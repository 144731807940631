import {useContext} from 'react';

import {NavigationInfoContext} from '~/contexts';

export function useNavigationInfo() {
  const navigationInfoContext = useContext(NavigationInfoContext);
  if (!navigationInfoContext) {
    console.error(
      new Error(
        'NavigationInfoContext must be used inside NavigationInfoContext.Provider.',
      ),
    );
  }

  return navigationInfoContext;
}
