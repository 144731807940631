import {useContext} from 'react';

import {I18nDataContext} from '~/contexts';

export function useI18nData() {
  const i18nDataContext = useContext(I18nDataContext);
  if (!i18nDataContext) {
    console.error(
      new Error('I18nDataContext must be used inside I18nDataContext.Provider.'),
    );
  }

  return i18nDataContext;
}
