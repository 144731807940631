import React, { useEffect, useState } from 'react';
import {View} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import ReactDiffViewer from 'react-diff-viewer';
import semver from 'semver';

import {required} from '~/util/rules';
import {colors} from '~/theme';
import {useForm} from '~/hooks';
import {ArrowRightIcon} from '~/assets/icons';
import {Button, Heading, Text} from '~/components/core';
import {Picker} from '~/components/form';
import {secondaryFormTheme} from '~/types';

interface VersionLabelProps {
	version: string;
}

function VersionLabel(props: VersionLabelProps) {
	const tw = useTailwind();
	return (
		<View style={tw('bg-highlight p-4 rounded-md')}>
			<Text style={tw('text-white')}>{props.version}</Text>
		</View>
	)
}

interface PublishFormProps {
	version: string;
	draft: string;
	published: string;
	onPublish: (version: string) => void;
	onClose: () => void;
}

type ChangeType = 'patch' | 'minor' | 'major';

const changeTypeOptions = [
	{ value: 'patch', label: 'Patch (formatting, consistency, or correction)' },
	{ value: 'minor', label: 'Minor (adding/removing information)' },
	{ value: 'major', label: 'Major (substantial changes)' },
];

interface DocumentChangeForm {
	changeType: string;
}

const getNextVersion = (current: string, change: ChangeType) => semver.inc(current, change)!;

export function PublishForm(props: PublishFormProps) {
	const tw = useTailwind();
	const {register, watch} = useForm<DocumentChangeForm>({
		defaultValues: {
			changeType: 'patch',
		},
		theme: secondaryFormTheme,
	});

	const changeType = register('changeType', {required});
  const selectedChangeType = watch('changeType') as ChangeType;
	const [nextVersion, setNextVersion] = useState(props.version);

	useEffect(() => {
		const bumped = getNextVersion(props.version, selectedChangeType);
		setNextVersion(bumped);
	}, [selectedChangeType]);

  return (
		<View>
			<Heading>
				Publish new version
			</Heading>
			<View style={tw('flex-row justify-between items-center mb-4')}>
				<Picker
					label="Change Type"
					{...changeType}
					options={changeTypeOptions}
				/>
				<View style={tw('flex-row justify-center items-center')}>
					<VersionLabel version={props.version} />
					<ArrowRightIcon
						style={tw('mx-2')}
						width={25}
						height={25}
						color={colors.highlight}
					/>
					<VersionLabel version={nextVersion} />
				</View>
			</View>
			<View style={tw('mb-4')}>
				<ReactDiffViewer
					oldValue={props.published}
					newValue={props.draft}
					splitView={true}
				/>
			</View>
			<View style={tw('flex-row justify-between items-center')}>
				<Button style={tw('bg-warning')} onPress={() => props.onPublish(nextVersion)}>
					Confirm
				</Button>
				<Button type="outline" onPress={props.onClose}>
					Cancel
				</Button>
			</View>
		</View>
	);
}
