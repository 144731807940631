import React from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

export function Loader() {
  const tw = useTailwind();

  return (
    <View style={tw('py-8')}>
      <ActivityIndicator size="large" />
    </View>
  );
}
