import React, { useEffect, useState } from 'react';
import {Pressable, View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {useCompanionApi} from '~/api/companion';
import {Workbook} from '~/api/companion/models/response';
import {Panel, Heading, Text} from '~/components/core';
import {SkeletonPanel} from '~/components/skeleton';

export function Workbooks() {
  const {api} = useCompanionApi();
  const [workbooks, setWorkbooks] = useState<Workbook[]>(new Array(6).fill(undefined));
  const navigate = useNavigate();
  const tw = useTailwind();

  useEffect(() => {
    (async () => {
      api.workbooks.all()
        .then((_workbooks) => {
            setWorkbooks(_workbooks);
          })
        .catch((err) => console.error(err));
    })();
  }, []);

  return (
    <View>
      {workbooks.map((w, i) => w ? (
        <Pressable key={i} onPress={() => navigate(`/classroom/workbooks/${w.id}`)}>
          <Panel>
            <Heading size="lg" style={tw('text-highlight')}>
              {w.title}
            </Heading>
            <Text>{w.description}</Text>
          </Panel>
        </Pressable>
      ) : <SkeletonPanel key={i} />)}
    </View>
  );
};

