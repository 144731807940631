import React from 'react';
import {Text as SlateText} from 'slate'

import {CustomElement, FormattedText} from '~/types';

const REMOVE_TAG = '<REMOVE>';

const processPrintNodes = (node: CustomElement & FormattedText) => {
  if (node['slide-context']) {
    node.text = REMOVE_TAG;
  }
  // todo
}

const processSlideNodes = (node: CustomElement & FormattedText) => {
  if (node['workbook-context']) {
    node.text = REMOVE_TAG;
  }
  // todo
}

const clearContextSpecific = (node: CustomElement, isPrint?: boolean) => {
  if (SlateText.isText(node)) {
    if (isPrint) processPrintNodes(node)
    else processSlideNodes(node)
  }
  node.children?.forEach((c) => clearContextSpecific(c as any, isPrint));
};

const cleanChildNodes = (node: CustomElement) => {
  if (node.children) {
    node.children = [...node.children.filter(n => n.text !== REMOVE_TAG)];
    if (node.children.length === 0) (node as any) = undefined;
  }
  if (node) node.children?.forEach(c => cleanChildNodes(c as any));
}

export const createSlidesFromNodes = (
	_title: string,
	nodes: CustomElement[],
	isPrint?: boolean
) => {
  let slides: CustomElement[][] = [];
  let previousSlideSplit: number = 0;
  let currentSectionTitle: CustomElement;
  let previousTitleSlide: number = 0;

  // step 1: cleanup nodes - clean out context-specific items
  nodes.forEach((n, i, currentNodes) => {
    clearContextSpecific(n, isPrint);
    cleanChildNodes(n);
    if (n.children && n.children.length === 0) {
      currentNodes.splice(i, 1);
    }
  })

  // step 2: iterate on cleaned nodes
  nodes.forEach((n, i) => {
    const lastSlide = i === nodes.length - 1;
    if (n.type === 'section-title') { // encountered a "title introduction slide"
      currentSectionTitle = n;
      previousTitleSlide = slides.length;
    }
    if (n.children[0].text === '---' || lastSlide) // encountered a "slide splitting node"
    {
      const skipInsertTitle = previousTitleSlide === slides.length; // is the current slide a title introduction slide?
      slides.push([...(skipInsertTitle ? [] : [currentSectionTitle]), ...nodes.slice(previousSlideSplit, lastSlide ? i + 1 : i)]);
      previousSlideSplit = i + 1; // node that we're splitting the slides on
    }
  });

  return slides;
}
