import {Descendant} from 'slate';

import {CustomElement} from '~/types';

export enum Format {
	'bold' = 'format_bold',
	'italic' = 'format_italic',
	'underline' = 'format_underlined',
	'heading-one' = 'looks_one',
	'heading-two' = 'looks_two',
	'block-quote' = 'format_quote',
	'numbered-list' = 'format_list_numbered',
	'bulleted-list' = 'format_list_bulleted',
	'textbox' = 'insert_textbox',
	'left' = 'format_align_left',
	'center' = 'format_align_center',
	'right' = 'format_align_right',
	'justify' = 'format_align_justify',
	'input' = 'insert_input',
	'section-title' = 'insert_section_title',
	'workbook-context' = 'insert_workbook_context',
	'slide-context' = 'insert_slide_context',
}

export type FormatType = keyof typeof Format;

export enum Hotkeys {
	'mod+b' = 'bold',
	'mod+i' = 'italic',
	'mod+u' = 'underline',
};

export type HotkeyType = keyof typeof Hotkeys;

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export type BlockType = keyof CustomElement;

export const TEXT_FORMAT_TYPES = [
	'bold',
	'italic',
	'underline',
	'heading-one',
	'heading-two',
	'block-quote',
	'numbered-list',
	'bulleted-list',
	'textbox',
	'left',
	'center',
	'right',
	'justify',
	'input',
	'section-title',
];

export const EDITOR_SEED: Descendant[] = [{type: 'paragraph', children: [{text: ''}]}];
