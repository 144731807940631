import React from 'react';
import {ImageBackground, Text, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import RenderHtml, {HTMLContentModel, HTMLElementModel} from 'react-native-render-html';
import {slateToHtml} from '@slate-serializers/html'

import {CustomElement} from '~/types';
import {useSlideScale} from '~/hooks';
import {colors} from '~/theme';
import {slideHtmlConfig} from '~/util/htmlConfig';

interface SlideViewProps {
  title: string;
  index: number;
  slides: CustomElement[][];
  width: number;
  height: number;
}

interface HtmlSlideProps {
  nodes: CustomElement[];
  width: number;
}

const customHTMLElementModels = {
  'rich-input': HTMLElementModel.fromCustomModel({
    tagName: 'rich-input',
    mixedUAStyles: {
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline',
    },
    contentModel: HTMLContentModel.textual
  }),
  'section-title': HTMLElementModel.fromCustomModel({
    tagName: 'section-title',
    mixedUAStyles: {
      display: 'flex',
      position: 'absolute',
      top: '89%',
      textAlign: 'right',
      opacity: 0.5,
    },
    contentModel: HTMLContentModel.block
  }),
};

function HtmlSlide(props: HtmlSlideProps) {
  const html = slateToHtml(props.nodes, slideHtmlConfig);
  return (
    <RenderHtml
      contentWidth={props.width}
      baseStyle={{
        fontSize: 24,
        color: colors.white,
        width: '100%',
        height: '100%',
      }}
      customHTMLElementModels={customHTMLElementModels}
      tagsStyles={{
        body: {height: '100%'},
      }}
      source={{html}}
    />
  );
}

function slideStyle(slideIndex: number, currentIndex: number, s?: CustomElement[]): any {
  return {
    width: '100%',
    height: '100%',
    position: 'unset',
    display: 'none',
    flexDirection: 'column',
    ...(slideIndex === currentIndex && {display: 'flex'}),
  };
}

function AdvertisementSlide() {
  const tw = useTailwind();
  return (
    <View style={tw('flex justify-end h-full')}>
      <Text
        style={[
          tw('text-white text-center'),
          {fontSize: 32, marginBottom: 96}
        ]}
      >
        www.makedisciplesprogram.com
      </Text>
    </View>
  )
}

interface MainTitleSlideProps {
  title: string;
}

function MainTitleSlide(props: MainTitleSlideProps) {
  const tw = useTailwind();
  return (
    <View style={tw('w-full h-full')}>
      <ImageBackground
        source={{uri: '/assets/images/title-slide.png'}}
        resizeMode="cover"
        style={tw('h-full w-full')}
      />
      <Text
        style={[
          tw('text-white text-center'),
          {position: 'absolute', right: 60, bottom: 60, fontSize: 32}
        ]}
      >
        {props.title.toUpperCase()}
      </Text>
    </View>
  )
}

export function SlideView(props: SlideViewProps) {
  const tw = useTailwind();
  const {width, height, scale, left, top} = useSlideScale(
    props.width,
    props.height,
  );

  /*
  todo: react native 0.68.1 lacks support for:
    - transformOrigin
    - position: 'unset'
  which is why the style must be typed `as any`.
  */
  return (
    <View
      style={{
        backgroundColor: colors.black,
        position: 'absolute',
        width,
        height,
        transformOrigin: 'top left',
        transform: `scale(${scale})`,
        top: `${top}px`,
        left: `${left}px`,
        boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
        display: 'flex',
      } as any}
    >
      <View style={tw('grow py-4 px-16')}>
        <View style={[slideStyle(0, props.index)]}>
          <AdvertisementSlide />
        </View>
        <View style={[slideStyle(1, props.index), {position: 'absolute', top: 0, left: 0}]}>
          <MainTitleSlide title={props.title} />
        </View>
        {props.slides.map((s, i) => (
          <View
            key={i}
            style={[slideStyle(i+2, props.index, s)]}
          >
            <HtmlSlide nodes={s} width={width} />
          </View>
        ))}
      </View>
    </View>
  );
}
