import React from 'react';
import {Controller} from 'react-hook-form';
import {ViewProps, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {Label} from '~/components/form';
import {UseFormRegisterReturnExtended} from '~/types';

import CheckboxInput from './component.native';

function _Checkbox(
  props: UseFormRegisterReturnExtended & ViewProps & {label?: string},
  ref: React.Ref<any>,
) {
  const {label, name, control, rules, error, theme} = props;
  const tw = useTailwind();

  // todo: might need <Pressable /> on native.
  return (
    <View style={tw(label ? 'mb-4' : '')} ref={ref}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: {onChange, value}}) => (
          <View
            style={tw('flex flex-row items-center max-w-full')}
          >
            <CheckboxInput
              {...props}
              inputColor={theme?.inputColor}
              checked={value}
              onChange={onChange}
            />
            {label && (
              <View>
                <Label inputName={name}>{label}</Label>
              </View>
            )}
          </View>
        )}
      />
      {label && error && (
        <Text style={tw('text-primary text-danger mt-1')}>
          {label} {error.message}
        </Text>
      )}
    </View>
  );
}

export const Checkbox = React.forwardRef(_Checkbox);
export default Checkbox;
