import React from 'react';
import {useWindowDimensions, View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Square} from '~/components/core';
import {useBreakpoint} from '~/hooks';

export function SquareList<ItemT>(
  props: ViewProps & {
    items: ItemT[];
  },
) {
  const tw = useTailwind();
  const {width: deviceWidth} = useWindowDimensions();
  const {breakMd} = useBreakpoint();
  const width = () => (breakMd ? 180 : deviceWidth / 2 - 24);
  const squareStyle = {maxWidth: width()};

  return (
    <View style={tw('flex-row flex-wrap -mr-4')}>
      {props.items.map((item, index) => (
        <Square style={[tw('mr-4'), squareStyle]} key={index}>
          {item}
        </Square>
      ))}
    </View>
  );
}
