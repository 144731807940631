import {useState} from 'react';

export function useArray<T>(initialValue: T[]) {
  const [array, setArray] = useState<T[]>(initialValue);

  const push = (item: T) => {
    setArray(prev => [...prev, item]);
  }

  const removeAtIndex = (index: number) => {
    setArray(prev => {
      const current = [...prev];
      current.splice(index, 1);
      return current;
    });
  }

  const pop = () => {
    removeAtIndex(array.length);
  }

  // very slow; avoid usage
  const remove = (item: T) => {
    setArray(
      prev =>
      prev.filter(x => JSON.stringify(x) !== JSON.stringify(item))
    );
  }

  const clear = () => {
    setArray(_ => ([]));
  }

  return {array, push, removeAtIndex, pop, remove, clear, setArray};
}
