import React from 'react';
import {TouchableOpacity, TouchableOpacityProps, View} from 'react-native';

import {DotsHorizontalIcon, DotsVerticalIcon} from '~/assets/icons';
import {useProfile} from '~/hooks';
import {colors} from '~/theme';

type KebabDirection = 'horizontal' | 'vertical';

function getKebabIcon(direction: KebabDirection) {
  switch (direction) {
    case 'horizontal': return DotsHorizontalIcon;
    case 'vertical': return DotsVerticalIcon;
    default: return DotsHorizontalIcon;
  }
}

export function Kebab(props: TouchableOpacityProps & {direction: KebabDirection}) {
  const {profile} = useProfile();

  const KebabContainer = ({children}: {children: React.ReactNode}) =>
    !!props.onPress
      ? <TouchableOpacity {...props} children={children} />
      : <View children={children} />;
  const Kebab = getKebabIcon(props.direction);

  return (
    <KebabContainer>
      <Kebab
        width={28}
        stroke={profile.darkMode ? colors.white : colors.black}
      />
    </KebabContainer>
  )
}