import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {useFont, useProfile} from '~/hooks';

function DatePickerInput(props: {
  value: Date;
  styles: StyleProp<ViewStyle>;
  onChange: (...event: any[]) => void;
  setDate: (date: Date) => void;
}) {
  const {value, styles, onChange, setDate} = props;
  const fontFamily = useFont('Montserrat', 'normal');
  const {profile} = useProfile();

  return (
    <input
      type="date"
      value={value ? value.toISOString().split('T')[0] : ''}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const d = new Date(event.target.value);
        setDate(d);
        onChange(d);
      }}
      style={{
        ...{fontFamily, colorScheme: profile.darkMode ? 'dark' : 'light'},
        ...styles as React.CSSProperties,
      }}
    />
  );
}

export default DatePickerInput;
