const width = 908;
const height = 681;

export function useSlideScale(containerWidth: number, containerHeight: number) {
  let scale;

  if (containerWidth / width > containerHeight / height)
    scale = containerHeight / height;
  else
    scale = containerWidth / width;

  const scaledWidth = width * scale;
  const scaledHeight = height * scale;

  const left = Math.max((containerWidth - scaledWidth) / 2, 0);
  const top = Math.max((containerHeight - scaledHeight) / 2, 0);

  return {width, height, scale, left, top};
}
