import React from 'react';
import {useColorScheme} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Route, Routes} from 'react-router-native';
import {TailwindProvider} from 'tailwind-rn';
import pluralize from 'pluralize';
import {ClickOutsideProvider} from 'react-native-click-outside';

import {Navigation, Router} from '~/components/navigation';
import {ProfileContext} from '~/contexts';
import {useStore} from '~/hooks';
import {Home, Settings, NotFound, Contacts} from '~/views';
import {BibleStudiesList, Series, Study} from '~/views/bible-studies';
import {Content} from './views/content';
import {Courses, WorkbooksListing} from './views/content/ClassroomContent';
import {Classroom, WorkbookA4, WorkbookDetail} from '~/views/classroom';
import {AddConversation, AddScheduledStudy, Stats} from '~/views/stats';

import utilities from '../tailwind.json';
import {Conversations} from './views/stats/Conversations';
import {Studies} from './views/stats/Studies';
import {NotifyProvider} from './components/system';
import {SeriesBibleStudyForm} from './forms';
import {Slideshow} from './components/slides';

// pluralize rules
pluralize.addUncountableRule('scheduled');
pluralize.addUncountableRule('conducted');

const App = () => {
  const [profile, setProfile] = useStore('profile', {
    darkMode: useColorScheme() !== 'light',
  });

  // notice: mobile routing is structured slightly differently from typical web app.
  return (
    <ProfileContext.Provider value={{profile, setProfile}}>
      <TailwindProvider
        utilities={utilities}
        colorScheme={profile.darkMode ? 'dark' : 'light'}>
        <SafeAreaProvider>
          <ClickOutsideProvider>
            <NotifyProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<Navigation />}>
                    <Route index element={<Home />} />
                    <Route path="content">
                      <Route index element={<Content />} />
                      <Route path="courses" element={<Courses />} />
                      <Route path="workbooks" element={<WorkbooksListing />} />
                    </Route>
                    <Route path="study-series">
                      <Route index element={<BibleStudiesList />} />
                      <Route path=":series">
                        <Route index element={<Series />} />
                        <Route path="add-bible-study" element={<SeriesBibleStudyForm action="Create" />} />
                        <Route path=":study">
                          <Route index element={<Study />} />
                          <Route path="edit" element={<SeriesBibleStudyForm action="Update" />} />
                          {/* <Route path="a4" element={<StudyA4 />} /> */}
                          {/* <Route path="us" element={<StudyUsLetter />} /> */}
                        </Route>
                      </Route>
                    </Route>
                    <Route path="stats">
                      <Route index element={<Stats />} />
                      <Route path="conversations" element={<Conversations />} />
                      <Route path="add-conversation" element={<AddConversation />} />
                      <Route path="studies" element={<Studies />} />
                      <Route path="add-scheduled-study" element={<AddScheduledStudy />} />
                    </Route>
                    <Route path="classroom">
                      <Route index element={<Classroom />} />
                      <Route path="workbooks">
                        <Route path=":workbook">
                          <Route index element={<WorkbookDetail action="Update" />} />
                          <Route path="slides" element={<Slideshow />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="contacts" element={<Contacts />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </Router>
            </NotifyProvider>
          </ClickOutsideProvider>
        </SafeAreaProvider>
      </TailwindProvider>
    </ProfileContext.Provider>
  );
};

export default App;
