import React from 'react';
import {View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {NotifyBackgroundColor, NotifyPopupInfo} from '~/types';

interface NotifyPopupProps extends NotifyPopupInfo { }

export function NotifyPopup({
  style,
  ...props
}: ViewProps & NotifyPopupProps) {
  const tw = useTailwind();
  const color = NotifyBackgroundColor[props.type ?? 'neutral'];

  return (
    <View style={tw(`${color} p-4 rounded-md mb-2`)}>
      <Text style={tw('text-white')}>{props.message}</Text>
    </View>
  );
}
