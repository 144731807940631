import React from 'react';
import {useNavigate} from 'react-router-native';

import {Button, Container, FocusedContent, Heading, ScrollView} from '~/components/core';
import {DatePicker, Input, Picker, RadioGroup} from '~/components/form';
import {Gap} from '~/components/layout';
import {useBreakpoint, useForm} from '~/hooks';
import {required} from '~/util/rules';

interface AddConversationForm {
  existing: number;
  firstName: string;
  lastName: string;
  contactId?: number;
  date: Date | string;
  location: string;
  notes: string;
  phoneNumber: string;
}

export function AddConversation() {
  const navigate = useNavigate();
  const {register, handleSubmit, watch} = useForm<AddConversationForm>({
    defaultValues: {
      existing: 1,
      firstName: '',
      lastName: '',
      date: new Date(Date.now()),
      location: '',
      notes: '',
      phoneNumber: '',
    },
  });

  const onSubmit = handleSubmit(
    (data, e) => {
      navigate('/');
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const existing = register('existing', {required});
  const existingContact = watch('existing') == 2;
  const shouldValidate = (value: string | number | boolean | undefined) => !!value || required;
  const firstName = register('firstName', {validate: (value) => shouldValidate(existingContact ? true : value)});
  const lastName = register('lastName', {validate: (value) => shouldValidate(existingContact ? true : value)});
  const contactId = register('contactId', {validate: (value) => shouldValidate(existingContact ? value : true)});
  const date = register('date', {required});
  const location = register('location', {required});
  const notes = register('notes');
  const phoneNumber = register('phoneNumber');
  const {breakMd} = useBreakpoint();

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <Heading>Add conversation</Heading>
          <RadioGroup
            {...existing}
            label="Existing"
            horizontal
            options={[
              { value: 1, label: 'New' },
              { value: 2, label: 'Existing' },
            ]}
          />
          {!existingContact ? (
            <Gap gap={4}>
              <Input
                {...firstName}
                label="First Name"
                placeholder="First Name"
                autoCapitalize="words"
              />
              <Input
                {...lastName}
                label="Last Name"
                placeholder="Last Name"
                autoCapitalize="words"
              />
            </Gap>
          ) : (
            <Picker
              {...contactId}
              label="Contact"
              options={[
                { value: 1, label: 'Tommy Atkins' },
                { value: 2, label: 'Digger Mosher' },
                { value: 3, label: 'Doughboy Jones' },
              ]}
            />
          )}
          <DatePicker {...date} label="Date" placeholder="Date" />
          <Input {...location} label="Location" placeholder="Location" />
          <Input
            {...notes}
            label="Notes"
            placeholder="Notes"
            multiline
            numberOfLines={6}
          />
          {!existingContact && (
            <Input {...phoneNumber} label="Phone Number" placeholder="Phone Number" />
          )}
          <Button type="solid" full={!breakMd} onPress={onSubmit}>
            Add
          </Button>
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
