import React from 'react';
import {GestureResponderEvent, Pressable, View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import {API_URL} from '@env';

import {Panel, Heading, PersonImage, Text} from '~/components/core';
import {User} from '~/api/companion/models/response';
import {controllers as companionControllers} from '~/api/companion';

export function PersonListing(
  props: ViewProps & {
    onPress: ((event: GestureResponderEvent) => void) | null | undefined;
    user: User;
    showRoles?: boolean;
    actions?: JSX.Element;
  }
) {
  const tw = useTailwind();

  return (
    <Pressable {...props}>
      <Panel>
        <View style={tw('flex-row justify-between items-center')}>
          <View style={tw('flex-row items-center')}>
            <PersonImage
              size="xs"
              uri={`${API_URL}/api/${companionControllers.users}/${props.user.id}/image`}
              style={tw('mr-4')}
            />
            <View>
              <Heading size="sm" style={tw(`text-highlight ${props.showRoles ? 'mb-2' : ''}`)}>
                {props.user.name}
              </Heading>
              {props.showRoles && <Text>{props.user.roles.join(', ')}</Text>}
            </View>
          </View>
          {props.actions}
        </View>
      </Panel>
    </Pressable>
  );
};
