// todo: improve poor type safety
import React from 'react';
import {View} from 'react-native';
import {
  useObject,
  FormContext,
  InputTypes,
  InputReturnTypes,
  UseRadioReturnType,
  UseSelectReturnType,
  UISchemaType,
  JSONSchemaType
} from 'react-hook-form-jsonschema';

import {Input, Picker, RadioGroup} from '~/components/form';
import {UseFormRegisterExtended, UseFormReturnExtended} from '~/hooks';
import {required} from '~/util/rules';

interface DynamicObjectProps {
  object: InputReturnTypes;
  register: UseFormRegisterExtended<any>;
}

function DynamicObject(props: DynamicObjectProps) {
  const input = props.register(
    props.object.pointer.split('/').pop() as any, // the end of the pointer should match the form values.
    {
      ...(props.object.isRequired && {required}),
      // todo: more validation conditions e.g as below
      // ...(!!props.object.validator.max && {max: max(+props.object.validator.max)})
    },
  );

  switch (props.object.type) {
    case InputTypes.input: return <Input {...input} label={props.object.getObject().title} />;
    case InputTypes.radio: return (
      <RadioGroup
        {...input}
        label={props.object.getObject().title} 
        options={(props.object as UseRadioReturnType).getItems().map((item, i) => ({value: i, label: item}))}
      />
    );
    case InputTypes.select: return (
      <Picker
        {...input}
        label={props.object.getObject().title} 
        options={(props.object as UseSelectReturnType).getItems().map((item, i) => ({value: i, label: item}))}
      />
    );
    default: return <></>;
  }
};

interface ObjectRendererProps {
  pointer: string;
  UISchema: UISchemaType;
  register: UseFormRegisterExtended<any>;
}

function ObjectRenderer(props: ObjectRendererProps) {
  const formInputObjects = useObject({
    pointer: props.pointer,
    UISchema: props.UISchema
  });

  return (
    <>
      {formInputObjects.map(object => (
        <DynamicObject
          key={`${object.type}${object.pointer}`}
          object={object}
          register={props.register}
        />
      ))}
    </>
  );
};

interface DynamicProps {
  form: UseFormReturnExtended<any, any>;
  schema: JSONSchemaType;
  UISchema: UISchemaType;
  footer: React.ReactNode;
}

export function DynamicForm(props: DynamicProps) {
  return (
    <View>
      <View>
        <FormContext schema={props.schema}>
          <ObjectRenderer pointer="#" UISchema={props.UISchema} register={props.form.register} />
          {props.footer}
        </FormContext>
      </View>
    </View>
  );
};
