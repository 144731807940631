import React from 'react';
import dateFormat from 'dateformat';
import {ScrollView, TouchableOpacity, View, ViewProps} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {PlusIcon} from '~/assets/icons';
import {Container, FlatList, FocusedContent, Heading, Kebab, TabView, Text} from '~/components/core';
import {useBreakpoint, useFont, useForm, useProfile} from '~/hooks';
import {scheduledStudies} from '~/mockData';
import {colors, widths} from '~/theme';
import {TabViewScene, UseFormRegisterReturnExtended} from '~/types';
import {Checkbox} from '~/components/form';

interface StudyParticipant {
  firstName: string;
  lastName: string;
}

interface ScheduledStudyProps {
  id: string;
  formInput: UseFormRegisterReturnExtended & ViewProps;
  participants: StudyParticipant[];
  date: string;
  location: string;
  studyTitle: string;
  notes: string;
  completed: boolean;
}

const dateTimeFormat = (date: Date) =>
  dateFormat(date, 'dddd, mmmm dS, h:MM TT');

function StudyScheduleItem({
  // id,
  formInput,
  participants,
  date,
  location,
  studyTitle,
  completed,
}: ScheduledStudyProps) {
  const tw = useTailwind();
  const {profile} = useProfile();
  const fontFamily = useFont('Montserrat', 'semibold');

  const today = new Date();
  const scheduledDate = new Date(date);

  const participantList = participants
    .map(p => `${p.firstName} ${p.lastName}`)
    .join(', ');

  const futureDate = scheduledDate.getTime() > today.getTime();
  const staleDate = today.getTime() - scheduledDate.getTime() > 2592000000;

  return (
    <View style={tw('justify-between flex-row items-center mb-4')}>
      <View style={tw('flex-row items-center shrink')}>
        <View style={tw('mr-4')}>
          <Checkbox {...formInput} />
        </View>
        <View style={tw('flex-1')}>
          <Text style={tw('mb-1')}>{participantList}</Text>
          {!!studyTitle && <Text style={tw('mb-1 text-sm')}>{studyTitle}</Text>}
          <View
            style={tw(
              `${futureDate ? 'border border-highlight p-2 rounded-md' : ''}`,
            )}>
            <Text
              style={[
                tw(`${staleDate ? 'text-inactive' : 'text-highlight'}`),
                {fontFamily},
              ]}>
              {dateTimeFormat(scheduledDate)} @ {location}
            </Text>
          </View>
        </View>
      </View>
      <View>
        <Kebab direction="vertical" />
      </View>
    </View>
  );
}

function UpcomingStudies() {
  const tw = useTailwind();

  const {register} = useForm<any>({
    defaultValues: scheduledStudies.reduce((a, v) => ({ ...a, [v.id]: v.completed}), {}),
  });

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      keyExtractor={(_, index) => 'key' + index}
      stickyHeaderIndices={[0]}
      stickyHeaderHiddenOnScroll
      data={scheduledStudies.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      )}
      style={tw('-mx-4')}
      renderItem={({item}) => {
        const scheduleItem = register(item.id);
        return <StudyScheduleItem formInput={scheduleItem} {...item} />
      }}
    />
  );
}

export function Studies() {
  const tw = useTailwind();
  const {breakMd} = useBreakpoint();
  const {profile} = useProfile();
  const navigate = useNavigate();

  const tabs: TabViewScene[] = [
    { key: 'upcoming', title: 'Upcoming', component: () => <UpcomingStudies />, },
    { key: 'completed', title: 'Completed', component: () => <></> },
  ];

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <View style={[tw('flex-row justify-between items-center'), breakMd && {maxWidth: widths.focus}]}>
            <Heading>Scheduled Bible Studies</Heading>
            <TouchableOpacity onPress={() => navigate('/stats/add-scheduled-study')}>
              <PlusIcon width={40} height={40} stroke={profile.darkMode ? colors['dark-highlight'] : colors.highlight} />
            </TouchableOpacity>
          </View>
          <TabView
            tabStyles={breakMd && {maxWidth: widths.focus}}
            tabs={tabs}
          />
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
