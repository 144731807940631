import React, {useState} from 'react';
import {View} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import {useTransition, animated, SpringConfig} from '@react-spring/native';

import {NotifyContext} from '~/contexts';
import {useArray, useBreakpoint} from '~/hooks';
import {NotifyPopupInfo} from '~/types';
import {NotifyPopup} from './NotifyPopup';

const config: SpringConfig = {};
const duration: number = 3000;

interface NotifyPopupInfoInternal extends NotifyPopupInfo {
  key?: number;
}

export function NotifyProvider(props: {children: React.ReactNode}) {
  const tw = useTailwind();
  const {breakMd} = useBreakpoint();
  const {
    array: notifyPopups,
    push,
    removeAtIndex,
    remove,
    pop,
    clear,
    setArray
  } = useArray<NotifyPopupInfoInternal>([]);

  const transitions = useTransition(notifyPopups, {
    from: {opacity: 0, life: '100%'},
    keys: item => item.key!,
    enter: _ => async (next) => {
      await next({opacity: 1})
      await next({life: '0%'})
    },
    leave: {opacity: 0},
    onRest: (_, __, item) => {
      setArray(state =>
        state.filter(i => i.key !== item.key)
      )
    },
    config: (_, __, phase) => key => phase === 'enter' && key === 'life' ? {duration} : config,
  });

  const [count, setCount] = useState(0);

  return (
    <NotifyContext.Provider
      value={{
        notifyPopups,
        pushNotifyPopup: (info: NotifyPopupInfoInternal) => {
          push({...info, key: count});
          setCount(count+1);
        },
        removeNotifyPopupAtIndex: (index: number) => removeAtIndex(index),
        removeNotifyPopup: (info: NotifyPopupInfoInternal) => remove(info),
        popNotifyPopup: pop,
        clearNotifyPopups: clear,
        setNotifyPopups: setArray,
      }}
    >
      <View style={tw(`absolute ${breakMd ? 'bottom-4' : 'bottom-16 left-4'} right-4 z-50`)}>
        {transitions((style, info) => (
          <animated.View key={info.key} style={style}>
            <NotifyPopup {...info}  />
          </animated.View>
        ))}
      </View>
      {props.children}
    </NotifyContext.Provider>
  );
}
