import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import {View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {Label} from '~/components/form';
import {usePlatform} from '~/hooks';
import {PickerOption, UseFormRegisterReturnExtended} from '~/types';

import PickerInput from './component.native';

interface PickerInputProps {
  options: PickerOption[];
  label?: string;
  chooseOptionLabel?: string;
  noMargin?: boolean;
}

function _Picker(
  props: UseFormRegisterReturnExtended & PickerInputProps,
  ref: React.Ref<any>,
) {
  const {options, label, chooseOptionLabel, noMargin, name, control, rules, error, theme} = props;
  const [option, setOption] = useState<string>(control._defaultValues[name] ?? '');
  const tw = useTailwind();
  const {isWeb} = usePlatform();
  const pickerStyles = tw(
    `${theme!.inputColor} rounded-md p-2 ${
      error ? 'border border-danger' : isWeb ? 'border-none' : ''
    }`,
  );

  return (
    <View style={[!noMargin && tw('mb-4'), tw('flex-initial')]} ref={ref}>
      {label && <Label>{props.label}</Label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: {onChange}}) => (
          <PickerInput
            options={options}
            value={option}
            chooseOptionLabel={chooseOptionLabel ?? 'Choose an option'}
            styles={pickerStyles}
            onChange={onChange}
            setOption={setOption}
          />
        )}
      />
      {error && (
        <Text style={tw('text-primary text-danger mt-1')}>
          {label} {error.message}
        </Text>
      )}
    </View>
  );
}

export const Picker = React.forwardRef(_Picker);
export default Picker;
