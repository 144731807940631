import React from 'react';

import {Container, FocusedContent, Heading} from '~/components/core';
import {Workbooks} from '~/views/classroom';

export function WorkbooksListing() {
  return (
    <FocusedContent>
			<Container>
				<Heading>Workbooks</Heading>
				<Workbooks />
			</Container>
		</FocusedContent>
  );
}
