import React from 'react';
import {useTailwind} from 'tailwind-rn';

import {LabelProps} from './LabelProps';

export default function Label({children, inputName}: LabelProps) {
  const tw = useTailwind();

  return (
    <label
      style={{
      fontSize: 14,
      userSelect: 'none',
      ...tw('text-primary dark:text-dark-primary py-1')
      }}
      htmlFor={inputName}
    >
      {children}
    </label>
  )
}
