import React, {Children} from 'react';
import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import {useTailwind} from 'tailwind-rn';

type GapSize = 2 | 4 | 6 | 8;

export function Gap({
  style,
  ...props
}: ViewProps & {gap: GapSize; vertical?: boolean, childStyle?: StyleProp<ViewStyle> | undefined}) {
  const tw = useTailwind();
  const dir = tw(`${props.vertical ? '' : 'flex-row'}`); // direction
  const containerMarginFactor = props.gap * -4; // negative margin correction container
  const adjustContainer = props.vertical
    ? { marginBottom: containerMarginFactor}
    : { marginRight: containerMarginFactor }
  const adjustChild = tw(`flex-1 m${props.vertical ? 'b' : 'r'}-${props.gap}`); // negative margin correction child

  return (
    <View style={[dir, adjustContainer, style]} {...props}>
      {Children.map(props.children, c => (
        <View style={[adjustChild, props.childStyle]}>{c}</View>
      ))}
    </View>
  );
}
