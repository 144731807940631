import React from 'react';
import {View} from 'react-native';
import {Link} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {useNavigationInfo, useProfile} from '~/hooks';
import {colors} from '~/theme';
import {NavMenuItemProps} from '~/types';

export function NavMenuItem({
  to,
  label,
  Icon,
  roles,
  index,
}: NavMenuItemProps) {
  const {profile} = useProfile();
  const {navigationInfo, setNavigationInfo} = useNavigationInfo();
  const selected = navigationInfo.currentTab === index;
  const tw = useTailwind();

  const show = !roles || (!!roles && profile.roles.filter(r => roles.includes(r)));

  return (
    <Link
      to={to}
      underlayColor="transparent"
      style={tw(
        `${show ? '' : 'hidden'} flex-1 flex-grow md:flex-initial py-1 md:my-4`,
      )}
      onPress={() => setNavigationInfo({...navigationInfo, currentTab: index})}>
      <View style={[tw('items-center')]}>
        <Icon
          width={25}
          height={25}
          fill={
            selected
              ? profile.darkMode
                ? colors['dark-highlight']
                : colors.highlight
              : colors.inactive
          }
        />
        <Text
          style={tw(
            `text-center mt-1 text-xxs md:text-xs ${
              selected
                ? 'text-highlight dark:text-dark-highlight'
                : 'text-inactive'
            }`,
          )}>
          {label}
        </Text>
      </View>
    </Link>
  );
}
