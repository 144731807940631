import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import {useNavigate} from 'react-router-native';

import {
  Container,
  FocusedContent,
  Heading,
  ScrollView,
  TabView,
} from '~/components/core';
import {useBreakpoint, useProfile} from '~/hooks';
import {colors, widths} from '~/theme';
import {TabViewScene} from '~/types';
import {PlusIcon} from '~/assets/icons';

export function Conversations() {
  const tw = useTailwind();
  const {breakMd} = useBreakpoint();
  const {profile} = useProfile();
  const navigate = useNavigate();

  const tabs: TabViewScene[] = [
    { key: 'this-week', title: 'This Week', component: () => <></>, },
    { key: 'all', title: 'All', component: () => <></> },
  ];

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <View style={tw('flex-row justify-between items-center')}>
            <Heading>Conversations</Heading>
            <TouchableOpacity onPress={() => navigate('/stats/add-conversation')}>
              <PlusIcon width={40} height={40} stroke={profile.darkMode ? colors['dark-highlight'] : colors.highlight} />
            </TouchableOpacity>
          </View>
          <TabView
            tabStyles={breakMd && {maxWidth: widths.focus}}
            tabs={tabs}
          />
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
