import {useProfile} from '~/hooks';

import {Api, useApi} from '..';
import {
  Country,
  Course,
  CourseWithUsers,
  Language,
  Paginated,
  PersonalBibleStudyDetail,
  PersonalBibleStudySeries,
  StudySeriesDetail,
  StudySeries,
  User,
  StudySubmission,
  Workbook,
  PresentationNotes,
} from './models/response';
import {
  CourseSearchRequest,
  CreateBibleStudyRequest,
  UpdateBibleStudySubmissionRequest,
  CreateCourseRequest,
  CreateStudySeriesRequest,
  CreateUserRequest,
  LoginRequest,
  StudySeriesSearchRequest,
  UpdateBibleStudyRequest,
  UpdateCourseRequest,
  UpdateStudySeriesRequest,
  UpdateUserRequest,
  UserSearchRequest,
  CreateWorkbookRequest,
  UpdateWorkbookRequest,
  UpdatePresentationNotesRequest,
  UpdateWorkbookSubmissionRequest,
  PublishBibleStudyRequest,
} from './models/request';

export const controllers = {
  users: 'users',
  studySeries: 'study-series',
  bibleStudies: 'bible-studies',
  i18n: 'i18n',
  classroom: 'classroom',
  workbooks: 'workbooks',
};

function jsonifyRequest<T>(options: RequestInit, payload: T) {
  return {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
    body: JSON.stringify(payload),
  }
}

export function useCompanionApi() {
  const {get, post, put, delete_} = useApi(Api.Companion);
  const {profile} = useProfile();
  const options: RequestInit = {
    headers: {
      Authorization: `Bearer ${profile.token}`, // definitely defined since we're logged in
    },
  };

  /// user controller
  const api = {
    users: {
      login: (loginRequest: LoginRequest) =>
        post<string>(controllers.users + '/login', jsonifyRequest(options, loginRequest)),
      stats: () =>
        get<any>(controllers.users + '/stats', options), // todo
      paginate: (userSearchRequest: UserSearchRequest) =>
        post<Paginated<User>>(controllers.users + '/search', jsonifyRequest(options, userSearchRequest)),
      create: (createUserRequest: CreateUserRequest) =>
        post<User>(controllers.users, jsonifyRequest(options, createUserRequest)),
      update: (userId: number, updateUserRequest: UpdateUserRequest) =>
        put<User>(controllers.users + `/${userId}`, jsonifyRequest(options, updateUserRequest)),
      updateImage: (userId: number, image: File) => {
        const formData = new FormData();
        formData.append("image", image);
        return put<boolean>(
          controllers.users + `/${userId}`,
          {...options, body: formData},
        )
      },
      delete: (userId: number) =>
        delete_<boolean>(controllers.users + `/${userId}`, options),
      courses: (userId: number) =>
        get<Course[]>(controllers.users + `/${userId}/courses`, options),
    },
    studySeries: {
      all: () =>
        get<PersonalBibleStudySeries[]>(controllers.studySeries, options),
      paginate: (studySeriesSearchRequest: StudySeriesSearchRequest) =>
        post<Paginated<StudySeries>>(controllers.studySeries + '/search', jsonifyRequest(options, studySeriesSearchRequest)),
      create: (createStudySeriesRequest: CreateStudySeriesRequest) =>
        post<StudySeries>(controllers.studySeries, jsonifyRequest(options, createStudySeriesRequest)),
      update: (seriesId: number, updateStudySeriesRequest: UpdateStudySeriesRequest) =>
        put<StudySeries>(controllers.studySeries + `/${seriesId}`, jsonifyRequest(options, updateStudySeriesRequest)),
      delete: (seriesId: number) =>
        delete_<boolean>(controllers.studySeries + `/${seriesId}`, options),
      series: (seriesSlug: string) =>
        get<StudySeriesDetail>(controllers.studySeries + `/${seriesSlug}/detail`, options),
    },
    bibleStudies: {
      study: (studySlug: string) =>
        get<PersonalBibleStudyDetail>(controllers.bibleStudies + `/${studySlug}`, options),
      create: (seriesSlug: string, createBibleStudyRequest: CreateBibleStudyRequest) =>
        post<PersonalBibleStudyDetail>(controllers.bibleStudies + `/${seriesSlug}`, jsonifyRequest(options, createBibleStudyRequest)),
      update: (studySlug: string, updateBibleStudyRequest: UpdateBibleStudyRequest) =>
        put<PersonalBibleStudyDetail>(controllers.bibleStudies + `/${studySlug}`, jsonifyRequest(options, updateBibleStudyRequest)),
      delete: (studySlug: string) =>
        delete_<boolean>(controllers.bibleStudies + `/${studySlug}`, options),
      getSubmission: (studySlug: string) =>
        get<StudySubmission>(controllers.bibleStudies + `/submissions/${studySlug}`, options),
      updateSubmission: (studySlug: string, updateBibleStudySubmissionRequest: UpdateBibleStudySubmissionRequest) =>
        put<StudySubmission>(controllers.bibleStudies + `/submissions/${studySlug}`, jsonifyRequest(options, updateBibleStudySubmissionRequest)),
      publish: (studySlug: string, publishBibleStudyRequest: PublishBibleStudyRequest) =>
        put<PersonalBibleStudyDetail>(controllers.bibleStudies + `/publish/${studySlug}`, jsonifyRequest(options, publishBibleStudyRequest)),
    },
    i18n: {
      countries: () => get<Country[]>(controllers.i18n + '/countries', options),
      languages: () => get<Language[]>(controllers.i18n + '/languages', options),
    },
    classroom: {
      all: () => get<CourseWithUsers[]>(controllers.classroom, options),
      paginate: (courseSearchRequest: CourseSearchRequest) =>
        post<Paginated<CourseWithUsers>>(controllers.classroom + '/search', jsonifyRequest(options, courseSearchRequest)),
      create: (createCourseRequest: CreateCourseRequest) =>
        post<CourseWithUsers>(controllers.classroom, jsonifyRequest(options, createCourseRequest)),
      update: (courseId: number, updateCourseRequest: UpdateCourseRequest) =>
        put<CourseWithUsers>(controllers.classroom + `/${courseId}`, jsonifyRequest(options, updateCourseRequest)),
      delete: (courseId: number) =>
        delete_<boolean>(controllers.classroom + `/${courseId}`, options),
      get: (courseId: number) =>
        get<CourseWithUsers>(controllers.classroom + `/${courseId}`, options),
    },
    workbooks: {
      all: () => get<Workbook[]>(controllers.workbooks, options),
      get: (id: number) => get<Workbook>(controllers.workbooks + `/${id}`, options),
      create: (createWorkbookRequest: CreateWorkbookRequest) =>
        post<Workbook>(controllers.workbooks, jsonifyRequest(options, createWorkbookRequest)),
      update: (id: number, updatedWorkbookRequest: UpdateWorkbookRequest) =>
        put<Workbook>(controllers.workbooks + `/${id}`, jsonifyRequest(options, updatedWorkbookRequest)),
      delete: (id: number) => delete_<boolean>(controllers.workbooks + `/${id}`, options),
      getNotes: (workbookId: number) =>
        get<PresentationNotes>(controllers.workbooks + `/${workbookId}/presentation-notes`, options),
      updateNotes: (workbookId: number, updatePresentationNotesRequest: UpdatePresentationNotesRequest) =>
        put<PresentationNotes>(controllers.workbooks + `/${workbookId}/presentation-notes`, jsonifyRequest(options, updatePresentationNotesRequest)),
      updateSubmission: (workbookId: number, updateWorkbookSubmissionRequest: UpdateWorkbookSubmissionRequest) =>
        put<StudySubmission>(controllers.workbooks + `/${workbookId}/submissions`, jsonifyRequest(options, updateWorkbookSubmissionRequest))
    },
  };

  return {api};
}
