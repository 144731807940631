import {createContext} from 'react';

import {NavigationInfoType} from '~/types';

interface NavigationInfoContextType {
  navigationInfo: NavigationInfoType;
  setNavigationInfo: (value: NavigationInfoType) => void;
}

export const NavigationInfoContext = createContext<NavigationInfoContextType>(
  undefined!,
);
