const bibleStudies = [
  {
    title: 'Getting to know the Lord',
    description: 'Presentation of the gospel of Jesus Christ',
    slug: 'gtktl',
  },
  {
    title: 'Supplementary studies',
    description: 'Vital supplements for the growing believer',
    slug: 'ss',
  },
  {
    title: 'Reasonable faith',
    description: 'Logic to live by',
    slug: 'rf',
  },
  {
    title: 'Deeper discipleship',
    description: 'Tools and expectations for the growing Christian',
    slug: 'dd',
  },
  {
    title: 'Studies on the Holy Spirit and Miracles',
    description: 'Digging deeper into the person and work of the Holy Spirit',
    slug: 'soths',
  },
  {
    title: 'For my religious friends',
    description: 'Questions and studies for those of other faiths',
    slug: 'fmrf',
  },
];

const scheduledStudies = [
  {
    id: '2',
    participants: [
      {
        firstName: 'John',
        lastName: 'Smith',
      },
    ],
    date: '12 Sep 1993 16:00:00 EDT',
    location: 'Coffee shop',
    studyTitle: '',
    notes: '',
    completed: false,
  },
  {
    id: '1',
    participants: [
      {
        firstName: 'John',
        lastName: 'Smith',
      },
      {
        firstName: 'Jane',
        lastName: 'Doe',
      },
    ],
    date: '10 Sep 1993 21:00:00 EDT',
    location: 'Coffee shop',
    studyTitle: 'Getting to Know the Lord',
    notes: '',
    completed: true,
  },
  {
    id: '3',
    participants: [
      {
        firstName: 'John',
        lastName: 'Smith',
      },
    ],
    date: '16 Sep 1993 17:00:00 EDT',
    location: 'Coffee shop',
    studyTitle: '',
    notes: '',
    completed: false,
  },
  {
    id: '4',
    participants: [
      {
        firstName: 'John',
        lastName: 'Smith',
      },
    ],
    date: '27 Dec 2023 16:00:00 EDT',
    location: 'Coffee shop',
    studyTitle: '',
    notes: '',
    completed: false,
  },
];

module.exports = {
  bibleStudies,
  scheduledStudies,
};
