import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';
import {slateToHtml} from '@slate-serializers/html';
import {Descendant} from 'slate';

import {Heading, Container, Button, Text, FocusedContent, ScrollView, Loader, useModalControl, Modal} from '~/components/core';
import {useAdministrative, useForm, useNotify} from '~/hooks';
import {Input, Label} from '~/components/form';
import {required} from '~/util/rules';
import {useCompanionApi} from '~/api/companion';
import {CrudAction, primaryFormTheme} from '~/types';
import {PersonalBibleStudyDetail} from '~/api/companion/models/response';
import {Editor} from '~/components/form';
import {EDITOR_SEED} from '~/components/form/Editor/types';
import {PublishForm} from './PublishForm';

interface UpdateBibleStudyForm {
	title: string;
	description: string;
  body: Descendant[];
  published: Descendant[];
  version: string;
}

interface SeriesBibleStudyFormProps {
	action?: CrudAction;
}

export function SeriesBibleStudyForm(props: SeriesBibleStudyFormProps) {
  const {api} = useCompanionApi();
  const administrative = useAdministrative();
  const tw = useTailwind();
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, setValue, getValues} = useForm<UpdateBibleStudyForm>({
    defaultValues: {
    },
  });
  const {pushNotifyPopup} = useNotify();
	const navigate = useNavigate();
  const slugs = location.pathname.split('study-series/').pop()!;
  const seriesSlug = slugs.split('/')[0];
  const studySlug = slugs.split('/')[1];
  const goBack = () => navigate(-1);
  const [bibleStudy, setBibleStudy] = useState<PersonalBibleStudyDetail>();
  const [initialBodyValue, setInitialBodyValue] = useState<Descendant[]>([]);
  const [published, setPublished] = useState<Descendant[]>([]);
  const [version, setVersion] = useState<string>("1.0.0");
  const publishModalControl = useModalControl();

  useEffect(() => {
    if (!administrative) {
      navigate('/');
      return;
    }
    if (props.action === 'Update' || props.action === 'Delete') {
      (async () => {
        api.bibleStudies.study(studySlug)
          .then((study) => {
            setBibleStudy(study);
            setValue('title', study.title);
            setValue('description', study.description);
            setValue('body', study.body);
            setPublished(study.published);
            setVersion(study.version);
            setInitialBodyValue(study.body.length > 0 ? study.body : EDITOR_SEED);
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false));
      })();
    }
  }, []);

  const studyAction = (action: () => Promise<PersonalBibleStudyDetail | boolean>) => action()
    .then((series) => {
      pushNotifyPopup({
        message: `Bible study ${props.action?.toLowerCase()}d successfully.`,
        type: 'success',
      });
			goBack();
    })
    .catch(err => {
      console.error(err);
      pushNotifyPopup({
        message: `Failed to ${props.action?.toLowerCase()} Bible study.`,
        type: 'error',
      })
    })
    .finally(() => setLoading(false));

  const onSubmit = handleSubmit(
    (data) => {
      setLoading(true);
      switch (props.action) {
        case 'Create': return studyAction(() => api.bibleStudies.create(seriesSlug, data));
				case 'Update': return studyAction(() => api.bibleStudies.update(studySlug, data));
        case 'Delete': return studyAction(() => api.bibleStudies.delete(studySlug))
        default:
          break;
      }
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const title = register('title', {required});
  const description = register('description', {required});
  const body = register('body', {required});

	// todo: debounce autosave
	const handleEditorChange = (value: Descendant[]) => {
    body.setValue(value);
	}

  const preparePublish = () => publishModalControl.setModalOpen(true);

  const handlePublish = (version: string) => {
    (async () => {
      api.bibleStudies.publish(studySlug, {version})
        .then((result) => result)
        .catch((err) => console.error(err))
        .finally(() => {
          publishModalControl.setModalOpen(false);
          navigate(0);
        });
    })();
  }

  return (
    <ScrollView>
      <Modal control={publishModalControl} width="expanded">
        <PublishForm
          version={version}
          draft={slateToHtml(getValues('body'))}
          published={slateToHtml(published)}
          onPublish={handlePublish}
          onClose={() => publishModalControl.setModalOpen(false)}
        />
      </Modal>
      <FocusedContent>
        {loading ? <Loader /> : (
          <Container>
            {props.action === 'Delete' ? (
              bibleStudy ? (
                <>
                  <Heading>Delete Bible study '{bibleStudy.title}'</Heading>
                  <Text style={tw('my-6')}>This action cannot be undone! Are you sure?</Text>
                  <View style={tw('flex-row justify-between items-center')}>
                    <Button type="danger" onPress={onSubmit}>
                      Delete
                    </Button>
                    <Button type="outline" onPress={goBack}>
                      Cancel
                    </Button>
                  </View>
                </>
              ) : <Loader />
            ) : (
              <>
                <Heading>{props.action ?? 'Update'} Bible study in series</Heading>
                {props.action === 'Update' && bibleStudy?.title && (
                  <View>
                    <Heading size="lg" style={tw('mb-6')}>
                      {bibleStudy.title}
                    </Heading>
                  </View>
                )}
                <Input
                  {...title}
                  label="Title"
                  placeholder="Title"
                />
                <View style={tw('mt-1 mb-4')}>
                  <Text>
                    Version: {bibleStudy?.version}
                  </Text>
                </View>
                <Input
                  {...description}
                  label="Description"
                  placeholder="Description"
                  multiline
                  numberOfLines={6}
                />
                <Label>Content</Label>
                {initialBodyValue.length > 0 && <Editor
                  theme={primaryFormTheme}
                  initialValue={initialBodyValue}
                  onValueChange={handleEditorChange}
                />}
                <View style={tw('flex-row justify-between items-center')}>
                  <Button type="solid" onPress={onSubmit}>
                    Save
                  </Button>
                  <Button type="outline" onPress={goBack}>
                    Discard
                  </Button>
                </View>
                <View style={tw('mt-4')}>
                  <Button type="solid" onPress={preparePublish} style={tw('bg-sage')}>
                    Publish
                  </Button>
                </View>
              </>
            )}
          </Container>
        )}
      </FocusedContent>
    </ScrollView>
  );
}
