import React from 'react';
import {Text as NativeText, TextProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {useFont} from '~/hooks';
import {FontWeight} from '~/types';

export function Text({style, weight, ...props}: TextProps & {weight?: FontWeight}) {
  const tw = useTailwind();
  const fontFamily = useFont('Montserrat', weight ?? 'normal');

  return (
    <NativeText
      style={[{fontFamily}, tw('text-primary dark:text-dark-primary'), style]}
      {...props}>
      {props.children}
    </NativeText>
  );
}
