import React, {CSSProperties, PropsWithChildren} from 'react'
import {Pressable} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {useSlate, RenderElementProps, RenderLeafProps} from 'slate-react'
import {useTailwind} from 'tailwind-rn';

import {FormatType, TEXT_ALIGN_TYPES} from './types';
import {isBlockActive, isMarkActive, toggleBlock, toggleMark} from './functions';
import {
	BlockQuoteIcon,
	BoldIcon,
	BulletedListIcon,
	CenterAlignIcon,
	HeadingOneIcon,
	HeadingTwoIcon,
	InputIcon,
	ItalicIcon,
	JustifyAlignIcon,
	LeftAlignIcon,
	NumberedListIcon,
	RightAlignIcon,
	SectionTitleIcon,
	TextboxIcon,
	UnderlineIcon,
	WorkbookContextIcon,
	SlideContextIcon,
} from '~/assets/icons';

interface MarkButtonIconProps {
	format: FormatType;
}

function FormatIcon(props: MarkButtonIconProps) {
	const iconProps: PropsWithChildren<SvgProps> = {
		height: 20,
	};

	switch (props.format) {
		case 'bold': return <BoldIcon {...iconProps} />;
		case 'italic': return <ItalicIcon {...iconProps} />;
		case 'underline': return <UnderlineIcon {...iconProps} />;
		case 'heading-one': return <HeadingOneIcon {...iconProps} />;
		case 'heading-two': return <HeadingTwoIcon {...iconProps} />;
		case 'block-quote': return <BlockQuoteIcon {...iconProps} />;
		case 'numbered-list': return <NumberedListIcon {...iconProps} />;
		case 'bulleted-list': return <BulletedListIcon {...iconProps} />;
		case 'textbox': return <TextboxIcon {...iconProps} />;
		case 'left': return <LeftAlignIcon {...iconProps} />;
		case 'center': return <CenterAlignIcon {...iconProps} />;
		case 'right': return <RightAlignIcon {...iconProps} />;
		case 'justify': return <JustifyAlignIcon {...iconProps} />;
		case 'input': return <InputIcon {...iconProps} />;
		case 'section-title': return <SectionTitleIcon {...iconProps} />;
		case 'workbook-context': return <WorkbookContextIcon {...iconProps} />;
		case 'slide-context': return <SlideContextIcon {...iconProps} />;
		default: return <></>;
	}
}

interface MarkButtonProps {
	format: FormatType;
}

export function MarkButton(props: MarkButtonProps) {
	const tw = useTailwind();
	const editor = useSlate();
	const isActive = isMarkActive(editor, props.format);

	return (
		<Pressable style={tw(`mr-4 ${isActive ? '' : 'opacity-50'}`)} onPress={() => toggleMark(editor, props.format)}>
			<FormatIcon format={props.format} />
		</Pressable>
	);
}

interface BlockButtonProps {
	format: FormatType;
}

export function BlockButton(props: BlockButtonProps) {
	const tw = useTailwind();
	const editor = useSlate();
	const isActive = isBlockActive(
		editor,
		props.format,
		// todo: types
		(TEXT_ALIGN_TYPES.includes(props.format) ? 'align' : 'type') as any
	);

	return (
		<Pressable style={tw(`mr-4 ${isActive ? '' : 'opacity-50'}`)} onPress={() => toggleBlock(editor, props.format)}>
			<FormatIcon format={props.format} />
		</Pressable>
	);
}

interface ElementProps extends RenderElementProps { }

const blankSpaceStyles: CSSProperties = {
	backgroundColor: '#fff',
	color: '#000',
	paddingLeft: 4,
	paddingRight: 4,
	paddingTop: 1,
	paddingBottom: 1,
	borderRadius: 2,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '#ccc',
};

const workbookContextStyles: CSSProperties = {
	backgroundColor: '#5f9acf',
	color: '#000',
	paddingLeft: 4,
	paddingRight: 4,
	paddingTop: 1,
	paddingBottom: 1,
	borderRadius: 2,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '#ccc',
};

const slideContextStyles: CSSProperties = {
	backgroundColor: '#6bd160',
	color: '#000',
	paddingLeft: 4,
	paddingRight: 4,
	paddingTop: 1,
	paddingBottom: 1,
	borderRadius: 2,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '#ccc',
};

export function Element({attributes, children, element}: ElementProps) {
	const style = {textAlign: element.align}
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
		case 'textbox':
			return (
				<div
					style={{
						...style,
						...blankSpaceStyles,
					}}
					{...attributes}
				>
					{children}
				</div>
			)
		case 'section-title':
			return (
        <p
					style={{
						...style,
						...{
							fontSize: 20,
							opacity: 0.5,
							textTransform: 'uppercase',
						},
					}}
					{...attributes}
				>
          {children}
        </p>
			)
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

interface LeafProps extends RenderLeafProps { }

export function Leaf({attributes, children, leaf}: LeafProps) {
	if (leaf.bold) {
		children = <strong>{children}</strong>
	}

	if (leaf.italic) {
		children = <em>{children}</em>
	}

	if (leaf.underline) {
		children = <u>{children}</u>
	}

	if (leaf['workbook-context']) {
		children = (
			<span
				style={workbookContextStyles}
				{...attributes}
			>
				{children}
			</span>	
		)
	}

	if (leaf['slide-context']) {
		children = (
			<span
				style={slideContextStyles}
				{...attributes}
			>
				{children}
			</span>	
		)
	}

	if (leaf.input) {
		children = (
			<span
				style={blankSpaceStyles}
				{...attributes}
			>
				{children}
			</span>	
		)
	}

  return <span {...attributes}>{children}</span>
}

export function Toolbar(props: any) {
	const tw = useTailwind();
	return (
		<div style={{...tw('flex flex-wrap'), color: 'white'}}>
			{props.children}
		</div>
	);
}
