import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {useFont, useProfile} from '~/hooks';
import {PickerOption} from '~/types';

function PickerInput(props: {
  options: PickerOption[],
  value?: string;
  chooseOptionLabel: string;
  styles: StyleProp<ViewStyle>;
  onChange: (...event: any[]) => void;
  setOption: (option: string) => void;
}) {
  const {options, value, chooseOptionLabel, styles, onChange, setOption} = props;
  const fontFamily = useFont('Montserrat', 'normal');
  const {profile} = useProfile();

  return (
    <select
      value={value}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setOption(value);
        onChange(value);
      }}
      style={{
        ...{fontFamily, colorScheme: profile.darkMode ? 'dark' : 'light'},
        ...styles as React.CSSProperties,
      }}
    >
      {chooseOptionLabel && <option value="" disabled hidden>{chooseOptionLabel}</option>}
      {options.map(({ value, label }, i) => <option key={`${value}-${i}`} value={value}>{label}</option>)}
    </select>
  )
}

export default PickerInput;
