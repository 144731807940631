import React, {useState} from 'react';
import {ImageCropData, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import {launchImageLibrary, launchCamera, ImageLibraryOptions} from 'react-native-image-picker';
import ImageEditor from '~/util/ImageEditor';

import {Heading, Container, Button, ScrollView, PersonImage, Text} from '~/components/core';
import {useForm, useNotify, useProfile} from '~/hooks';
import {useCompanionApi} from '~/api/companion';
import {MdtpContentFormProps} from '~/types';
import {colors} from '~/theme';
import {CameraAddIcon, UploadIcon} from '~/assets/icons';

interface UpdatePictureForm {
  profilePicture: File;
}

interface UpdatePictureFormProps extends MdtpContentFormProps {}

const maxWidth = 256;
const maxHeight = 256;

export function UpdatePictureForm(props: UpdatePictureFormProps) {
  const {handleSubmit, setValue} = useForm<UpdatePictureForm>({
    theme: props.theme,
  });
  const tw = useTailwind();
  const {api} = useCompanionApi();
  const {profile, setProfile} = useProfile();
	const {pushNotifyPopup} = useNotify();

  const onSubmit = handleSubmit(
    (data, e) => {
      api.users.updateImage(profile.id, data.profilePicture)
        .then(() => {
          setProfile({...profile, pictureUri: imageUri!});
					pushNotifyPopup({
						message: 'Profile picture updated successfully.',
						type: 'success',
					});
          props.onClose();
        })
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const [imageUri, setImageUri] = useState<string | null>(null);

  const uploadImage = (existing: boolean) => {
    const options: ImageLibraryOptions = {mediaType: 'photo', maxWidth, maxHeight};
    const method = existing ? launchImageLibrary : launchCamera;

    method(options)
      .then(async (image) => {
        const data = image.assets && image.assets[0];
        if (data) {
          const uri = data.uri!;
          const cropData: ImageCropData = {
            // offset to the center of the picture
            offset: {x: (data.width! - maxWidth) / 2, y: (data.height! - maxHeight) / 2},
            size: {width: maxWidth, height: maxHeight},
            resizeMode: 'cover',
          };

          const cropped = await ImageEditor.cropImage(uri, cropData);
          const croppedUri = `data:${cropped.type};base64,${cropped.uri}`;

          setImageUri(croppedUri);
          const blob = await (await fetch(croppedUri)).blob();
          setValue('profilePicture', new File([blob], `${profile.id}.jpeg`, blob));
        }
      })
      .catch(err => console.error(err))
      .finally(() => {/*todo: add loading bar finish*/});
  }

  return (
    <ScrollView>
      <Container>
        <Heading>Update picture</Heading>
        <View style={tw('items-center')}>
          <PersonImage style={tw('my-4')} size="massive" uri={imageUri ?? profile.pictureUri} />
        </View>
        <View style={tw('mt-4 mb-8 justify-center items-center')}>
          <View style={tw('my-1 justify-center')}>
            <Button type="text" onPress={() => uploadImage(true)}>
              <View style={tw('flex-row items-center')}>
                <UploadIcon width={25} height={25} stroke={colors.white} />
                <Text style={tw('text-xl py-1 px-5')}>
                  Upload
                </Text>
              </View>
            </Button>
          </View>
          <View style={tw('my-1 justify-center')}>
            <Button type="text" onPress={() => uploadImage(false)}>
              <View style={tw('flex-row items-center')}>
                <CameraAddIcon width={25} height={25} stroke={colors.white} />
                <Text style={tw('text-xl py-1 px-5')}>
                  Take photo
                </Text>
              </View>
            </Button>
          </View>
        </View>
        <View style={tw('flex-row justify-between items-center')}>
          <Button type="solid" onPress={onSubmit}>
            Update
          </Button>
          <Button type="outline" onPress={props.onClose}>
            Cancel
          </Button>
        </View>
      </Container>
    </ScrollView>
  );
}
