// for more direct usage with ReactNative
const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#fff',
  black: '#000',
  gainsboro: '#d9dade',
  gray: '#bbb',
  primary: '#333',
  'dark-primary': '#eee',
  secondary: '#5b5b5b',
  highlight: '#4f61fb',
  'dark-highlight': '#9da6f9',
  inactive: '#aaa',
  navy: '#1f222a',
  stone: '#2b3038',
  danger: '#ff4026',
  sage: '#37804b',
  warning: '#bf6e3b',
};

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

const widths = {
  focus: 600,
  stretch: 1000,
  expanded: 1600,
};

module.exports = {
  colors,
  screens,
  widths,
};
