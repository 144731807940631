import {useWindowDimensions} from 'react-native';

import {screens} from '~/theme';

export function useBreakpoint() {
  const {width} = useWindowDimensions();

  return {
    breakSm: width > screens.sm,
    breakMd: width > screens.md,
    breakLg: width > screens.lg,
    breakXl: width > screens.xl,
    break2xl: width > screens['2xl'],
  };
}
