import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {colors} from '~/theme';
import {PlusIcon, TrashIcon} from '~/assets/icons';
import {Heading, LoadingOrResult, PersonListing, Modal} from '~/components/core';
import {useModalControl} from '~/components/core/Modal';
import {useBreakpoint, useForm, useProfile, useDebounce} from '~/hooks';
import {Input} from '~/components/form';
import {useCompanionApi} from '~/api/companion';
import {Country, User} from '~/api/companion/models/response';
import {UserSearchRequest} from '~/api/companion/models/request';
import {UserForm} from '~/forms';
import {secondaryFormTheme} from '~/types';
import {I18nDataContext} from '~/contexts';

export function Users() {
  const {register, handleSubmit, getValues, watch} = useForm<UserSearchRequest>({
    defaultValues: {
      page: 0,
      size: 10,
      search: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const lastSearch = watch('search');

  const getUsers = (data: UserSearchRequest) => {
    setLoading(true);
    api.users.paginate(data)
      .then((users) => {
        setUsers(users.items);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const onSubmit = handleSubmit(
    (data, e) => {
      getUsers(data);
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const tw = useTailwind();
  const {breakMd} = useBreakpoint();

  const search = register('search');
  const debouncedSearch = useDebounce(lastSearch);

  const {profile} = useProfile();
  const [users, setUsers] = useState<User[]>([]);
  const addUserModalControl = useModalControl();
  const editUserModalControl = useModalControl();
  const confirmDeleteUserModalControl = useModalControl();
  const {api} = useCompanionApi();
  const [editUser, setEditUser] = useState<User>();
  const handleEditUser = (user: User) => {
    setEditUser(user);
    editUserModalControl.setModalOpen(true);
  }
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    (async () => {
      getUsers(getValues());
      // todo: only do the below if it's not cached already.
      Promise.all([
        api.i18n.countries(),
        // api.i18n.languages(),
      ])
        .then(([countries]) => {
          setCountries(countries);
        })
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    })();
  }, []);

  useEffect(() => {
    onSubmit();
  }, [debouncedSearch]);

  return (
    <>
      <I18nDataContext.Provider value={{countries}}>
        <Modal control={addUserModalControl}>
          <UserForm
            action="Create"
            theme={secondaryFormTheme}
            onReload={onSubmit}
            onClose={() => addUserModalControl.setModalOpen(false)}
          />
        </Modal>
        <Modal control={editUserModalControl}>
          <UserForm
            action="Update"
            theme={secondaryFormTheme}
            onReload={onSubmit}
            onClose={() => editUserModalControl.setModalOpen(false)}
            user={editUser}
          />
        </Modal>
        <Modal control={confirmDeleteUserModalControl}>
          <UserForm
            action="Delete"
            theme={secondaryFormTheme}
            onReload={onSubmit}
            onClose={() => confirmDeleteUserModalControl.setModalOpen(false)}
            user={editUser}
          />
        </Modal>
      </I18nDataContext.Provider>
      <View style={tw('flex-row justify-between items-center')}>
        <Heading size="lg">Users</Heading>
        <TouchableOpacity onPress={() => addUserModalControl.setModalOpen(true)}>
          <PlusIcon width={25} height={25} stroke={profile.darkMode ? colors['dark-highlight'] : colors.highlight} />
        </TouchableOpacity>
      </View>
      <View style={breakMd && {maxWidth: 300}}>
        <Input {...search} label="Search" placeholder="Enter a search criteria" />
      </View>
      <LoadingOrResult
        loading={loading}
        results={users.map((u, i) => (
          <PersonListing
            key={i}
            onPress={() => handleEditUser(u)}
            user={u}
            actions={(
              <TouchableOpacity
                style={tw('mr-4')}
                onPress={() => {
                  setEditUser(u);
                  confirmDeleteUserModalControl.setModalOpen(true)}
                }
              >
                <TrashIcon width={15} fill={colors.danger} />
              </TouchableOpacity>
            )}
            showRoles
          />
        ))}
      />
    </>
  );
}
