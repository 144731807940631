import React from 'react';
import {
  View,
  useWindowDimensions,
  Animated,
  Text,
  Pressable,
  StyleProp,
  ViewStyle
} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import {
  TabView as NativeTabView,
  SceneRendererProps,
  NavigationState,
  SceneMap,
  TabViewProps as NativeTabViewProps,
} from 'react-native-tab-view';

import {TabViewScene} from '~/types';
import {useBreakpoint} from '~/hooks';

interface TabViewProps {
  tabStyles?: StyleProp<ViewStyle>;
  tabs: TabViewScene[];
  tabViewProps?: Partial<NativeTabViewProps<any>>;
}

export function TabView({tabStyles, tabs, tabViewProps} : TabViewProps) {
  if (tabs.length < 2) {
    console.error('TabView must have at least 2 tabs.');
    return <></>;
  }

  const tw = useTailwind();
  const {breakMd} = useBreakpoint();
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(0);
  const scenes = SceneMap({...tabs.reduce((acc, t) => ({...acc, [t.key]: t.component}), {})}); 

  const renderTabBar = (props: SceneRendererProps & {navigationState: NavigationState<{key: string; title: string;}>}) => {
    const inputRange = props.navigationState.routes.map((_, i) => i);

    return (
      <View style={[tw('flex-row p-1 rounded-md bg-inactive dark:bg-stone mb-4 mx-4'), tabStyles]}>
        {props.navigationState.routes.map((route, i) => {
          const backgroundColor = props.position.interpolate({
            inputRange,
            outputRange: inputRange.map((inputIndex) =>
              inputIndex === i ? '#fff' : 'transparent'
            ),
          });

          return (
            <Pressable
              key={i}
              style={tw('flex-1')}
              onPress={() => setIndex(i)}
            >
              <Animated.View style={[tw(`flex-1 items-center p-2 rounded-md ${i === (tabs.length - 1) ? '' : 'mr-2'}`), {backgroundColor}]}>
                <Text style={tw('text-primary dark:text-highlight')}>
                  {route.title}
                </Text>
              </Animated.View>
            </Pressable>
          );
        })}
      </View>
    );
  };

  return (
    <NativeTabView
      navigationState={{index, routes: tabs}}
      renderScene={(sceneProps) => <View style={tw('px-4')}>{scenes(sceneProps)}</View>}
      onIndexChange={setIndex}
      initialLayout={{width: layout.width}}
      style={tw('-mx-4')}
      renderTabBar={renderTabBar}
      animationEnabled={!breakMd}
      swipeEnabled={!breakMd}
      {...tabViewProps}
    />
  );
}
