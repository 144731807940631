import React from 'react';
import {Pressable, StatusBar, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Outlet, useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {ArrowLeftIcon} from '~/assets/icons';
import {Text} from '~/components/core';
import {useFont, useNavigationInfo, useProfile, useTitle} from '~/hooks';
import {colors} from '~/theme';

export function Screen() {
  const {profile} = useProfile();
  const {navigationInfo} = useNavigationInfo();
  const {title} = useTitle();
  const navigate = useNavigate();
  const insets = useSafeAreaInsets();
  const tw = useTailwind();
  const fontFamily = useFont('Montserrat', 'semibold');

  return (
    <>
      <StatusBar
        barStyle={profile.darkMode ? 'light-content' : 'dark-content'}
      />
      {navigationInfo.showTopNav && (
        <View style={[tw('bg-white dark:bg-stone'), {paddingTop: insets.top}]}>
          <View style={tw('flex-row items-center py-4 px-2')}>
            <Pressable onPress={() => navigate(-1)}>
              <ArrowLeftIcon
                style={tw('mx-2')}
                width={25}
                height={25}
                fill={profile.darkMode ? colors.white : colors.black}
              />
            </Pressable>
            <Text style={[tw('text-base mx-4'), {fontFamily}]} key={title}>
              {title}
            </Text>
          </View>
        </View>
      )}

      <View
        style={[
          tw('flex-1'),
          !navigationInfo.showTopNav && {paddingTop: insets.top},
        ]}>
        <Outlet context={[navigationInfo.showTopNav]} />
      </View>
    </>
  );
}
