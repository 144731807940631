import React from 'react';
import {View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Panel} from '~/components/core';
import {SkeletonHeading} from './SkeletonHeading';

export function SkeletonPanel() {
  const tw = useTailwind();

  return (
    <Panel>
      <SkeletonHeading />
      <View style={tw('bg-white w-64 h-4 mt-2 opacity-50 rounded-md')} />
    </Panel>
  );
}
