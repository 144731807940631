import React from 'react';
import {Controller} from 'react-hook-form';
import {TextInput, TextInputProps, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {Label} from '~/components/form';
import {usePlatform, useProfile} from '~/hooks';
import {colors} from '~/theme';
import {UseFormRegisterReturnExtended} from '~/types';

function _Input(
  props: UseFormRegisterReturnExtended & TextInputProps & {
    label?: string, onChangeText?: () => void,
    tight?: boolean,
    expand?: boolean,
    correct?: boolean,
  },
  ref: React.Ref<any>,
) {
  const {label, name, control, rules, error, theme, correct} = props;
  const {profile} = useProfile();
  const tw = useTailwind();
  const {isWeb} = usePlatform();
  const outlineNone = {outlineStyle: 'none'};
  const verticalFix = {textAlignVertical: 'top'};
  const correctStyles = correct != undefined ? correct ? 'border border-sage' : 'border border-warning' : '';

  return (
    <View style={[!props.tight && tw('mb-4'), props.expand && tw('w-full h-full')]} ref={ref}>
      {label && <Label>{label}</Label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: {onChange, onBlur, value}}) => (
          <TextInput
            {...props}
            style={[
              tw(
                `${theme!.inputColor} rounded-md p-2
                ${error ? 'border border-danger' : ''}
                ${correctStyles}`,
              ),
              props.multiline && {
                ...(isWeb && outlineNone),
                ...verticalFix,
              },
              props.disabled && tw('text-inactive'),
              props.expand && tw('w-full h-full'),
            ]}
            placeholderTextColor={
              profile.darkMode ? colors.gainsboro : colors.secondary
            }
            onBlur={onBlur}
            onChangeText={(e) => {
              onChange(e);
              if (props.onChangeText) props.onChangeText();
            }}
            value={value ?? ''}
          />
        )}
      />
      {error && (
        <Text style={tw('text-primary text-danger mt-1')}>
          {label} {error.message}
        </Text>
      )}
    </View>
  );
}

export const Input = React.forwardRef(_Input);
export default Input;
