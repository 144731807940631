import React, {useCallback, useMemo} from 'react'
import {createEditor, Descendant, BaseElement, Element as SlateElement, Node} from 'slate'
import {withHistory} from 'slate-history';
import {Slate, Editable, withReact, RenderElementProps} from 'slate-react'
import isHotkey from 'is-hotkey';
import {useTailwind} from 'tailwind-rn';
import {ListType, ListsSchema, withLists, onKeyDown as listKeyDown} from '@prezly/slate-lists';

import {BlockButton, Element, Leaf, MarkButton, Toolbar} from './components';
import {EDITOR_SEED, HotkeyType, Hotkeys} from './types';
import {toggleMark} from './functions';
import {EditorProps} from './EditorProps';

declare module 'slate' {
	interface CustomTypes {
		SlateElement: { type: Type } & BaseElement;
	}
}

enum Type {
	PARAGRAPH = 'paragraph',
	ORDERED_LIST = 'numbered-list',
	ORDERED = 'numbered',
	UNORDERED_LIST = 'bulleted-list',
	UNORDERED = 'bulleted',
	LIST_ITEM = 'list-item',
	LIST_ITEM_TEXT = 'list-item-text',
}

const listsSchema: ListsSchema = {
	isConvertibleToListTextNode(node: Node) {
		return SlateElement.isElementType(node, Type.PARAGRAPH);
	},
	isDefaultTextNode(node: Node) {
		return SlateElement.isElementType(node, Type.PARAGRAPH);
	},
	isListNode(node: Node, type?: ListType) {
		if (type === ListType.ORDERED) {
			return SlateElement.isElementType(node, Type.ORDERED);
		}
		if (type === ListType.UNORDERED) {
			return SlateElement.isElementType(node, Type.UNORDERED);
		}
		return (
			SlateElement.isElementType(node, Type.ORDERED_LIST) ||
			SlateElement.isElementType(node, Type.UNORDERED_LIST)
		);
	},
	isListItemNode(node: Node) {
		return SlateElement.isElementType(node, Type.LIST_ITEM);
	},
	isListItemTextNode(node: Node) {
		return SlateElement.isElementType(node, Type.LIST_ITEM_TEXT);
	},
	createDefaultTextNode(props = {}) {
		return { children: [{ text: '' }], ...props, type: Type.PARAGRAPH };
	},
	createListNode(type: ListType = ListType.UNORDERED, props = {}) {
		const nodeType = type === ListType.ORDERED ? Type.ORDERED_LIST : Type.UNORDERED_LIST;
		return { children: [{ text: '' }], ...props, type: nodeType };
	},
	createListItemNode(props = {}) {
		return { children: [{ text: '' }], ...props, type: Type.LIST_ITEM };
	},
	createListItemTextNode(props = {}) {
		return { children: [{ text: '' }], ...props, type: Type.LIST_ITEM_TEXT };
	},
};

export default function EditorInput(props: EditorProps) {
	const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
	const renderLeaf = useCallback(props => <Leaf {...props} />, []);
	const editor = useMemo(() => withLists(listsSchema)(withHistory(withReact(createEditor()))), []);
	const tw = useTailwind()
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		for (const hotkey in Hotkeys) {
			if (isHotkey(hotkey, event)) {
				event.preventDefault();
				const mark = Hotkeys[hotkey as HotkeyType];
				toggleMark(editor, mark);
			}
		}
		listKeyDown(editor, event);
	};
	const initialValue: Descendant[] = props.initialValue.length > 0
		? props.initialValue
		: EDITOR_SEED;

  return (
		<Slate
			editor={editor}
			initialValue={initialValue}
			onValueChange={props.onValueChange}
		>
			<Toolbar>
				<MarkButton format="bold" />
				<MarkButton format="italic" />
				<MarkButton format="underline" />
				<BlockButton format="heading-one" />
				<BlockButton format="heading-two" />
				<BlockButton format="block-quote" />
				<BlockButton format="numbered-list" />
				<BlockButton format="bulleted-list" />
				<BlockButton format="left" />
				<BlockButton format="center" />
				<BlockButton format="right" />
				<BlockButton format="justify" />
				<MarkButton format="input" />
				<BlockButton format="textbox" />
				<BlockButton format="section-title" />
				<MarkButton format="workbook-context" />
				<MarkButton format="slide-context" />
			</Toolbar>
			<Editable
				style={{
					...{outline: 'none'},
					...tw(`${props.theme.inputColor} rounded-md p-2 my-2`),
				}}
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				spellCheck
				autoFocus
				onKeyDown={handleKeyDown}
				placeholder={props.placeholder}
				onBlur={props.onBlur}
			/>
    </Slate>
  );
}
