import {
	Editor,
	Element as SlateElement,
	Transforms,
	Path as SlatePath,
	Descendant,
	Text as SlateText,
} from 'slate'
import escapeHtml from 'escape-html'

import {CustomEditor} from '~/types';
import {BlockType, FormatType, LIST_TYPES, TEXT_ALIGN_TYPES} from './types';
import {generateId} from '~/util/functions';

export const isMarkActive = (editor: CustomEditor, format: FormatType) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
}

const setNodeProperties = (editor: CustomEditor, path: SlatePath, properties: any) => {
	Transforms.setNodes<SlateElement>(
		editor,
		properties,
		{at: path},
	);
}

export const toggleMark = (editor: CustomEditor, format: FormatType) => {
	const isActive = isMarkActive(editor, format);
	if (isActive) {
		Editor.removeMark(editor, format);
		const selectedPath = editor.selection!.focus.path;
		switch (format) {
			// remove id to input node for grading.
			case 'input': setNodeProperties(editor, selectedPath, { id: undefined }); break;
			default: break;
		}
	} else {
		Editor.addMark(editor, format, true);
		const selectedPath = editor.selection!.focus.path;
		switch (format) {
			case 'input': {
				// add id to input node for grading.
				setNodeProperties(
					editor,
					selectedPath,
					{
						id: generateId(),
						bold: undefined,
						italic: undefined,
						// etc.
					},
				);
			} break;
			default: break;
		}
	}
}

export const isBlockActive = (editor: CustomEditor, format: FormatType, blockType: BlockType = 'type') => {
	const {selection} = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: n =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n[blockType] === format,
		})
	);

	return !!match;
}

const serialize = (node: Descendant) => {
  if (SlateText.isText(node)) {
    return escapeHtml(node.text)
  }

  const children: string = node.children.map(n => serialize(n)).join('');
	return children;
}

export const toggleBlock = (editor: CustomEditor, format: FormatType) => {
	const isActive = isBlockActive(
		editor,
		format,
		// todo: types
		(TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type') as any
	);

	if (format === 'textbox') {
		if (!isActive) {
			const path = editor.selection!.anchor.path[0];
			const text = serialize(editor.children[path]);
			Transforms.removeNodes(editor);
			Transforms.insertNodes(
				editor,
				{
					type: 'textbox',
					id: generateId(),
					children: [{text}],
				},
			);
			return;
		} else {
			Transforms.setNodes<SlateElement & {id?: string}>(editor, {type: 'paragraph', id: undefined});
			return;
		}
	}

	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: n =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true,
	});
	// let newProperties: Partial<SlateElement>;
	// todo: types
	let newProperties: Record<any, any>;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		}
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		}
	}
	Transforms.setNodes<SlateElement>(editor, newProperties);

	if (!isActive && isList) {
		const block = {type: format, children: []};
		// todo: types
		Transforms.wrapNodes(editor, block as any);
	}
}
