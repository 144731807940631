import {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useForm as useFormInternal,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn,
} from 'react-hook-form';
import {FormTheme, primaryFormTheme, SetFormInputValueOptions} from '~/types';

export type UseFormRegisterExtended<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>,
) => UseFormRegisterReturn<TFieldName> & {
  control: Control<TFieldValues>;
  rules?: RegisterOptions<TFieldValues, TFieldName>;
  error: FieldError;
  setValue: (value: PathValue<TFieldValues, TFieldName>, options?: SetFormInputValueOptions) => void;
};

export interface UseFormReturnExtended<TFieldValues, TContext>
  extends UseFormReturn<TFieldValues, TContext> {
  register: UseFormRegisterExtended<TFieldValues>;
}

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>(
  props?: UseFormProps<TFieldValues, TContext> & {theme?: FormTheme},
): UseFormReturnExtended<TFieldValues, TContext> {
  const formTools = useFormInternal<TFieldValues>({
    ...props,
    shouldFocusError: false, // workaround for [No command found with name "focus"]
  });

  const register: UseFormRegisterExtended<TFieldValues> = (name, options) => ({
    ...formTools.register(name, options),
    control: formTools.control,
    rules: options,
    error: formTools.formState.errors[name],
    theme: props?.theme ?? primaryFormTheme,
    // override hack
    setValue: (
      value: PathValue<TFieldValues, TFieldName>,
      options?: SetFormInputValueOptions) =>
      formTools.setValue(name, value, options),
  });

  return {
    ...formTools,
    register,
  };
}
