import {createContext} from 'react';
import {NotifyPopupInfo} from '~/types';

interface NotifyContextType {
  notifyPopups: NotifyPopupInfo[];
  pushNotifyPopup: (info: NotifyPopupInfo) => void;
  popNotifyPopup: () => void;
  removeNotifyPopupAtIndex: (index: number) => void;
  removeNotifyPopup: (info: NotifyPopupInfo) => void;
  clearNotifyPopups: () => void;
  setNotifyPopups: React.Dispatch<React.SetStateAction<NotifyPopupInfo[]>>;
}

export const NotifyContext = createContext<NotifyContextType>(undefined!);
