import React from 'react';
import {useNavigate} from 'react-router-native';

import {Button, Container, FocusedContent, Heading, ScrollView} from '~/components/core';
import {DatePicker, Input} from '~/components/form';
import {Gap} from '~/components/layout';
import {useBreakpoint, useForm} from '~/hooks';
import {required} from '~/util/rules';

interface ScheduleStudyForm {
  firstName: string;
  lastName: string;
  date: Date | string;
  location: string;
  notes: string;
}

export function AddScheduledStudy() {
  const navigate = useNavigate();
  const {register, handleSubmit} = useForm<ScheduleStudyForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      date: new Date(Date.now()),
      location: '',
      notes: '',
    },
  });

  const onSubmit = handleSubmit(
    (data, e) => {
      navigate('/');
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const firstName = register('firstName', {required});
  const lastName = register('lastName', {required});
  const date = register('date', {required});
  const location = register('location', {required});
  const notes = register('notes');
  const {breakMd} = useBreakpoint();

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <Heading>Schedule Bible Study</Heading>
          <Gap gap={4}>
            <Input
              {...firstName}
              label="First Name"
              placeholder="First Name"
              autoCapitalize="words"
            />
            <Input
              {...lastName}
              label="Last Name"
              placeholder="Last Name"
              autoCapitalize="words"
            />
          </Gap>
          <DatePicker {...date} label="Date" placeholder="Date" />
          <Input {...location} label="Location" placeholder="Location" />
          <Input
            {...notes}
            label="Notes"
            placeholder="Notes"
            multiline
            numberOfLines={6}
          />
          <Button type="solid" full={!breakMd} onPress={onSubmit}>
            Add
          </Button>
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
