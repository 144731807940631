import React from 'react';
import {useTailwind} from 'tailwind-rn';

import {Heading} from '~/components/core';

export function Stats() {
  const tw = useTailwind();

  return (
    <Heading size="2xl" weight="semibold" style={tw('text-highlight')}>
      Stats
    </Heading>
  );
}

export * from './AddConversation';
export * from './AddScheduledStudy';
