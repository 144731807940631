import React from 'react';
import {Text as NativeText, TextProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {useFont} from '~/hooks';
import {FontWeight} from '~/types';

enum HeadingSize {
  'xs' = 'text-xs',
  'sm' = 'text-sm',
  'base' = 'text-base',
  'lg' = 'text-xl',
  '2xl' = 'text-2xl',
  '3xl' = 'text-3xl',
}

export function Heading({
  style,
  ...props
}: TextProps & {size?: keyof typeof HeadingSize; weight?: FontWeight}) {
  const tw = useTailwind();
  const size = HeadingSize[props.size ?? '3xl'];
  const weight = props.weight ?? 'bold';
  const fontFamily = useFont('Montserrat', weight);

  return (
    <NativeText
      style={[
        {fontFamily},
        tw(`my-2 text-primary dark:text-dark-primary ${size}`),
        style,
      ]}
      {...props}>
      {props.children}
    </NativeText>
  );
}
