import React, {useState, useEffect} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Heading, Container, Button, Text} from '~/components/core';
import {useDebounce, useForm, useNotify} from '~/hooks';
import {Combobox, Input} from '~/components/form';
import {required} from '~/util/rules';
import {useCompanionApi} from '~/api/companion';
import {ComboboxOption, MdtpContentFormProps} from '~/types';
import {Course, CourseWithUsers} from '~/api/companion/models/response';

interface UpdateCourseForm {
  name: string;
  memberSearch: string;
  userIds: number[];
}

interface CourseFormProps extends MdtpContentFormProps {
  course?: CourseWithUsers;
  onReload: () => void;
}

export function CourseForm(props: CourseFormProps) {
  const {api} = useCompanionApi();
  const tw = useTailwind();
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, watch} = useForm<UpdateCourseForm>({
    defaultValues: {
      name: props.course?.name,
      userIds: props.course?.users.map(u => u.id),
    },
    theme: props.theme,
  });
  const {pushNotifyPopup} = useNotify();

  const courseAction = (action: () => Promise<Course | boolean>) => action()
    .then((course) => {
      props.onClose();
      pushNotifyPopup({
        message: `Course ${props.action?.toLowerCase()}d successfully.`,
        type: 'success',
      });
      props.onReload();
    })
    .catch(err => {
      console.error(err);
      pushNotifyPopup({
        message: `Failed to ${props.action?.toLowerCase()} course.`,
        type: 'error',
      })
    })
    .finally(() => setLoading(false));

  const onSubmit = handleSubmit(
    (data, e) => {
      setLoading(true);
      const submission = {
        ...data,
        userIds: selectedMembers.map(m => m.value as number),
      }
      switch (props.action) {
        case 'Create': return courseAction(() => api.classroom.create(submission));
        case 'Update': return courseAction(() => api.classroom.update(props.course!.id!, submission));
        case 'Delete': return courseAction(() => api.classroom.delete(props.course!.id!));
        default:
          break;
      }
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const name = register('name', {required});
  const memberSearch = register('memberSearch');
  const lastMemberSearch = watch('memberSearch') ?? '';
  const debouncedMemberSearch = useDebounce(lastMemberSearch);
  const [memberOptions, setMemberOptions] = useState<ComboboxOption[]>([]);
  const [membersLoading, setMembersLoading] = useState(true);
  const [selectedMembers, setSelectedMembers] = useState<ComboboxOption[]>([]);

  useEffect(() => {
    if (lastMemberSearch.length > 0) {
      setMembersLoading(true);
      api.users.paginate({
        page: 0,
        size: 4,
        search: lastMemberSearch,
      })
        .then((users) => {
          setMemberOptions(users.items.map(u => ({
            value: u.id,
            label: u.name,
          })));
        })
        .catch(err => console.error(err))
        .finally(() => setMembersLoading(false));
    }
  }, [debouncedMemberSearch]);

  return loading ? (
    <View style={tw('py-8')}>
      <ActivityIndicator size="large" />
    </View>
  ) : (
    <Container>
      {props.action === 'Delete' ? (
        <>
          <Heading>Delete course '{props.course?.name}'</Heading>
          <Text style={tw('my-6')}>This action cannot be undone! Are you sure?</Text>
          <View style={tw('flex-row justify-between items-center')}>
            <Button type="danger" onPress={onSubmit}>
              Delete
            </Button>
            <Button type="outline" onPress={props.onClose}>
              Cancel
            </Button>
          </View>
        </>
      ) : (
        <>
          <Heading>{props.action ?? 'Update'} course</Heading>
          {props.course && (
            <View>
              <Heading size="lg" style={tw('mb-6')}>
                {props.course?.name}
              </Heading>
            </View>
          )}
          <Input
            {...name}
            label="Name"
            placeholder="Name"
          />
          <Combobox
            label="Members"
            {...memberSearch}
            placeholder="Search for members"
            loading={membersLoading}
            defaultSelectedOptions={props.course?.users.map(u => ({
              label: u.name,
              value: u.id,
            }))}
            options={memberOptions}
            onChangeOptions={setSelectedMembers}
          />
          <View style={tw('flex-row justify-between items-center')}>
            <Button type="solid" onPress={onSubmit}>
              Submit
            </Button>
            <Button type="outline" onPress={props.onClose}>
              Cancel
            </Button>
          </View>
        </>
      )}
    </Container>
  );
}
