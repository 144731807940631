import React, {useEffect, useState} from 'react';
import {useWindowDimensions, View} from 'react-native';
import {useLocation, useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';
import RenderHtml, {CustomRendererProps, CustomTagRendererRecord, HTMLContentModel, HTMLElementModel} from 'react-native-render-html';
import {slateToHtml} from '@slate-serializers/html'

import {useAdministrative, useForm, UseFormRegisterExtended, useNotify, useProfile, useTitle} from '~/hooks';
import {useCompanionApi} from '~/api/companion';
import {PersonalBibleStudyDetail} from '~/api/companion/models/response';
import {Button, Container, FocusedContent, Heading, Kebab, Loader, Panel, ScrollView} from '~/components/core';
import {Input} from '~/components/form';
import {SkeletonHeading, SkeletonBody} from '~/components/skeleton';
import {colors} from '~/theme';
import {CustomElement, secondaryFormTheme} from '~/types';
import {createSlidesFromNodes} from '~/components/slides';
import {required} from '~/util/rules';
import {QuestionResult} from '~/api/companion/models';
import { bibleStudyHtmlConfig } from '~/util/htmlConfig';

interface HtmlPageProps {
	nodes: CustomElement[];
  width: number;
  register: UseFormRegisterExtended<any>;
  results: QuestionResult[];
}

type TagRendererProps = JSX.IntrinsicAttributes & CustomRendererProps<any>;

interface TagRenderersBaseProps {
  register: UseFormRegisterExtended<any>;
}

type TagRenderProps = CustomRendererProps<any> & TagRenderersBaseProps;

function InputRenderer({
  TDefaultRenderer,
  ...props
}: TagRenderProps) {
  const tw = useTailwind();
  const input = props.register(props.tnode.id!, {required});
  const dataCorrect: string = props.tnode.init.domNode.attribs['data-correct'];
  const dataValue: string = props.tnode.init.domNode.attribs['data-value'];

  return (
    <TDefaultRenderer {...props} style={tw('py-1')}>
      <Input
        {...input}
        tight
        {...((dataValue && dataValue.length > 0) && {defaultValue: dataValue})}
        {...((dataCorrect && dataCorrect.length > 0) && {correct: dataCorrect === 'true'})}
      />
    </TDefaultRenderer>
  );
}

const getTagRenderers = (baseProps: TagRenderersBaseProps): CustomTagRendererRecord => ({
  'rich-input': (props: TagRendererProps) => <InputRenderer {...baseProps} {...props} />,
});

const customHTMLElementModels = {
  'rich-input': HTMLElementModel.fromCustomModel({
    tagName: 'rich-input',
    contentModel: HTMLContentModel.block
  }),
};

function insertNodeResult(node: any, results: QuestionResult[]) {
	const index = results.findIndex((r) => r.id === node.id);
  if (index > -1) {
    node.correct = results[index].correct;
  }
	if (node && node.children?.length > 0) (node.children as any[]).forEach(n => insertNodeResult(n, results));
}

function processNodes(nodes: CustomElement[], results: QuestionResult[]) {
	let processed = JSON.parse(JSON.stringify(nodes));
	processed.forEach((n: any) => insertNodeResult(n, results));
	return processed;
}

function HtmlPage(props: HtmlPageProps) {
  const {profile} = useProfile();
  const html = slateToHtml(processNodes(props.nodes.filter(n => n), props.results), bibleStudyHtmlConfig);
  const renderers = getTagRenderers({register: props.register});

  return (
    <RenderHtml
      renderers={renderers}
      customHTMLElementModels={customHTMLElementModels}
      contentWidth={props.width}
      baseStyle={{
        fontSize: 16,
        color: profile.darkMode ? colors.white : colors.black,
        width: '100%',
        height: '100%',
      }}
      tagsStyles={{
        body: {height: '100%'},
      }}
      source={{html}}
    />
  );
}

interface StudyDocumentProps {
  study: PersonalBibleStudyDetail;
  results: QuestionResult[];
  setResults: React.Dispatch<React.SetStateAction<QuestionResult[]>>;
  slug: string;
}

function StudyDocument(props: StudyDocumentProps) {
  const {api} = useCompanionApi();
  const administrative = useAdministrative();
	const pages = createSlidesFromNodes(props.study.title, JSON.parse(JSON.stringify(props.study.published)), true);
  const {width} = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const tw = useTailwind();
  const {register, handleSubmit} = useForm<any>({
    defaultValues: props.results.reduce((a, v) => ({ ...a, [v.id]: v.answer}), {}),
    theme: secondaryFormTheme,
  });
  const {pushNotifyPopup} = useNotify();

  const onSubmit = handleSubmit(
    (data) => {
      setLoading(true);
      api.bibleStudies.updateSubmission(
        props.slug,
        {
          studyId: props.study.id,
          questions: Object.keys(data).map((key) => ({ id: key, value: data[key]})),
        })
        .then((res) => {
          pushNotifyPopup({
            message: 'Submission created successfully.',
            type: 'success',
          });
          props.setResults(res.results);
        })
        .catch(err => {
          console.error(err);
          pushNotifyPopup({
            message: 'Failed to send form submission.',
            type: 'error',
          })
        })
        .finally(() => setLoading(false));
    },
    (errors, e) => console.error({errors, e}),
  );

  return (
    <>
      {(props.study.body.length > 0) ? (
        loading ? (
          <Loader />
        ) : pages.map((p, i) => (
          <Panel key={i} style={tw('mb-4')}>
            <HtmlPage nodes={p} width={width} register={register} results={props.results} />
          </Panel>
        ))
      ) : (
        <Panel>
          <View>
            <SkeletonBody />
          </View>
        </Panel>
      )}
      <View style={tw('flex-row justify-between items-center')}>
        {!administrative && <Button type="solid" onPress={onSubmit}>
          Submit
        </Button>}
      </View>
    </>
  )
}

export function Study() {
  const {api} = useCompanionApi();
  const administrative = useAdministrative();
  const [loading, setLoading] = useState(false);
  const [study, setStudy] = useState<PersonalBibleStudyDetail>();
  const tw = useTailwind();
  const location = useLocation();
  const studySlug = location.pathname.split('study-series/').pop()!.split('/')[1];
  const titleContext = useTitle();
  const navigate = useNavigate();
  const [results, setResults] = useState<QuestionResult[]>([]);

  useEffect(() => titleContext.setTitle('Series'), []);
  useEffect(() => {
    setLoading(true);
    (async () => {
      Promise.all([
        api.bibleStudies.study(studySlug),
        api.bibleStudies.getSubmission(studySlug),
      ])
        .then(([studyDetail, submission]) => {
            setStudy(studyDetail);
            if (submission.results) setResults(submission.results);
            setLoading(false);
          })
        .catch((err) => console.error(err));
    })();
  }, []);

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          {study ? (
            <View style={tw('flex-row justify-between items-center')}>
              <Heading>{study.title}</Heading>
              {administrative && <Kebab onPress={() => navigate(`${location.pathname}/edit`)} direction="horizontal" />}
            </View>
          ) : (
            <SkeletonHeading />
          )}
          {loading ? (
            <Loader />
          ) : study && (
            <StudyDocument
              study={study}
              results={results}
              setResults={setResults}
              slug={studySlug}
            />
          )}
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
