import React, {useState} from 'react';
import {View} from 'react-native';
import {useLocation} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {NavigationInfoContext, TitleContext} from '~/contexts';
import {usePlatform, useProfile} from '~/hooks';

import {NavBar} from './NavBar';
import {Screen} from './Screen';
import {Login} from '~/views/Login';

export function Navigation() {
  const {profile} = useProfile();
  const {isWeb} = usePlatform();
  const tw = useTailwind();
  const location = useLocation();

  // hack
  const hideNavigation = location.pathname.includes('slides');
  const showTopNav = location.pathname.split('/').length > 2 && !isWeb;
  const [navigationInfo, setNavigationInfo] = useState({
    currentTab: 0,
    showTopNav,
  });
  const [title, setTitle] = useState('');

  if (!profile.token) {
    return <Login />
  }

  return (
    <NavigationInfoContext.Provider value={{navigationInfo, setNavigationInfo}}>
      <View style={tw('md:flex-row w-full h-full bg-gainsboro dark:bg-navy')}>
        {!hideNavigation && <NavBar style={tw('hidden md:flex')} />}
        <TitleContext.Provider value={{title, setTitle}}>
          <Screen />
        </TitleContext.Provider>
        {!hideNavigation && <NavBar style={tw('flex sticky bottom-0 md:hidden')} />}
      </View>
    </NavigationInfoContext.Provider>
  );
}
