import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {Heading, Container, Button, Text, FocusedContent, ScrollView, Loader, useModalControl, Modal} from '~/components/core';
import {useAdministrative, useForm, useNotify} from '~/hooks';
import {Input, Label} from '~/components/form';
import {required} from '~/util/rules';
import {useCompanionApi} from '~/api/companion';
import {CrudAction, primaryFormTheme} from '~/types';
import {Workbook} from '~/api/companion/models/response';
import {Editor} from '~/components/form';
import {Descendant} from 'slate';
import {EDITOR_SEED} from '~/components/form/Editor/types';
import {WorkbookA4} from './WorkbookA4';

interface UpdateWorkbook {
	title: string;
	description: string;
  body: Descendant[];
  published: Descendant[];
  version: string;
}

interface WorkbookDetailProps {
	action?: CrudAction;
}

export function WorkbookDetail(props: WorkbookDetailProps) {
  const {api} = useCompanionApi();
  const administrative = useAdministrative();
  const tw = useTailwind();
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, setValue, getValues} = useForm<UpdateWorkbook>({
    defaultValues: {
    },
  });
  const {pushNotifyPopup} = useNotify();
	const navigate = useNavigate();
  const workbookId = +location.pathname.split('workbooks/').pop()!;
  const goBack = () => navigate(-1);
  const [workbook, setWorkbook] = useState<Workbook>();
  const [initialBodyValue, setInitialBodyValue] = useState<Descendant[]>([]);
  const publishModalControl = useModalControl();

  useEffect(() => {
    if (!administrative) {
      navigate('/');
      return;
    }
    if (props.action === 'Update' || props.action === 'Delete') {
      (async () => {
        api.workbooks.get(workbookId)
          .then((workbook) => {
            setWorkbook(workbook);
            setValue('title', workbook.title);
            setValue('description', workbook.description);
            setValue('body', workbook.body);
            setInitialBodyValue(workbook.body.length > 0 ? workbook.body : EDITOR_SEED);
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false));
      })();
    }
  }, []);

  const studyAction = (action: () => Promise<Workbook | boolean>) => action()
    .then((series) => {
      pushNotifyPopup({
        message: `Workbook study ${props.action?.toLowerCase()}d successfully.`,
        type: 'success',
      });
			goBack();
    })
    .catch(err => {
      console.error(err);
      pushNotifyPopup({
        message: `Failed to ${props.action?.toLowerCase()} Workbook study.`,
        type: 'error',
      })
    })
    .finally(() => setLoading(false));

  const onSubmit = handleSubmit(
    (data) => {
      setLoading(true);
      switch (props.action) {
        case 'Create': return studyAction(() => api.workbooks.create(data));
				case 'Update': return studyAction(() => api.workbooks.update(workbookId, data));
        case 'Delete': return studyAction(() => api.workbooks.delete(workbookId))
        default:
          break;
      }
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const title = register('title', {required});
  const description = register('description', {required});
  const body = register('body', {required});

	// todo: debounce autosave
	const handleEditorChange = (value: Descendant[]) => {
    body.setValue(value);
	}

  const publish = () => publishModalControl.setModalOpen(true);

  return (
    <ScrollView>
      <Modal control={publishModalControl}>
        {/*  */}
      </Modal>
      <FocusedContent>
        {loading ? <Loader /> : (
          <Container>
            {props.action === 'Delete' ? (
              workbook ? (
                <>
                  <Heading>Delete Workbook '{workbook.title}'</Heading>
                  <Text style={tw('my-6')}>This action cannot be undone! Are you sure?</Text>
                  <View style={tw('flex-row justify-between items-center')}>
                    <Button type="danger" onPress={onSubmit}>
                      Delete
                    </Button>
                    <Button type="outline" onPress={goBack}>
                      Cancel
                    </Button>
                  </View>
                </>
              ) : <Loader />
            ) : (
              <>
                <Heading>{props.action ?? 'Update'} Workbook</Heading>
                {props.action === 'Update' && workbook?.title && (
                  <View>
                    <Heading size="lg" style={tw('mb-6')}>
                      {workbook.title}
                    </Heading>
                  </View>
                )}
                <Input
                  {...title}
                  label="Title"
                  placeholder="Title"
                />
                <View style={tw('mt-1 mb-4')}>
                  <Text>
                    Version: {workbook?.version}
                  </Text>
                </View>
                <Input
                  {...description}
                  label="Description"
                  placeholder="Description"
                  multiline
                  numberOfLines={6}
                />
                <Label>Content</Label>
                {initialBodyValue.length > 0 && <Editor
                  theme={primaryFormTheme}
                  initialValue={initialBodyValue}
                  onValueChange={handleEditorChange}
                />}
                <View style={tw('flex-row justify-between items-center mb-4')}>
                  <Button type="solid" onPress={() => navigate(`/classroom/workbooks/${workbookId}/slides`)}>
                    View presentation
                  </Button>
                  {workbook && <WorkbookA4 {...workbook} />}
                </View>
                <View style={tw('flex-row justify-between items-center')}>
                  <Button type="solid" onPress={onSubmit}>
                    Save
                  </Button>
                  <Button type="outline" onPress={goBack}>
                    Discard
                  </Button>
                </View>
                <View style={tw('mt-4')}>
                  <Button type="solid" onPress={publish} style={tw('bg-sage')}>
                    Publish
                  </Button>
                </View>
              </>
            )}
          </Container>
        )}
      </FocusedContent>
    </ScrollView>
  );
}
