import React from 'react';
import {View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

export function SkeletonBody() {
  const tw = useTailwind();

  // simulate a bunch of lines of text
  return (
    <>
      <View style={tw('bg-white w-96 h-4 mb-2 opacity-50 rounded-md')} />
      <View style={tw('bg-white w-64 h-4 mb-2 opacity-50 rounded-md')} />
      <View style={tw('bg-white w-80 h-4 mb-2 opacity-50 rounded-md')} />
      <View style={tw('bg-white w-96 h-4 mb-2 opacity-50 rounded-md')} />
      <View style={tw('bg-white w-80 h-4 opacity-50 rounded-md')} />
    </>
  )
}
