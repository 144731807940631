import React from 'react';
import {View, ViewProps, StyleSheet} from 'react-native';
import {useTailwind} from 'tailwind-rn';

export function HR(props: ViewProps) {
  const tw = useTailwind();

  return (
    <View
      style={[
        tw('border-b-gray dark:border-b-white'),
        {borderBottomWidth: StyleSheet.hairlineWidth},
      ]}
      {...props}
    />
  );
}
