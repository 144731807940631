import React, {useState, useRef, useEffect} from 'react';
import {useWindowDimensions} from 'react-native';
import {Descendant} from 'slate';

import {useCompanionApi} from '~/api/companion';
import {colors} from '~/theme';
import {CustomElement} from '~/types';
import {Blackout} from '../Blackout';
import {PresentationSlide} from '../PresentationSlide';
import {SlideView} from '../SlideView';
import {Presenter} from '../Presenter';
import {createSlidesFromNodes} from '../utilities';

type ClonedWindowMessageKey = ''
  | 'advanceSlide'
  | 'blackout'
  | 'mirrored'
  | 'timer'
  | 'time';

type ClonedWindowMessage = [ClonedWindowMessageKey, any];

export default function Slideshow() {
  const {api} = useCompanionApi();
  const container = useRef<HTMLDivElement>(null);
  const isClonedView = window.location.href.includes('cloned');
  const [presenter, setPresenter] = useState(false);
  const {width, height} = useWindowDimensions();
  const [clone, setClone] = useState<Window | null>();
  const [nodes, setNodes] = useState<Descendant[]>([]);
  const [slides, setSlides] = useState<CustomElement[][]>([]);
  const workbookId = +location.pathname.split('workbooks/').pop()!.split('/')[0];
  const [slideIndex, setSlideIndex] = useState(
    window.location.href.includes('cloned')
      ? +window.location.href.split('cloned@')[1]
      : 0
  );
  const [blackout, setBlackout] = useState(false);
  const [mirrored, setMirrored] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [timer, setTimer] = useState(false);
  const [time, setTime] = useState(0);
  const [presenterNotes, setPresenterNotes] = useState<string[]>([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    (async () => {
      Promise.all([
        api.workbooks.get(workbookId),
        api.workbooks.getNotes(workbookId),
      ])
        .then(([workbookDetail, presenterNotes]) => {
            setNodes(workbookDetail.body);
            setSlides(createSlidesFromNodes(workbookDetail.title, JSON.parse(JSON.stringify(workbookDetail.body))));
            setPresenterNotes(presenterNotes.notes)
            setTitle(workbookDetail.title);
          })
        .catch((err) => console.error(err));
    })();
  }, []);

  useEffect(() => {
    container.current!.focus();
    if (slides.length > 0 && isClonedView) {
      window.addEventListener(
        'message',
        (event: MessageEvent) => {
          const [key, value]: ClonedWindowMessage =
            (event && typeof event.data === 'string' ? (event.data?.split(':') as ClonedWindowMessage) : null) ?? ['', ''];
          switch (key) {
            case 'advanceSlide':
              advanceSlide(+value);
              break;
            case 'blackout':
              setBlackout(value === 'true');
              break;
            case 'mirrored':
              setMirrored(value === 'true');
              break;
            case 'timer':
              setTimer(value === 'true');
              break;
            case 'time':
              setTime(+value);
              break;
            default:
              break;
          }
        },
        false,
      );
    }
  }, [slides]);

  useEffect(() => {
    if (clone?.closed) setClone(null);
  }, [clone?.closed]);

  const sendCloneMessage = (key: ClonedWindowMessageKey, value: any) => clone?.postMessage(`${key}:${value}`, '*');

  const cloneWindow = () => {
    if (clone || isClonedView)
      return;
    setClone(window.open(location.href + `#cloned@${slideIndex}`, '_blank', 'location=no'));
    if (clone)
      (clone as Window).focus();
  }

  const advanceSlide = (direction: number) => {
    setSlideIndex((current) => Math.max(0, Math.min(current + direction, slides.length + 1)));
    sendCloneMessage('advanceSlide', direction);
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.metaKey || event.ctrlKey || isClonedView)
      return;

    switch (event.key) {
      case 'ArrowRight':
        advanceSlide(1);
        break;
      case 'ArrowLeft':
        advanceSlide(-1);
        break;
      case 'b':
        setBlackout(!blackout);
        sendCloneMessage('blackout', !blackout);
        break;
      case 'm':
        setMirrored(!mirrored);
        sendCloneMessage('mirrored', !mirrored);
        break;
      case 'c':
        cloneWindow();
        break;
      case 'p':
        setPresenter(!presenter);
        break;
      case 'f':
        setFullscreen(!fullscreen);
        break;
      case 's':
        setTimer(!timer);
        sendCloneMessage('timer', !timer);
        break;
      case 't':
        setTime(0);
        sendCloneMessage('time', 0);
        break;
      default:
        break;
    }

    if (!presenter) event.preventDefault();
  }

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    advanceSlide(event.deltaY > 0 ? 1 : -1);
  }

  return (
    <div
      ref={container}
      tabIndex={-1}
      onKeyDown={handleKeyPress}
      onWheel={handleScroll}
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: presenter ? colors.gray : colors.black,
        ...(mirrored && isClonedView && {transform: 'scale(-1, 1)'}),
      }}
    >
      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {presenter ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <PresentationSlide
              title={title}
              index={slideIndex}
              slides={slides}
              style={{
                marginLeft: 40,
                marginTop: 40,
                marginRight: 40,
                marginBottom: 20,
              }}
            />
            <PresentationSlide
              title={title}
              index={slideIndex+1}
              slides={slides}
              style={{
                marginLeft: 40,
                marginTop: 20,
                marginRight: 40,
                marginBottom: 40,
                opacity: 0.5,
              }}
            />
          </div>
        ) : (
          <SlideView title={title} index={slideIndex} slides={slides} width={width} height={height} />
        )}
      </div>
      {presenter && (
        <Presenter
          workbookId={workbookId}
          index={slideIndex}
          notes={presenterNotes}
        />
      )}
      {blackout && isClonedView && <Blackout />}
    </div>
  );
}
