import React from 'react';
import {Pressable} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {PersonalBibleStudySeries} from '~/api/companion/models/response';
import {Heading, Panel, Text} from '~/components/core';

export function SeriesLink(props: PersonalBibleStudySeries) {
  const navigate = useNavigate();
  const tw = useTailwind();

  return (
    <Pressable onPress={() => navigate(`/study-series/${props.slug}`)}>
      <Panel>
        <Heading size="lg" style={tw('text-highlight')}>
          {props.title.toUpperCase()}
        </Heading>
        <Text>{props.description}</Text>
      </Panel>
    </Pressable>
  );
}
