import React from 'react';
import {Controller} from 'react-hook-form';
import {ViewProps, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {Label} from '~/components/form';
import {RadioOption, UseFormRegisterReturnExtended} from '~/types';

import RadioInput from './component.native';

interface RadioGroupProps {
  options: RadioOption[];
  label?: string;
  horizontal?: boolean;
}

function _RadioGroup(
  props: UseFormRegisterReturnExtended & ViewProps & RadioGroupProps,
  ref: React.Ref<any>,
) {
  const {options, label, horizontal, name, control, rules, error, theme} = props;
  const tw = useTailwind();

  // todo: might need <Pressable /> on native.
  return (
    <View style={tw('mb-4')} ref={ref}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: {onChange, value}}) => (
          <View style={tw(horizontal ? 'flex flex-row' : '')}>
            {options.map((o, i) => (
              <View
                key={i}
                style={tw(`flex flex-row items-center max-w-full ${horizontal ? 'mr-2' : ''}`)}
              >
                <RadioInput
                  {...props}
                  ref={ref}
                  index={i}
                  value={o.value}
                  inputColor={theme?.inputColor}
                  checked={+value === o.value}
                  onChange={onChange}
                  error={!!error}
                />
                {label && (
                  <View>
                    <Label inputName={`${name}${i}`}>{o.label}</Label>
                  </View>
                )}
              </View>
            ))}
          </View>
        )}
      />
      {label && error && (
        <Text style={tw('text-primary text-danger mt-1')}>
          {label} {error.message}
        </Text>
      )}
    </View>
  );
}

export const RadioGroup = React.forwardRef(_RadioGroup);
export default RadioGroup;
