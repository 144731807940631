import React from 'react';
import {useTailwind} from 'tailwind-rn';

import {radioStyles} from './styles';
import {colors} from '~/theme';

function _RadioInput(
  props: {
    name: string;
    index: number;
    value: any;
    inputColor: string | undefined;
    checked: boolean;
    onChange: (...event: any[]) => void;
    error: boolean;
  },
  ref: React.Ref<any>,
) {
  const {name, index, value, inputColor, checked, onChange} = props;
  const tw = useTailwind();

  return (
    <input
      style={{
        ...radioStyles(checked) as React.CSSProperties,
        ...tw(checked ? '' : inputColor!),
        ...{
          appearance: 'none',
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 10,
          backgroundPosition: 'center',
          ...(props.error && {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.danger
          }),
        },
      }}
      ref={ref}
      value={value}
      checked={checked}
      onChange={onChange}
      type="radio"
      id={`${name}${index}`}
      name={name}
    />
  );
}

export const RadioInput = React.forwardRef(_RadioInput);
export default RadioInput;
