import {useContext} from 'react';

import {TitleContext} from '~/contexts';

export function useTitle() {
  const titleContext = useContext(TitleContext);
  if (!titleContext) {
    console.error(
      new Error('TitleContext must be used inside TitleContext.Provider.'),
    );
  }

  return titleContext;
}
