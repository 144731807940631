import React from 'react';
import {View} from 'react-native';

export function Blackout() {
  return (
    <View
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        zIndex: 100,
      }}
    />
  );
}
