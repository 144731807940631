import React from 'react';
import {View, Image} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import jwtDecode from 'jwt-decode';
import {API_URL} from '@env';

import {useCompanionApi, controllers as companionControllers} from '~/api/companion';
import {Button, Container} from '~/components/core';
import {Input} from '~/components/form';
import {useForm, useProfile} from '~/hooks';
import {required} from '~/util/rules';
import {Roles} from '~/types';

interface LoginForm {
  email: string;
  password: string;
}

interface Jwt {
  user_id: number;
  email_address: string;
  first_name: string;
  last_name: string;
  country_code: string;
  courses: string | string[];
  role: string | string[];
  exp: number;
  iss: string;
  aud: string;
}

export function Login() {
  const {api} = useCompanionApi();
  const tw = useTailwind();
  const {profile, setProfile} = useProfile();
  const {register, handleSubmit} = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit(
    (data) => {
      (async () => {
        api.users.login(data)
          .then((token) => {
            const user: Jwt = jwtDecode(token);
            setProfile({
              ...profile,
              token,
              id: +user.user_id,
              emailAddress: user.email_address,
              firstName: user.first_name,
              lastName: user.last_name,
              countryCode: user.country_code,
              courses: (typeof user.courses === 'string' ? [user.courses] : user.courses).map(c => +c),
              roles: (typeof user.role === 'string' ? [user.role] : user.role) as (keyof typeof Roles)[],
              pictureUri: `${API_URL}/api/${companionControllers.users}/${user.user_id}/image`
            });
          })
          .catch((err) => console.error(err));
      })();
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const email = register('email', {required});
  const password = register('password', {required});

  return (
    <View style={tw('md:flex-row w-full h-full bg-gainsboro dark:bg-navy')}>
      <Container style={tw('mx-auto w-full max-w-md')}>
        <View style={tw('h-[256]')}>
          <Image style={tw('w-full h-full')} resizeMode="contain" source={require('~/assets/images/companion-logo.png')} />
        </View>
        <View>
          <Input
            {...email}
            label="Email Address"
            placeholder="Email Address"
            autoComplete="email"
          />
          <Input
            {...password}
            label="Password"
            placeholder="Password"
            autoComplete="password"
            secureTextEntry
          />
        </View>
        <Button type="solid" full onPress={onSubmit}>
          Submit
        </Button>
      </Container>
    </View>
  );
}
