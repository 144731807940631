import AsyncStorage from '@react-native-async-storage/async-storage';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {version} from '~/manifest';
import {GlobalStore, globalStoreDefaults} from '~/store';

type IsTuple<T extends ReadonlyArray<any>> = number extends T['length']
  ? false
  : true;
type ArrayKey = number;
type TupleKeys<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>;
type PathImpl<K extends string | number, V> = V extends object
  ? `${K}`
  : `${K}` | `${K}.${Path<V>}`;
type Path<T> = T extends ReadonlyArray<infer V>
  ? IsTuple<T> extends true
    ? {
        [K in TupleKeys<T>]-?: PathImpl<K & string, T[K]>;
      }[TupleKeys<T>]
    : PathImpl<ArrayKey, V>
  : {
      [K in keyof T]-?: PathImpl<K & string, T[K]>;
    }[keyof T];

// slight typescript hack to enable version comparison below
// without losing type-safety on stored objects
type StoreValues = Record<string, any>;

interface Cached extends StoreValues {
  version?: string;
}

async function getExisting<T>(key: string): Promise<T | null> {
  try {
    const existing = await AsyncStorage.getItem(key);
    if (existing) {
      const parsed: Cached = JSON.parse(existing);
      if (parsed.version && parsed.version >= version) {
        return parsed as T;
      }
    }
  } catch (err) {
    console.error(err);
  }
  return null; // doesn't exist or we invalidate due to version mismatch
}

async function updateValue<T>(key: string, value: T) {
  try {
    await AsyncStorage.setItem(key, JSON.stringify({version, ...value}));
  } catch (err) {
    console.error(err);
  }
}

type UseStore = <TStoreName extends Path<GlobalStore> = Path<GlobalStore>>(
  key: TStoreName,
  defaultValue?: Partial<GlobalStore[typeof key]>,
) => [
  GlobalStore[typeof key],
  Dispatch<SetStateAction<GlobalStore[typeof key]>>,
];

export const useStore: UseStore = (key, defaultValue) => {
  const [value, setValue] = useState<GlobalStore[typeof key]>(
    defaultValue
      ? {...globalStoreDefaults[key], ...defaultValue}
      : globalStoreDefaults[key]
  );

  // run only when 'key' changes
  useEffect(() => {
    (async () => {
      const existing = await getExisting<GlobalStore[typeof key]>(key);
      if (existing) setValue(existing);
    })();
  }, [key]);

  // on update
  useEffect(() => {
    updateValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
