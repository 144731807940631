import React, {useEffect, useState} from 'react';
import {Pressable, TouchableOpacity, View} from 'react-native';
import {useLocation, useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {FlatList, FocusedContent, Heading, Kebab, Modal, Panel, Text, useModalControl} from '~/components/core';
import {SkeletonPanel} from '~/components/skeleton';
import {useAdministrative, useProfile, useTitle} from '~/hooks';
import {useCompanionApi} from '~/api/companion';
import {Country, PersonalBibleStudy, StudySeries, StudySeriesDetail} from '~/api/companion/models/response';
import {colors} from '~/theme';
import {PlusIcon} from '~/assets/icons';
import {I18nDataContext} from '~/contexts';
import {StudySeriesForm} from '~/forms';
import {secondaryFormTheme} from '~/types';

function SeriesDetail(props: PersonalBibleStudy) {
  const navigate = useNavigate();

  return (
    <Pressable onPress={() => navigate(props.slug)}>
      <Panel>
        <Heading size="lg">{props.title}</Heading>
        <Text>{props.description}</Text>
      </Panel>
    </Pressable>
  );
}

export function Series() {
  const {api} = useCompanionApi();
  const [seriesDetail, setSeriesDetail] = useState<StudySeriesDetail>();
  const location = useLocation();
  const slug = location.pathname.split('study-series/').pop()!;
  const titleContext = useTitle();
  const tw = useTailwind();
  const navigate = useNavigate();
  const {profile} = useProfile();
  const administrative = useAdministrative();
  const [countries, setCountries] = useState<Country[]>([]);
  const editSeriesModalControl = useModalControl();
  const [editStudySeries, setEditStudySeries] = useState<StudySeries>();
  const handleEditCourse = (series: StudySeries) => {
    setEditStudySeries(series);
    editSeriesModalControl.setModalOpen(true);
  }

  const onSubmit = () => {
    // ..
  }

  useEffect(() => titleContext.setTitle('Series'), []);
  useEffect(() => {
    (async () => {
      Promise.all([
        api.i18n.countries(),
        api.studySeries.series(slug),
      ])
        .then(([countries, detail]) => {
            setCountries(countries);
            setSeriesDetail(detail);
          })
        .catch((err) => console.error(err));
    })();
  }, []);

  return (
    <>
      <I18nDataContext.Provider value={{countries}}>
        <Modal control={editSeriesModalControl}>
          <StudySeriesForm
            action="Update"
            theme={secondaryFormTheme}
            onReload={onSubmit}
            series={editStudySeries}
            onClose={() => editSeriesModalControl.setModalOpen(false)}
          />
        </Modal>
      </I18nDataContext.Provider>
      <FocusedContent>
        <FlatList
          showsVerticalScrollIndicator={false}
          keyExtractor={(_, index) => 'key' + index}
          ListHeaderComponent={(
            <>
              <View style={tw('flex-row justify-between items-center')}>
                <Heading>{seriesDetail ? seriesDetail.series.title : 'Loading...'}</Heading>
                {seriesDetail && administrative && <Kebab onPress={() => handleEditCourse(seriesDetail.series)} direction="horizontal" />}
              </View>
              <View style={tw('flex-row justify-between items-center')}>
                <Heading size="lg">Bible Studies</Heading>
                {administrative && (
                  <TouchableOpacity onPress={() => navigate(`${location.pathname}/add-bible-study`)}>
                    <PlusIcon width={25} height={25} stroke={profile.darkMode ? colors['dark-highlight'] : colors.highlight} />
                  </TouchableOpacity>
                )}
              </View>
            </>
          )}
          data={seriesDetail?.studies ?? new Array(6).fill(undefined)}
          renderItem={({item}) => item ? <SeriesDetail {...item} /> : <SkeletonPanel />}
        />
      </FocusedContent>
    </>
  );
}
