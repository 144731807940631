import React from 'react';
import {FlatList as NativeFlatList, FlatListProps} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
// import {useOutletContext} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

export function FlatList<T>({style, ...props}: FlatListProps<T>) {
  // const [topNav] = useOutletContext<[boolean, Function]>();
  const insets = useSafeAreaInsets();
  const tw = useTailwind();

  const applyStickyFix = props.stickyHeaderHiddenOnScroll;

  return (
    <NativeFlatList
      style={[applyStickyFix && {marginTop: -insets.top}, style]}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={tw('p-4')}
      ListHeaderComponentStyle={[
        tw('bg-gainsboro dark:bg-navy'),
        applyStickyFix && {paddingTop: insets.top},
      ]}
      {...props}
    />
  );
}
