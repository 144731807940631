import React from 'react';
import {ScrollView as NativeScrollView, ScrollViewProps} from 'react-native';

export function ScrollView(props: ScrollViewProps) {
  return (
    <NativeScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      {...props}
    />
  );
}
