import React, {useState} from 'react';
import {View, ViewProps, Modal as NativeModal, Pressable} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Panel} from '~/components/core';
import {useBreakpoint} from '~/hooks';
import {widths} from '~/theme';

interface ModalControl {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useModalControl(): ModalControl {
  const [modalOpen, setModalOpen] = useState(false);

  return {modalOpen, setModalOpen};
}

type ModalWidth = keyof typeof widths;

export function Modal({
  style,
  ...props
}: ViewProps & {control: ModalControl; onRequestClose?: () => void, width?: ModalWidth}) {
  const tw = useTailwind();
  const {breakMd} = useBreakpoint();

  return (
    <NativeModal
      animationType="fade"
      transparent
      visible={props.control.modalOpen}
      onRequestClose={props.onRequestClose}
    >
      <View
        style={[tw('relative flex-1 justify-center items-center'), {backgroundColor: 'rgba(0,0,0,0.5)'}]}
      >
        <Pressable style={tw('absolute w-full h-full')} onPress={() => props.control.setModalOpen(false)} />
        <View pointerEvents="box-none" style={[tw('w-full p-4'), {maxWidth: widths[props.width ?? 'stretch']}]}>
          <Panel>
            {props.children}
          </Panel>
        </View>
      </View>
    </NativeModal>
  );
}
