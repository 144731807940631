import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {Pressable, TextInput, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';
import {useClickOutside} from 'react-native-click-outside';

import {CrossIcon} from '~/assets/icons';
import {Text} from '~/components/core';
import {Label} from '~/components/form';
import {useArray, useProfile} from '~/hooks';
import {colors} from '~/theme';
import {ComboboxOption, UseFormRegisterReturnExtended} from '~/types';

interface ComboboxProps {
  options: ComboboxOption[];
  defaultSelectedOptions?: ComboboxOption[];
  onChangeOptions: (value: ComboboxOption[]) => void;
  label?: string;
  placeholder?: string;
  noMargin?: boolean;
  loading?: boolean;
}

function _Combobox(
  props: UseFormRegisterReturnExtended & ComboboxProps,
  ref: React.Ref<any>,
) {
  const {options, defaultSelectedOptions, onChangeOptions, label, placeholder, noMargin, name, control, rules, error, theme, loading} = props;
  const {
    array: selectedOptions,
    push,
    removeAtIndex,
  } = useArray<ComboboxOption>(defaultSelectedOptions ?? []);
  const {profile} = useProfile();
  const tw = useTailwind();
  const [showSelectedOptions, setShowSelectedOptions] = useState(false);
  const optionsRef = useClickOutside<View>(() => setShowSelectedOptions(false));
  const [search, setSearch] = useState('');

  useEffect(() => {
    onChangeOptions(selectedOptions);
    setSearch('');
  }, [selectedOptions]);

  return (
    <View style={[!noMargin && tw('mb-4'), tw('flex-initial z-10')]} ref={ref}>
      {label && <Label>{props.label}</Label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: {onChange, onBlur}}) => (
          <View style={tw('relative')}>
            <View
              style={[
                tw(
                  `${theme!.inputColor} rounded-md p-2
                ${error ? 'border border-danger' : ''}`,
                ),
                {textAlignVertical: 'top'},
                props.disabled && tw('text-inactive'),
              ]}
            >
              <View style={tw('flex-row flex-wrap')}>
                {selectedOptions.map((so, i) => (
                  <View style={tw('bg-stone rounded-md p-1 mr-2 mb-2 inline-flex')} key={i}>
                    <Pressable
                      style={tw('flex-row')}
                      onPress={() => {
                        removeAtIndex(i);
                      }}
                    >
                      <Text>{so.label}</Text>
                      <CrossIcon style={tw('ml-1')} width={15} />
                    </Pressable>
                  </View>
                ))}
              </View>
              <TextInput
                style={tw(theme!.inputColor)}
                placeholder={placeholder}
                placeholderTextColor={
                  profile.darkMode ? colors.gainsboro : colors.secondary
                }
                onBlur={onBlur}
                onChangeText={(text) => {
                  setSearch(text);
                  onChange(text);
                }}
                value={search}
                onChange={() => setShowSelectedOptions(true)}
              />
            </View>
            {showSelectedOptions && (
              <View ref={optionsRef} style={tw(`absolute top-full ${theme!.inputColor} rounded-md p-2 mt-2 w-full`)}>
                {(options.length > 0) 
                  ? options.map((o, i) => (
                    <Pressable
                      key={i}
                      onPress={() => {
                        if (!selectedOptions.find(s => s.value === o.value)) push(o);
                        setShowSelectedOptions(false);
                      }}
                    >
                      <View style={tw('py-2')}>
                        <Text>{o.label}</Text>
                      </View>
                    </Pressable>
                  )) : (
                    <Text>{loading ? 'Loading...' : 'No results'}</Text>
                  )}
              </View>
            )}
          </View>
        )}
      />
      {error && (
        <Text style={tw('text-primary text-danger mt-1')}>
          {label} {error.message}
        </Text>
      )}
    </View>
  );
}

export const Combobox = React.forwardRef(_Combobox);
export default Combobox;
