import React from 'react';
import {pdf} from '@react-pdf/renderer';
import {saveAs} from 'file-saver';

export function usePdf(filename: string, Pdf: any) {
	const downloadPdf = async() => {
		const blob = await pdf(Pdf).toBlob();
		saveAs(blob, `${filename}.pdf`);
	}

	return {downloadPdf};
}
