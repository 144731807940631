import {useContext} from 'react';

import {ProfileContext} from '~/contexts';

export function useProfile() {
  const profileContext = useContext(ProfileContext);
  if (!profileContext) {
    console.error(
      new Error('ProfileContext must be used inside ProfileContext.Provider.'),
    );
  }

  return profileContext;
}
