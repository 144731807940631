import React from 'react';

import {Text} from '~/components/core';

export function Feed() {
  return (
    <>
      {/*todo*/}
      <Text>No updates available.</Text>
    </>
  );
};
