import React from 'react';
import {useTailwind} from 'tailwind-rn';

import {checkboxStyles} from './styles';

function CheckboxInput(props: {
  name: string;
  inputColor: string | undefined;
  checked: boolean;
  onChange: (...event: any[]) => void;
}) {
  const {name, inputColor, checked, onChange} = props;
  const tw = useTailwind();

  return (
    <input
      style={{
        ...checkboxStyles(checked) as React.CSSProperties,
        ...tw(checked ? '' : inputColor!),
        ...{
          appearance: 'none',
          WebkitAppearance: 'none',
          MozAppearance: 'none',
        },
      }}
      onChange={onChange}
      type="checkbox"
      id={name}
    />
  );
}

export default CheckboxInput;
