import React, {useEffect, useState} from 'react';
import {Pressable, Switch, View, ViewProps, StyleSheet} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {useCompanionApi} from '~/api/companion';
import {Country, Language} from '~/api/companion/models/response';
import {
  Button,
  Container,
  Heading,
  ScrollView,
  PersonImage,
  Modal,
  useModalControl,
  FocusedContent,
} from '~/components/core';
import {Chevron} from '~/components/core/Chevron';
import {Picker} from '~/components/form';
import {ChangePasswordForm, UpdatePictureForm} from '~/forms';
import {useProfile, useForm} from '~/hooks';
import {widths} from '~/theme';
import {secondaryFormTheme} from '~/types';
import {required} from '~/util/rules';

function SettingOption(props: ViewProps & {title: string, danger?: boolean}) {
  const tw = useTailwind();

  return (
    <View
      style={[
        tw('flex-row justify-between items-center py-4 border-t-gray dark:border-t-secondary'),
        {borderTopWidth: StyleSheet.hairlineWidth},
      ]}
      {...props}
    >
      <Heading
        size="base"
        weight="light"
        style={tw(`${props.danger ? 'text-danger' : ''} mr-4`)}
      >
        {props.title}
      </Heading>
      {props.children}
    </View>
  )
}

function SettingGroup(props: ViewProps & {title: string, danger?: boolean}) {
  const tw = useTailwind();

  return (
    <View style={tw('mb-6')} {...props}>
      <Heading
        size="lg"
        weight="light"
        style={tw(props.danger ? 'text-danger' : '')}
      >
        {props.title}
      </Heading>
      {props.children}
    </View>
  )
}

interface SettingsConfigurationForm {
  country: string;
  language: string;
}

export function Settings() {
  const {api} = useCompanionApi();
  const navigate = useNavigate();
  const {profile, setProfile} = useProfile();
  const tw = useTailwind();
  const toggleTheme = () =>
    setProfile({...profile, darkMode: !profile.darkMode});
  const [countries, setCountries] = useState<Country[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  useEffect(() => {
    (async () => {
      Promise.all([
        api.i18n.countries(),
        api.i18n.languages(),
      ])
        .then(([countries, languages]) => {
          setCountries(countries);
          setLanguages(languages);
        })
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    })();
  }, []);

  const {register, handleSubmit} = useForm<SettingsConfigurationForm>({
    defaultValues: {
      country: profile.countryCode,
      language: profile.language,
    },
  });

  const onSubmit = handleSubmit(
    (data, e) => {
      
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const updatePictureModalControl = useModalControl();
  const changePasswordModalControl = useModalControl();
  const country = register('country', {required});
  const language = register('language', {required});

  const logout = () => {
    setProfile({...profile, token: null});
    navigate('/');
  }

  return (
    <ScrollView>
      <Modal control={updatePictureModalControl}>
        <UpdatePictureForm
          theme={secondaryFormTheme}
          onClose={() => updatePictureModalControl.setModalOpen(false)}
        />
      </Modal>
      <Modal control={changePasswordModalControl} width="focus">
        <ChangePasswordForm
          theme={secondaryFormTheme}
          onClose={() => changePasswordModalControl.setModalOpen(false)}
        />
      </Modal>
      <FocusedContent>
        <Container>
          <Heading>Settings</Heading>
          <SettingGroup title="My profile">
            <SettingOption title="Dark theme">
              <Switch onValueChange={toggleTheme} value={profile.darkMode} />
            </SettingOption>
            <SettingOption title="Profile picture">
              <Pressable onPress={() => updatePictureModalControl.setModalOpen(true)}>
                <PersonImage size="xs" uri={profile.pictureUri} />
              </Pressable>
            </SettingOption>
            <SettingOption title="Country">
              <Picker
                {...country}
                options={countries.map(c => ({
                  value: c.code, label: `${c.code} ${c.unicode}`
                }))}
                noMargin
              />
            </SettingOption>
            <SettingOption title="Language">
              <Picker
                {...language}
                options={languages.map(l => ({
                  value: l.code, label: l.name
                }))}
                noMargin
              />
            </SettingOption>
            <Pressable onPress={() => changePasswordModalControl.setModalOpen(true)}>
              <SettingOption title="Change password">
                <Chevron direction="right" />
              </SettingOption>
            </Pressable>
            <Pressable onPress={() => logout()}>
              <SettingOption title="Sign out">
                <Chevron direction="right" />
              </SettingOption>
            </Pressable>
          </SettingGroup>

          <SettingGroup title="Privacy">
            <Pressable onPress={() => {}}>
              <SettingOption title="Cookie options">
                <Chevron direction="right" />
              </SettingOption>
            </Pressable>
            <SettingOption title="Data compliance">
              <Chevron direction="right" />
            </SettingOption>
          </SettingGroup>

          <SettingGroup title="Archives">
            <Pressable onPress={() => {}}>
              <SettingOption title="Previous content">
                <Chevron direction="right" />
              </SettingOption>
            </Pressable>
          </SettingGroup>

          <SettingGroup danger title="Danger zone">
            <SettingOption danger title="Delete my account">
              <Button
                type="danger"
                onPress={() => {}}
              >
                Delete
              </Button>
            </SettingOption>
          </SettingGroup>
        </Container>
      </FocusedContent>
    </ScrollView>
  );
}
