import React from 'react';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {
  Container,
  FocusedContent,
  Heading,
  ScrollView,
} from '~/components/core';

export function Contacts() {
  const navigate = useNavigate();
  const tw = useTailwind();

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <Heading>Contacts</Heading>
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
