import React from 'react';
import {View, ViewProps} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {useProfile} from '~/hooks';
import {colors} from '~/theme';

export function MenuItem({
  style,
  ...props
}: ViewProps & {Icon?: React.FC<SvgProps>; text: string}) {
  const {profile} = useProfile();
  const tw = useTailwind();

  return (
    <View
      style={[
        tw(
          'flex-row items-center h-14 py-2 px-4 bg-white dark:bg-stone border-b border-b-secondary',
        ),
        style,
      ]}>
      {props.Icon && (
        <View>
          {props.Icon && (
            <props.Icon
              stroke={profile.darkMode ? colors.white : colors.black}
              width={28}
              height={28}
            />
          )}
        </View>
      )}
      <Text style={tw('px-4 grow')}>{props.text}</Text>
      <View>{props.children}</View>
    </View>
  );
}
