import {
  Control,
  FieldError,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import {SvgProps} from 'react-native-svg';
import {BaseEditor} from 'slate'
import {ReactEditor} from 'slate-react'
import {HistoryEditor} from 'slate-history'

export interface NavigationInfoType {
  currentTab: number;
  showTopNav: boolean;
}

export interface Tab {
  to: string;
  label: string;
  Icon: React.FC<SvgProps>;
  roles?: (keyof typeof Roles)[];
}

export interface NavMenuItemProps extends Tab {
  index: number;
}

export type SetFormInputValueOptions = Partial<{
  shouldValidate: boolean;
  shouldDirty: boolean;
  shouldTouch: boolean;
}> | undefined;

export interface UseFormRegisterReturnExtended extends UseFormRegisterReturn {
  control: Control<any, any>;
  rules?: RegisterOptions<any, any>;
  error: FieldError;
  theme?: FormTheme;
  setValue: (value: any, options?: SetFormInputValueOptions) => void;
}

export interface PickerOption {
  value: number | string;
  label: string;
}

export interface RadioOption {
  value: number | string;
  label: string;
}

export interface ComboboxOption {
  value: number | string;
  label: string;
}

export type FontWeight =
  | 'thin'
  | 'thin-italic'
  | 'extralight'
  | 'extralight-italic'
  | 'light'
  | 'light-italic'
  | 'normal'
  | 'italic'
  | 'medium'
  | 'medium-italic'
  | 'semibold'
  | 'semibold-italic'
  | 'bold'
  | 'bold-italic'
  | 'extrabold'
  | 'extrabold-italic'
  | 'black'
  | 'black-italic';

export interface TabViewScene {
  key: string;
  title: string;
  component: React.ReactNode; 
}

export type CrudAction =
  | 'Create' // or Add
  | 'Read' // or Load
  | 'Update'
  | 'Delete';

export interface FormTheme {
  inputColor: string;
}

export const primaryFormTheme: FormTheme = {
  inputColor: 'bg-white text-primary dark:bg-stone dark:text-dark-primary',
}

export const secondaryFormTheme: FormTheme = {
  inputColor: 'bg-gainsboro text-primary dark:bg-navy dark:text-dark-primary',
}

export const lightThemeOnly: FormTheme = {
  inputColor: 'bg-white text-primary',
}

export interface MdtpContentFormProps {
  action?: CrudAction;
  theme: FormTheme;
  onClose: () => void;
}

export enum NotifyBackgroundColor {
  'neutral' = 'bg-highlight',
  'success' = 'bg-sage',
  'warning' = 'bg-warning',
  'error' = 'bg-danger',
}

export interface NotifyPopupInfo {
  message: string;
  type?: keyof typeof NotifyBackgroundColor;
  duration?: number;
}

export enum Roles {
  Administrator = 1,
	Author = 2,
	Instructor = 3,
	Student = 4
}

// get list of role names from above enum
export const RoleNames = (Object.keys(Roles)).filter(v => !(+v)) as Array<keyof typeof Roles>;

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type Alignment =
  | 'left'
  | 'center'
  | 'right'
  | 'justify';

interface BaseElement {
  type: string;
  align?: Alignment;
  children: CustomText[];
}

export interface ParagraphElement extends BaseElement {
  type: 'paragraph';
}

export interface HeadingElement extends BaseElement {
  type: 'heading';
  level: number;
}

export interface BlockquoteElement extends BaseElement {
  type: 'block-quote';
}

export interface BulletedListElement extends BaseElement {
  type: 'bulleted-list';
}

export interface HeadingOneElement extends BaseElement {
  type: 'heading-one';
}

export interface HeadingTwoElement extends BaseElement {
  type: 'heading-two';
}

export interface ListItemElement extends BaseElement {
  type: 'list-item';
}

export interface NumberedListElement extends BaseElement {
  type: 'numbered-list';
}

export interface TextboxElement extends BaseElement {
  id: string;
  type: 'textbox';
}

export interface ListItemText extends BaseElement {
  type: 'list-item-text';
}

export interface SectionTitleElement extends BaseElement {
  type: 'section-title';
}

export interface ImageElement extends BaseElement {
  type: 'image';
  url: string;
  background?: boolean;
}

export type CustomElement = 
  | ParagraphElement
  | HeadingElement
  | BlockquoteElement
  | BulletedListElement
  | HeadingOneElement
  | HeadingTwoElement
  | ListItemElement
  | NumberedListElement
  | TextboxElement
  | ListItemText
  | SectionTitleElement
  | ImageElement;

export type FormattedText = {
  id?: string;
  text: string;
  bold?: true;
	italic?: true;
	underline?: true;
	'heading-one'?: true;
	'heading-two'?: true;
	'block-quote'?: true;
	'numbered-list'?: true;
	'bulleted-list'?: true;
  textbox?: true;
	left?: true;
	center?: true;
	right?: true;
	justify?: true;
  input?: true;
  'section-title'?: true;
  'workbook-context'?: true;
  'slide-context'?: true;
  correct?: true;
  value?: string;
}

export type CustomText = FormattedText

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
