import {createContext} from 'react';

import {ProfileStore} from '~/store';

interface ProfileContextType {
  profile: ProfileStore;
  setProfile: (value: ProfileStore) => void;
}

export const ProfileContext = createContext<ProfileContextType>(undefined!);
