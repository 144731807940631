import ArrowLeftIcon from './arrow-left.svg';
import ArrowRightIcon from './arrow-right.svg';
import BookIcon from './book.svg';
import CheckIcon from './check.svg';
import ChevronLeftIcon from './chevron-right.svg';
import ChevronRightIcon from './chevron-right.svg';
import ClassroomIcon from './classroom.svg';
import ColorFilter from './classroom.svg';
import CookieIcon from './cookie.svg';
import DotsVerticalIcon from './dots-vertical.svg';
import DotsHorizontalIcon from './dots-horizontal.svg';
import HomeIcon from './home.svg';
import LanguageIcon from './language.svg';
import LogoIcon from './logo.svg';
import MenuIcon from './menu.svg';
import PaperIcon from './paper.svg';
import PlusIcon from './plus.svg';
import SignOutIcon from './sign-out.svg';
import ThemeIcon from './theme.svg';
import UserIcon from './user.svg';
import AdminIcon from './admin.svg';
import LinkIcon from './link.svg';
import UploadIcon from './upload.svg';
import ShareIcon from './share.svg';
import PeopleIcon from './people.svg';
import CameraAddIcon from './camera-add.svg';
import TrashIcon from './trash.svg';
import CrossIcon from './cross.svg';
import ContentIcon from './content.svg';
import BoldIcon from './bold.svg';
import ItalicIcon from './italic.svg';
import UnderlineIcon from './underline.svg';
import HeadingOneIcon from './heading-one.svg';
import HeadingTwoIcon from './heading-two.svg';
import BlockQuoteIcon from './block-quote.svg';
import NumberedListIcon from './numbered-list.svg';
import BulletedListIcon from './bulleted-list.svg';
import LeftAlignIcon from './left-align.svg';
import CenterAlignIcon from './center-align.svg';
import RightAlignIcon from './right-align.svg';
import JustifyAlignIcon from './justify-align.svg';
import InputIcon from './input.svg';
import TextboxIcon from './textbox.svg';
import SectionTitleIcon from './section-title.svg';
import WorkbookContextIcon from './workbook-context.svg';
import SlideContextIcon from './slide-context.svg';

export {
  ArrowLeftIcon,
  ArrowRightIcon,
  BookIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClassroomIcon,
  ColorFilter,
  CookieIcon,
  DotsVerticalIcon,
  DotsHorizontalIcon,
  HomeIcon,
  LanguageIcon,
  LogoIcon,
  MenuIcon,
  PaperIcon,
  PlusIcon,
  SignOutIcon,
  ThemeIcon,
  UserIcon,
  AdminIcon,
  LinkIcon,
  UploadIcon,
  ShareIcon,
  PeopleIcon,
  CameraAddIcon,
  TrashIcon,
  CrossIcon,
  ContentIcon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  HeadingOneIcon,
  HeadingTwoIcon,
  BlockQuoteIcon,
  NumberedListIcon,
  BulletedListIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  JustifyAlignIcon,
  InputIcon,
  TextboxIcon,
  SectionTitleIcon,
  WorkbookContextIcon,
  SlideContextIcon,
};
