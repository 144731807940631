import React from 'react';
import {
  ImageBackground,
  ImageSourcePropType,
  Pressable,
  View,
  ViewProps,
} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {Heading, Panel} from '~/components/core';
import {useBreakpoint} from '~/hooks';

export function Stat(
  props: ViewProps & {
    name: string;
    actions: React.ReactNode;
    count: number;
    image: ImageSourcePropType;
  },
) {
  const navigate = useNavigate();
  const tw = useTailwind();
  const {breakMd} = useBreakpoint();

  const imageStyles = {width: 128};

  // todo: use SectionList for Home content.
  return (
    <Pressable onPress={() => navigate(`/stats/${props.name.toLowerCase()}`)}>
      <Panel>
        {breakMd && (
          <View style={tw('h-[256] -mx-4 -mt-4')}>
            <ImageBackground
              source={props.image}
              resizeMode="cover"
              style={tw('flex-1 overflow-hidden rounded-t-2xl h-full w-full')}
            />
          </View>
        )}
        <View style={tw(`flex-row ${breakMd ? 'pt-4' : ''}`)}>
          <View style={tw(`flex-1 ${breakMd ? '' : 'mr-4'}`)}>
            <Heading
              size="lg"
              weight="semibold"
              style={tw('text-secondary dark:text-dark-primary')}>
              {props.name}
            </Heading>
            <Heading
              size="lg"
              weight="semibold"
              style={tw('text-primary dark:text-white')}>
              {props.count} this week
            </Heading>
            <View style={tw('flex-row')}>
              {props.actions}
            </View>
          </View>
          {!breakMd && (
            <View style={[imageStyles]}>
              <ImageBackground
                source={props.image}
                resizeMode="cover"
                style={tw('flex-1 overflow-hidden rounded-2xl h-full w-full')}
              />
            </View>
          )}
        </View>
      </Panel>
    </Pressable>
  );
}
