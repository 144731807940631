import React, {useState} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Heading, Container, Button, Text} from '~/components/core';
import {useForm, useNotify} from '~/hooks';
import {Input} from '~/components/form';
import {required} from '~/util/rules';
import {useCompanionApi} from '~/api/companion';
import {MdtpContentFormProps} from '~/types';
import {StudySeries} from '~/api/companion/models/response';

interface CreateSeriesForm {
  title: string;
  description: string;
}

interface StudySeriesFormProps extends MdtpContentFormProps {
  series?: StudySeries;
  onReload: () => void;
}

export function StudySeriesForm(props: StudySeriesFormProps) {
  const {api} = useCompanionApi();
  const tw = useTailwind();
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit} = useForm<CreateSeriesForm>({
    defaultValues: {
      title: props.series?.title,
      description: props.series?.description,
    },
    theme: props.theme,
  });
  const {pushNotifyPopup} = useNotify();

  const studySeriesAction = (action: () => Promise<StudySeries | boolean>) => action()
    .then((series) => {
      props.onClose();
      pushNotifyPopup({
        message: `Study series ${props.action?.toLowerCase()}d successfully.`,
        type: 'success',
      });
      props.onReload();
    })
    .catch(err => {
      console.error(err);
      pushNotifyPopup({
        message: `Failed to ${props.action?.toLowerCase()} study series.`,
        type: 'error',
      })
    })
    .finally(() => setLoading(false));

  const onSubmit = handleSubmit(
    (data) => {
      setLoading(true);
      switch (props.action) {
        case 'Create': return studySeriesAction(() => api.studySeries.create(data));
        case 'Update': return studySeriesAction(() => api.studySeries.update(props.series!.id, data));
        case 'Delete': return studySeriesAction(() => api.studySeries.delete(props.series!.id!));
        default:
          break;
      }
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const title = register('title', {required});
  const description = register('description', {required});

  return loading ? (
    <View style={tw('py-8')}>
      <ActivityIndicator size="large" />
    </View>
  ) : (
    <Container>
      {props.action === 'Delete' ? (
        <>
          <Heading>Delete study series '{props.series?.title}'</Heading>
          <Text style={tw('my-6')}>This action cannot be undone! Are you sure?</Text>
          <View style={tw('flex-row justify-between items-center')}>
            <Button type="danger" onPress={onSubmit}>
              Delete
            </Button>
            <Button type="outline" onPress={props.onClose}>
              Cancel
            </Button>
          </View>
        </>
      ) : (
        <>
          <Heading>{props.action ?? 'Update'} study series</Heading>
          {props.series && (
            <View>
              <Heading size="lg" style={tw('mb-6')}>
                {props.series?.title}
              </Heading>
            </View>
          )}
          <Input
            {...title}
            label="Title"
            placeholder="Title"
          />
          <Input
            {...description}
            label="Description"
            placeholder="Description"
            multiline
            numberOfLines={6}
          />
          <View style={tw('flex-row justify-between items-center')}>
            <Button type="solid" onPress={onSubmit}>
              Submit
            </Button>
            <Button type="outline" onPress={props.onClose}>
              Cancel
            </Button>
          </View>
        </>
      )}
    </Container>
  );
}
