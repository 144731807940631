import React from 'react';
import {Document, Page, StyleSheet, View, Text, Image, Font} from '@react-pdf/renderer';
import {slateToHtml} from '@slate-serializers/html'
import Html from 'react-pdf-html';

import {usePdf} from '~/components/pdf';
import {Button} from '~/components/core';
import {CustomElement} from '~/types';
import {WorkbookA4Props} from './types';
import {createSlidesFromNodes} from '~/components/slides';
import {HtmlRendererProps, HtmlRenderers} from 'react-pdf-html/dist/types/render';
import { workbookHtmlConfig } from '~/util/htmlConfig';

const fontsDir = '/assets/fonts/LiberationSerif';

Font.register({ family: 'Liberation Serif', fonts: [
	{ src: fontsDir + '/LiberationSerif-Regular.ttf' },
	{ src: fontsDir + '/LiberationSerif-Italic.ttf', fontStyle: 'italic' },
	{ src: fontsDir + '/LiberationSerif-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 700 },
]});

const styles = StyleSheet.create({
	titlePage: {
		position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
		fontSize: 12,
		padding: '0 60px',
	},
  page: {
		fontFamily: 'Liberation Serif',
		padding: '0 60px',
  },
	header: {
    fontSize: 9,
		textAlign: 'center',
		paddingTop: 30
	},
	footer: {
		position: 'absolute',
    fontSize: 9,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: 'center',
	},
	content: {
		marginBottom: 60,
		padding: 0,
		fontSize: 12,
	},
});

// similar to Entity Extensions on backend
function clearInputText(node: any) {
	if (node.input == true && !node.text.includes('___')) node.text = '____________________';
	if (node.type == "textbox") (node.children as any[]).forEach(n => n.text =
		'_________________________________________________________________________\n' +
		'_________________________________________________________________________\n' +
		'_________________________________________________________________________\n' +
		'_________________________________________________________________________');
	if (node.children?.length > 0) (node.children as any[]).forEach(n => clearInputText(n));
}

function processNodes(nodes: CustomElement[]) {
	let processed = JSON.parse(JSON.stringify(nodes));
	processed.forEach((n: any) => clearInputText(n));
	return processed;
}

interface HtmlPageProps {
	index: number;
	title: string;
	nodes: CustomElement[];
}

const htmlRenderers: HtmlRenderers = {
	'section-title': (props: HtmlRendererProps) => <aside>{props.children}</aside>,
};

const htmlStyleSheet = {
	'section-title': {},
	'ul': {
		margin: '0',
	},
	'li': {
		marginLeft: '-12px',
		paddingTop: '2px',
		paddingBottom: '2px',
	},
}

function HtmlPage(props: HtmlPageProps) {
	const html = slateToHtml(processNodes(props.nodes), workbookHtmlConfig);
	return (
		<Page size="A4" style={styles.page}>
			<Text style={styles.header}>{props.title}</Text>
			<Html
				style={styles.content}
				renderers={htmlRenderers}
				stylesheet={htmlStyleSheet}
			>
				{html}
			</Html>
			{/* todo: footnotes */}
			<Text style={styles.footer}>Page {props.index + 2}</Text>
		</Page>
	);
}

function WorkbookDocument(props: WorkbookA4Props) {
	const pages = createSlidesFromNodes(props.title, JSON.parse(JSON.stringify(props.body)), true);

	return (
		<Document style={{height: '100%'}}>
			<Page size="A4" style={styles.page}>
				<View style={styles.titlePage}>
					<View>
						<Image
							source={require('~/assets/images/make-disciples-training-program.png')}
							style={{width: 300}}
						/>
						<View style={{display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
							<View style={{marginRight: 10}}>
								<Text>Name</Text>
								<Text>Class Date</Text>
							</View>
							<View>
								<Text>___________________________________</Text>
								<Text>___________________________________</Text>
							</View>
						</View>
					</View>
					<View style={{width: '100%', textAlign: 'right'}}>
						<Text>{props.title.toUpperCase()}</Text>
						<Text>www.makedisciplesprogram.com</Text>
						{/* todo: version */}
						<Text>v</Text>
					</View>
				</View>
			</Page>
			{pages.map((p, i) => (
				<HtmlPage
					key={i}
					index={i}
					title={props.title}
					nodes={p}
				/>
			))}
		</Document>
	);
}

export default function WorkbookA4(props: WorkbookA4Props) {
  const {downloadPdf} = usePdf(props.title, <WorkbookDocument {...props} />);

	return (
		<Button type="solid" onPress={downloadPdf}>
			Download A4
		</Button>
	);
};
