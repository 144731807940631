import React from 'react';
import {View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Heading, Container, Button, ScrollView, Text} from '~/components/core';
import {useForm, useNotify, useProfile} from '~/hooks';
import {useCompanionApi} from '~/api/companion';
import {MdtpContentFormProps} from '~/types';
import {Input} from '~/components/form';
import {required, passwordPattern} from '~/util/rules';

interface UpdatePictureForm {
  password: string;
	confirmPassword: string;
}

interface UpdatePictureFormProps extends MdtpContentFormProps {}

export function ChangePasswordForm(props: UpdatePictureFormProps) {
  const {register, handleSubmit, getValues} = useForm<UpdatePictureForm>({
    theme: props.theme,
		reValidateMode: 'onSubmit',
  });
  const tw = useTailwind();
  const {api} = useCompanionApi();
  const {profile} = useProfile();
	const {pushNotifyPopup} = useNotify();

  const onSubmit = handleSubmit(
    (data, e) => {
      api.users.update(profile.id, {password: data.password})
        .then(() => {
					pushNotifyPopup({
						message: 'Password changed successfully.',
						type: 'success',
					});
					props.onClose();
				})
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

	const validatePassword = (value: string) => value === getValues('password') || 'must match password';
	const password = register('password', {required});
	const confirmPassword = register('confirmPassword', {required, validate: validatePassword, pattern: passwordPattern});

  return (
    <ScrollView>
      <Container>
        <Heading>Change password</Heading>
        <View style={tw('mt-4 mb-8')}>
					<View style={tw('mb-4')}>
						<Text>Password must match the following criteria:</Text>
						<Text>- Length of 8 - 16 characters</Text>
						<Text>- Must contain at least 1 uppercase letter</Text>
						<Text>- Must contain at least 1 lowercase letter</Text>
						<Text>- Must contain at least 1 special character ($ % # * & - .)"</Text>
						<Text>- Must contain at least 1 number</Text>
					</View>
					<View style={tw('max-w-md')}>
						<Input
							{...password}
							label="New Password"
							placeholder="Password"
							autoComplete="password"
							secureTextEntry
						/>
						<Input
							{...confirmPassword}
							label="Confirm Password"
							placeholder="Confirm Password"
							autoComplete="password"
							secureTextEntry
						/>
					</View>
        </View>
        <View style={tw('flex-row justify-between items-center')}>
          <Button type="solid" onPress={onSubmit}>
            Submit
          </Button>
          <Button type="outline" onPress={props.onClose}>
            Cancel
          </Button>
        </View>
      </Container>
    </ScrollView>
  );
}
