import {useContext} from 'react';

import {NotifyContext} from '~/contexts';

export function useNotify() {
  const notifyContext = useContext(NotifyContext);
  if (!notifyContext) {
    console.error(
      new Error('NotifyContext must be used inside NotifyContext.Provider.'),
    );
  }

  return notifyContext;
}
