import React from 'react';
import {View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {useFont} from '~/hooks';

export function Info({style, ...props}: ViewProps) {
  const tw = useTailwind();
  const fontFamily = useFont('Montserrat', 'semibold');

  return (
    <View {...props} style={[tw('flex-row border border-highlight p-2 rounded-md max-w-full'), style]}>
      <Text style={[tw('text-highlight'), {fontFamily}]}>
        {props.children}
      </Text>
    </View>
  );
}
