import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import {TextInputProps, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {Label} from '~/components/form';
import {usePlatform} from '~/hooks';
import {UseFormRegisterReturnExtended} from '~/types';

import DatePickerInput from './component.native';

function _DatePicker(
  props: UseFormRegisterReturnExtended & TextInputProps & {label?: string},
  ref: React.Ref<any>,
) {
  const {label, name, control, rules, error} = props;
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const tw = useTailwind();
  const {isWeb} = usePlatform();
  const pickerStyles = tw(
    `bg-white text-primary dark:bg-stone dark:text-dark-primary rounded-md p-2 ${
      error ? 'border border-danger' : isWeb ? 'border-none' : ''
    }`,
  );

  return (
    <View style={tw('mb-4')} ref={ref}>
      {label && <Label>{props.label}</Label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field: {onChange}}) => (
          <DatePickerInput
            value={date}
            styles={pickerStyles}
            onChange={onChange}
            setDate={setDate}
          />
        )}
      />
      {error && (
        <Text style={tw('text-primary text-danger mt-1')}>
          {label} {error.message}
        </Text>
      )}
    </View>
  );
}

export const DatePicker = React.forwardRef(_DatePicker);
export default DatePicker;
