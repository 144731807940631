import React, {Children} from 'react';
import {TouchableOpacity, TouchableOpacityProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {useFont} from '~/hooks';

type ButtonType = 'solid' | 'outline' | 'text' | 'danger';

export function Button({
  style,
  ...props
}: TouchableOpacityProps & {full?: boolean; type?: ButtonType}) {
  const tw = useTailwind();

  let buttonTypeStyles: string = '';
  let textTypeStyles: string = '';
  switch (props.type) {
    case 'solid':
      buttonTypeStyles = 'bg-highlight';
      textTypeStyles = 'text-white';
    break;
    case 'outline':
      buttonTypeStyles = 'border border-highlight text-highlight';
      textTypeStyles = 'text-highlight';
    break;
    case 'text':
      buttonTypeStyles = '';
    break;
    case 'danger':
      buttonTypeStyles = 'border border-danger text-danger';
      textTypeStyles = 'text-danger';
    break;
  }

  const fontFamily = useFont('Montserrat', 'semibold');

  return (
    <TouchableOpacity
      {...props}
      style={[
        tw(
          `justify-center items-center rounded-xl p-2 ${buttonTypeStyles} ${
            props.full ? '' : 'self-start'
          }`,
        ),
        style,
      ]}>
      {Children.count(props.children) > 0 &&
      typeof Children.map(props.children, c => c)?.[0] === 'string' ? (
        <Text style={[tw(`${textTypeStyles} text-xl py-1 px-5`)]}>
          {props.children}
        </Text>
      ) : (
        props.children
      )}
    </TouchableOpacity>
  );
}
