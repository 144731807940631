import {ValidationRule} from 'react-hook-form';

export const required = 'is required';
export const min = (value: number) => `minimum value is ${value}`;
export const max = (value: number) => `maximum value is ${value}`;
export const minLength = (value: number) => `minimum length is ${value}`;
export const maxLength = (value: number) => `maximum length is ${value}`;
export const pattern = (value: RegExp) => `must match the pattern ${value}`;
export const emailPattern: ValidationRule<RegExp> | undefined = {value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, message: 'must be a valid email address'};
export const passwordPattern: ValidationRule<RegExp> | undefined = {value: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\#\$\.\%\&\*])(?=.*[a-zA-Z]).{8,16}$/, message: 'must match the password criteria'}
export const match = (name: string) => `must match ${name}`;
