import React, {useState} from 'react';
import {LayoutChangeEvent, StyleProp, View, ViewStyle} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {CustomElement} from '~/types';
import {SlideView} from './SlideView';

interface PresentationSlideProps {
  title: string;
  index: number;
  slides: CustomElement[][];
  style?: StyleProp<ViewStyle>;
}

export function PresentationSlide(props: PresentationSlideProps) {
  const tw = useTailwind();
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const onLayout = ({nativeEvent: {layout}}: LayoutChangeEvent) => {
    setContainerWidth(layout.width);
    setContainerHeight(layout.height);
  }

  return (
    <View
      onLayout={onLayout}
      style={[
        tw('justify-center items-center relative grow'),
        props.style,
      ]}
    >
      <SlideView title={props.title} index={props.index} slides={props.slides} width={containerWidth} height={containerHeight} />
    </View>
  );
}
