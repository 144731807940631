import {StyleProp, ViewStyle} from 'react-native';
import {colors} from '~/theme';

export const encodedIcon = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cpath fill='white' stroke='none' d='M7 3.34a10 10 0 1 1-4.995 8.984L2 12l.005-.324A10 10 0 0 1 7 3.34z'/%3E%3C/svg%3E")`;

export const radioStyles = (checked: boolean): StyleProp<ViewStyle> => ({
  marginTop: 0,
  marginRight: 8,
  marginBottom: 0,
  marginLeft: 0,
  width: 18,
  height: 18,
  borderRadius: 9999,
  // borderStyle: 'solid',
  // borderWidth: 1,
  ...(checked && {
    borderColor: colors.highlight,
    backgroundColor: colors.highlight,
    backgroundImage: encodedIcon,
  })
});
