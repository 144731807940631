import {Element} from 'domhandler'
import {Config} from '@slate-serializers/dom/src/lib/config/types';
import {styleToString} from '@slate-serializers/dom/src/lib/utilities';

const ELEMENT_NAME_TAG_MAP = {
  p: 'p',
  paragraph: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  ul: 'ul',
  ol: 'ol',
  li: 'li',
  blockquote: 'blockquote',
}

const MARK_ELEMENT_TAG_MAP = {
  strikethrough: ['s'],
  bold: ['strong'],
  underline: ['u'],
  italic: ['i'],
  code: ['pre', 'code'],
}

export const baseHtmlConfig: Config = {
  markMap: MARK_ELEMENT_TAG_MAP,
  elementMap: ELEMENT_NAME_TAG_MAP,
  elementAttributeTransform: ({ node }) => {
    if (node.align) {
      return {
        style: styleToString({
          ['text-align']: node.align,
        }),
      };
    }
    return;
  },
  elementTransforms: {
    quote: ({ children = [] }) => {
      const p = [new Element('p', {}, children)];
      return new Element('blockquote', {}, p);
    },
    link: ({ node, children = [] }) => {
      const attrs: any = {}
      if (node.newTab) {
        attrs.target = '_blank';
      }
      return new Element(
        'a',
        {
          href: node.url,
          ...attrs,
        },
        children,
      );
    },
    'numbered-list': ({ node, children }) => new Element('ol', {}, children),
    'bulleted-list': ({ node, children }) => new Element('ul', {}, children),
		'list-item': ({ node, children }) => new Element('li', {}, children),
		'list-item-text': ({ node, children }) => new Element('span', {}, children),
		textbox: ({ node, children }) => new Element('pre', {}, children),
    'section-title': ({ node, children }) => new Element('section-title', {}, children),
  },
	markTransforms: {
		input: ({ node, attribs }) => {
			return new Element(`rich-input id="${node.id}" data-correct="${node.correct ?? ''}"`, {});
		},
	},
  encodeEntities: false,
  alwaysEncodeBreakingEntities: true,
  alwaysEncodeCodeEntities: false,
  convertLineBreakToBr: false,
}

export const slideHtmlConfig: Config = {
	...baseHtmlConfig,
};

export const bibleStudyHtmlConfig: Config = {
	...baseHtmlConfig,
};

export const workbookHtmlConfig: Config = {
	...baseHtmlConfig,
	markTransforms: {
		...baseHtmlConfig.markTransforms,
		input: ({ node, attribs }) => {
      return new Element('span', {});
		}
	}
}
