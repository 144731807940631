import {ProfileStore} from './ProfileStore';

// all storage objects located here
export interface GlobalStore {
  profile: ProfileStore;
}

export const globalStoreDefaults: GlobalStore = {
  profile: {
    token: '',
    darkMode: false,
    countryCode: 'US',
    language: 'en',
    id: -1,
    firstName: '',
    lastName: '',
    emailAddress: '',
    roles: [],
    pictureUri: '',
    courses: [],
    currentCourseId: -1,
  }
};
