import React from 'react';
import {View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {useBreakpoint} from '~/hooks';
import {widths} from '~/theme';

export function FocusedContent({style, ...props}: ViewProps) {
  const {breakMd, breakLg} = useBreakpoint();
  const tw = useTailwind();

  return (
    <View style={tw('items-center')}>
			<View style={[tw('w-full'), breakMd && {maxWidth: widths.focus}, breakLg && {maxWidth: widths.stretch}]}>
				{props.children}
			</View>
    </View>
  );
}
