import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-native';

import {
  Container,
  FocusedContent,
  Heading,
  ScrollView,
  TabView,
} from '~/components/core';
import {useAdministrative, useBreakpoint} from '~/hooks';
import {TabViewScene} from '~/types';
import {Users} from './Users';
import {ClassroomContent} from './ClassroomContent';
import {StudySeriesList} from './StudySeriesList';
import {widths} from '~/theme';

const tabs: TabViewScene[] = [
  { key: 'users', title: 'Users', component: () => <Users /> },
  { key: 'classroom', title: 'Classroom', component: () => <ClassroomContent /> },
  { key: 'study-series', title: 'Study Series', component: () => <StudySeriesList /> },
];

export function Content() {
  const {breakMd} = useBreakpoint();
  const administrative = useAdministrative();
  const navigate = useNavigate();

  useEffect(() => {
    if (!administrative) {
      navigate('/');
      return;
    }
  }, [])

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <Heading>Content</Heading>
          <TabView
            tabStyles={breakMd && {maxWidth: widths.focus}}
            tabs={tabs}
          />
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
