import {FontWeight} from '~/types';

type Font = 'Montserrat';

interface FontFamily {
  name: Font;
  thin: string;
  'thin-italic': string;
  extralight: string;
  'extralight-italic': string;
  light: string;
  'light-italic': string;
  normal: string;
  italic: string;
  medium: string;
  'medium-italic': string;
  semibold: string;
  'semibold-italic': string;
  bold: string;
  'bold-italic': string;
  extrabold: string;
  'extrabold-italic': string;
  black: string;
  'black-italic': string;
}

const Montserrat: FontFamily = {
  name: 'Montserrat',
  thin: 'Montserrat-Thin',
  'thin-italic': 'Montserrat-ThinItalic',
  extralight: 'Montserrat-ExtraLight',
  'extralight-italic': 'Montserrat-ExtraLightItalic',
  light: 'Montserrat-Light',
  'light-italic': 'Montserrat-LightItalic',
  normal: 'Montserrat-Regular',
  italic: 'Montserrat-Italic',
  medium: 'Montserrat-Medium',
  'medium-italic': 'Montserrat-MediumItalic',
  semibold: 'Montserrat-SemiBold',
  'semibold-italic': 'Montserrat-SemiBoldItalic',
  bold: 'Montserrat-Bold',
  'bold-italic': 'Montserrat-BoldItalic',
  extrabold: 'Montserrat-ExtraBold',
  'extrabold-italic': 'Montserrat-ExtraBoldItalic',
  black: 'Montserrat-Black',
  'black-italic': 'Montserrat-BlackItalic',
};

const fonts = {
  Montserrat,
};

type Locale = 'en-US';

const localeDefaults = {
  'en-US': Montserrat,
};

export function useFont(font: Font, weight: FontWeight): string {
  // return Platform.OS === 'web' ? fonts[font].name : fonts[font][weight];
  return fonts[font][weight];
}

export function useLocaleDefaultFont(locale: Locale): string {
  return localeDefaults[locale].name;
}
