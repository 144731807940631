import React from 'react';
import {ActivityIndicator} from 'react-native';

import {Text} from '~/components/core';

interface LoadOrResultProps {
  loading: boolean;
  results: JSX.Element[];
  noResults?: JSX.Element;
}

export function LoadingOrResult({loading, results, noResults}: LoadOrResultProps) {
  return (
    loading ? (
      <ActivityIndicator size="large" />
    ) : results.length > 0 ? <>{results}</> : noResults ? noResults : <Text>No results</Text>
  );
}
