import React, {useEffect, useState} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {useCompanionApi} from '~/api/companion';
import {CourseWithUsers, User} from '~/api/companion/models/response';
import {Heading, Text} from '~/components/core';
import {PersonListing} from '~/components/core/PersonListing';
import {useProfile} from '~/hooks';

export function People() {
  const navigate = useNavigate();
  const tw = useTailwind();
  const {api} = useCompanionApi();
  const {profile} = useProfile();
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState<CourseWithUsers>();
  const [instructors, setInstructors] = useState<User[]>([]);
  const [students, setStudents] = useState<User[]>([]);

  useEffect(() => {
    // todo: move this all to course context provider.
    (async () => {
      api.classroom.get(profile.currentCourseId)
        .then((_course) => {
          setCourse(_course);
          setInstructors(_course.users.filter(u => u.roles.includes('Instructor')));
          setStudents(_course.users.filter(u => u.roles.includes('Student')));
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    })();
  }, []);

  return (
    <View>
      {loading ? (
        <View style={tw('py-8')}>
          <ActivityIndicator size="large" />
        </View>
      ) : (
        <>
          <Heading size="base">Instructors</Heading>
          {instructors.length > 0 ? instructors.map((instructor, i) => (
            <PersonListing key={i} onPress={() => navigate('/')} user={instructor} />
          )) : (
            <Text>No instructors.</Text>
          )}
          <Heading size="base" style={tw('mt-4')}>Students</Heading>
          {students.length > 0 ? students.map((student, i) => (
            <PersonListing key={i} onPress={() => navigate('/')} user={student} />
          )) : (
            <Text>No students.</Text>
          )}
        </>
      )}
    </View>
  );
};
