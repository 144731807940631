import React, {useState} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Heading, Container, Button, Text} from '~/components/core';
import {useForm, useI18nData, useNotify} from '~/hooks';
import {Checkbox, Input, Label, Picker} from '~/components/form';
import {required, emailPattern} from '~/util/rules';
import {useCompanionApi} from '~/api/companion';
import {Gap} from '~/components/layout';
import {MdtpContentFormProps, RoleNames} from '~/types';
import {User} from '~/api/companion/models/response';

interface UpdateUserForm {
  email: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  [others: string | number | symbol]: unknown;
}

interface UserFormProps extends MdtpContentFormProps {
  user?: User;
  onReload: () => void;
}

export function UserForm(props: UserFormProps) {
  const {api} = useCompanionApi();
  const tw = useTailwind();
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, watch} = useForm<UpdateUserForm>({
    defaultValues: (() => {
      let defaultData: any = {
        firstName: props.user?.firstName,
        lastName: props.user?.lastName,
        email: props.user?.email,
        countryCode: props.user?.countryCode,
      };
      RoleNames.forEach(rn => defaultData[rn] = props.user?.roles.includes(rn));
      return defaultData;
    })(),
    theme: props.theme,
  });
  const {pushNotifyPopup} = useNotify();
  const {countries} = useI18nData();

  const userAction = (action: () => Promise<User | boolean>) => action()
    .then((user) => {
      props.onClose();
      pushNotifyPopup({
        message: `User ${props.action?.toLowerCase()}d successfully.`,
        type: 'success',
      });
      props.onReload();
    })
    .catch(err => {
      console.error(err);
      pushNotifyPopup({
        message: `Failed to ${props.action?.toLowerCase()} user.`,
        type: 'error',
      })
    })
    .finally(() => setLoading(false));

  const roles = RoleNames.map((role, i) => ({
    label: role,
    register: register(role),
    value: watch(role) as boolean,
    id: i+1,
  }));

  const onSubmit = handleSubmit(
    (data, e) => {
      setLoading(true);
      const submission = {
        ...data,
        roles: roles.map(r => r.value ? r.id : undefined).filter(r => r !== undefined) as number[],
      }
      switch (props.action) {
        case 'Create': return userAction(() => api.users.create(submission));
        case 'Update': return userAction(() => api.users.update(props.user!.id!, submission));
        case 'Delete': return userAction(() => api.users.delete(props.user!.id!));
        default:
          break;
      }
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const firstName = register('firstName', {required});
  const lastName = register('lastName', {required});
  const email = register('email', {required: !props.user, pattern: emailPattern});
  const countryCode = register('countryCode', {required});

  return loading ? (
    <View style={tw('py-8')}>
      <ActivityIndicator size="large" />
    </View>
  ) : (
    <Container>
      {props.action === 'Delete' ? (
        <>
          <Heading>Delete user '{props.user?.name}'</Heading>
          <Text style={tw('my-6')}>This action cannot be undone! Are you sure?</Text>
          <View style={tw('flex-row justify-between items-center')}>
            <Button type="danger" onPress={onSubmit}>
              Delete
            </Button>
            <Button type="outline" onPress={props.onClose}>
              Cancel
            </Button>
          </View>
        </>
      ) : (
        <>
          <Heading>{props.action ?? 'Update'} user</Heading>
          {props.user && (
            <View>
              <Heading size="lg" style={tw('mb-6')}>
                {props.user?.name}
              </Heading>
              <Input
                disabled
                {...email}
                label="Email"
                placeholder="Email"
              />
            </View>
          )}
          {!props.user && (
            <>
              <Gap gap={4}>
                <Input
                  {...firstName}
                  label="First Name"
                  placeholder="First Name"
                  autoCapitalize="words"
                />
                <Input
                  {...lastName}
                  label="Last Name"
                  placeholder="Last Name"
                  autoCapitalize="words"
                />
              </Gap>
              <Input {...email} label="Email" placeholder="Email" />
            </>
          )}
          <Picker
            label="Country"
            {...countryCode}
            options={countries!.map(c => ({
              value: c.code, label: `${c.code} ${c.unicode}`
            }))}
          />
          <Label>
            Roles
          </Label>
          {roles.map((role, i) => (
            <Checkbox key={i} label={role.label} {...roles[i].register} />
          ))}
          <View style={tw('flex-row justify-between items-center')}>
            <Button type="solid" onPress={onSubmit}>
              Submit
            </Button>
            <Button type="outline" onPress={props.onClose}>
              Cancel
            </Button>
          </View>
        </>
      )}
    </Container>
  );
}
