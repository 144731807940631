import React from 'react';
import {GestureResponderEvent, Pressable, View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Panel, Heading, Text} from '~/components/core';
import {CourseWithUsers} from '~/api/companion/models/response';

export function CourseListing(
  props: ViewProps & {
    onPress: ((event: GestureResponderEvent) => void) | null | undefined;
    course: CourseWithUsers;
    showUsers?: boolean;
    actions?: JSX.Element;
  }
) {
  const tw = useTailwind();

  return (
    <Pressable {...props}>
      <Panel>
        <View style={tw('flex-row justify-between items-center')}>
          <View style={tw('flex-row items-center')}>
            <View>
              <Heading size="sm" style={tw(`text-highlight ${props.showUsers ? 'mb-2' : ''}`)}>
                {props.course.name}
              </Heading>
              <Text>{props.course.users.length} members</Text>
            </View>
          </View>
          {props.actions}
        </View>
      </Panel>
    </Pressable>
  );
};
