import React, {useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {Button, Container, Heading, ScrollView, Stat, PersonImage, FocusedContent} from '~/components/core';
import {Gap} from '~/components/layout';
import {useBreakpoint, useProfile} from '~/hooks';
import {colors, widths} from '~/theme';
import {useCompanionApi} from '~/api/companion';
import {PlusIcon} from '~/assets/icons';

interface AddStatProps {
  route: string;
}

function AddStat(props: AddStatProps) {
  const navigate = useNavigate();

  return (
    <Button type="solid" onPress={() => navigate(props.route)}>
      <PlusIcon width={25} height={25} stroke={colors.white} />
    </Button>
  )
}

export function Home() {
  const {profile} = useProfile();
  const [, setStats] = useState<any>({});
  const tw = useTailwind();
  const {breakMd} = useBreakpoint();
  const {api} = useCompanionApi();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      api.users.stats()
        .then((_stats) => {
          setStats(_stats);
        })
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    })();
  }, []);

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <View style={tw('flex-row items-center justify-between')}>
            <Heading>Hello, {profile.firstName}!</Heading>
            <Pressable onPress={() => navigate('/settings')}>
              <PersonImage uri={profile.pictureUri} />
            </Pressable>
          </View>
          <Heading size="2xl" weight="semibold" style={tw('text-highlight')}>
            Stats
          </Heading>
          <Gap gap={breakMd ? 4 : 2} vertical={!breakMd} childStyle={breakMd && {maxWidth: widths.focus}}>
            <Stat
              name="Conversations"
              actions={<AddStat route="/stats/add-conversation" />}
              count={27}
              image={require('~/assets/images/conversation.jpeg')}
            />
            <Stat
              name="Studies"
              actions={<AddStat route="/stats/add-scheduled-study" />}
              count={7}
              image={require('~/assets/images/bible-study.jpeg')}
            />
          </Gap>
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
