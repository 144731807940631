import React, {useEffect, useState} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {useCompanionApi} from '~/api/companion';
import {
  Container,
  FocusedContent,
  Heading,
  Kebab,
  ScrollView,
  TabView,
} from '~/components/core';
import {useAdministrative, useForm, useProfile} from '~/hooks';
import {TabViewScene} from '~/types';
import {Feed, People, Workbooks} from '~/views/classroom';
import {Course} from '~/api/companion/models/response';
import {Picker} from '~/components/form';

const tabs: TabViewScene[] = [
  { key: 'feed', title: 'Feed', component: () => <Feed /> },
  { key: 'people', title: 'People', component: () => <People /> },
  { key: 'workbooks', title: 'Workbooks', component: () => <Workbooks /> },
];

interface CourseForm {
  currentCourse: number;
}

export function Classroom() {
  const {api} = useCompanionApi();
  const tw = useTailwind();
  const {profile, setProfile} = useProfile();
  const navigate = useNavigate();
  const administrative = useAdministrative();
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentCourse, setCurrentCourse] = useState<Course>();
  const {register, watch} = useForm<CourseForm>({
    defaultValues: {
      currentCourse: profile.currentCourseId,
    },
  });

  const currentCourseSelection = register('currentCourse');
  const lastCourseSelection = watch('currentCourse');

  useEffect(() => {
    if (!lastCourseSelection || courses.length < 1) {
      (async () => {
        api.users.courses(profile.id)
          .then((courses) => {
            setCourses(courses);
            const currentCourseId = profile.currentCourseId > -1 ? profile.currentCourseId : courses[courses.length - 1].id;
            setProfile({...profile, currentCourseId});
            const current = courses.find(c => c.id === currentCourseId);
            if (current) {
              setCurrentCourse(current);
              currentCourseSelection.setValue(current.id);
            }
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false));
      })();
    } else {
      setProfile({...profile, currentCourseId: +lastCourseSelection});
    }
  }, [lastCourseSelection]);

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          {loading ? (
            <View style={tw('py-8')}>
              <ActivityIndicator size="large" />
            </View>
          ) : (
            <>
              <View style={tw('flex-row justify-between items-center mb-2')}>
                <Heading>Classroom</Heading>
                {administrative && currentCourse && <Kebab onPress={() => navigate(`/classroom/${currentCourse.id}`)} direction="horizontal" />}
              </View>
              {courses.length > 1 ? (
                <View style={tw('mb-4')}>
                  <Picker
                    {...currentCourseSelection}
                    options={courses.map(c => ({
                      value: c.id, label: c.name,
                    }))}
                    noMargin
                  />
                </View>
              ) : (
                <Heading size="lg">{currentCourse ? currentCourse.name : 'Loading...'}</Heading>
              )}
              <TabView key={profile.currentCourseId} tabs={tabs} />
            </>
          )}
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
