import React, {useState, useEffect} from 'react';
import {TextInput, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Text} from '~/components/core';
import {useCompanionApi} from '~/api/companion';
import {useDebounce} from '~/hooks';

interface PresenterProps {
  workbookId: number;
  index: number;
  notes: string[];
}

export function Presenter({
  workbookId,
  index,
  notes,
}: PresenterProps) {
  const tw = useTailwind();
  const {api} = useCompanionApi();
  const [lastTextChange, setLastTextChange] = useState('');
	const handleNotesChange = (text: string) => {
    setNotesText(text);
    setLastTextChange(text);
	}
  const [notesText, setNotesText] = useState(notes[index]);
  const debouncedSave = useDebounce(lastTextChange, 250);
  const [autoSaving, setAutoSaving] = useState(false);

  useEffect(() => setNotesText(notes[index]), [index]);

  useEffect(() => {
    (async () => {
      setAutoSaving(true);
      const updated = notes;
      updated[index] = notesText;

      api.workbooks.updateNotes(workbookId, {
        notes: updated,
      })
        .then((_) => setAutoSaving(false))
        .catch((err) => console.error(err));
    })();
  }, [debouncedSave]);

  return (
    <View style={tw('w-1/2 h-full bg-white p-8')}>
      <View style={tw('p-2 flex-row')}>
        <Text style={{color: '#000', marginRight: 8}}>
          Notes
        </Text>
        {autoSaving && (
          <Text style={{color: '#777', fontStyle: 'italic'}}>
            autosaving...
          </Text>
        )}
      </View>
      <View style={tw('w-full h-full p-2')} key={index}>
        <TextInput
          style={{
            width: '100%',
            height: '100%',
            borderWidth: 0,
            outlineStyle: 'none',
          } as any}
          multiline
          numberOfLines={6}
          onChangeText={handleNotesChange}
          value={notesText}
        />
      </View>
    </View>
  );
}
