import React from 'react';
import {ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Panel} from '~/components/core';

export function Square({style, ...props}: ViewProps) {
  const tw = useTailwind();
  const squareStyle = {aspectRatio: 1};

  return (
    <Panel
      style={[
        tw('w-full grow justify-center items-center mr-4'),
        squareStyle,
        style,
      ]}
      {...props}>
      {props.children}
    </Panel>
  );
}
