import React, { useCallback, useEffect, useState } from 'react';
import {Image, ImageProps, ImageSourcePropType} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {useProfile} from '~/hooks';

enum ImageSize {
  'xs' = 38,
  'sm' = 42,
  'md' = 46,
  'lg' = 50,
  '2xl' = 54,
  '3xl' = 58,
  'massive' = 200,
}

// todo: I had to break caching on this because react-native's
// built-in Image component doesn't pass authentication
// headers correctly.
export function PersonImage({style, ...props}: Omit<ImageProps, 'source'> & {size?: keyof typeof ImageSize, uri: string}) {
  const tw = useTailwind();
  const size = ImageSize[props.size ?? 'md'];
  const {profile} = useProfile();
  const [source, setSource] = useState<ImageSourcePropType | undefined>(undefined);

  const load = useCallback(async () => {
    const response = await fetch(props.uri, {headers: {Authorization: `Bearer ${profile.token}`}});
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => setSource({uri: reader.result as string});
  }, [props.uri]);

  useEffect(() => {
    load()
  }, [load]);

  if (!source) return <></>;

  return (
    <Image
      style={[
        tw('rounded-full'),
        {width: size, height: size},
        style,
      ]}
      {...props}
      defaultSource={require('~/assets/images/user-default.png')}
      source={source}
    />
  );
}
