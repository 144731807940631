import React from 'react';

import {Container, Text} from '~/components/core';

export function NotFound() {
  return (
    <Container>
      <Text>Page not found</Text>
    </Container>
  );
}
