import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {colors} from '~/theme';
import {PlusIcon, TrashIcon} from '~/assets/icons';
import {Heading, LoadingOrResult, Modal, CourseListing, FocusedContent, ScrollView, Container} from '~/components/core';
import {useModalControl} from '~/components/core/Modal';
import {useBreakpoint, useForm, useProfile, useDebounce} from '~/hooks';
import {Input} from '~/components/form';
import {useCompanionApi} from '~/api/companion';
import {Country, CourseWithUsers} from '~/api/companion/models/response';
import {CourseSearchRequest} from '~/api/companion/models/request';
import {CourseForm} from '~/forms';
import {secondaryFormTheme} from '~/types';
import {I18nDataContext} from '~/contexts';

export function Courses() {
  const {register, handleSubmit, getValues, watch} = useForm<CourseSearchRequest>({
    defaultValues: {
      page: 0,
      size: 10,
      search: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const lastSearch = watch('search');

  const getCourses = (data: CourseSearchRequest) => {
    setLoading(true);
    api.classroom.paginate(data)
      .then((courses) => {
        setCourses(courses.items);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const onSubmit = handleSubmit(
    (data, e) => {
      getCourses(data);
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const tw = useTailwind();
  const {breakMd, breakLg} = useBreakpoint();

  const search = register('search');
  const debouncedSearch = useDebounce(lastSearch);

  const {profile} = useProfile();
  const [courses, setCourses] = useState<CourseWithUsers[]>([]);
  const addCourseModalControl = useModalControl();
  const editCourseModalControl = useModalControl();
  const confirmDeleteCourseModalControl = useModalControl();
  const {api} = useCompanionApi();
  const [editCourse, setEditCourse] = useState<CourseWithUsers>();
  const handleEditCourse = (course: CourseWithUsers) => {
    setEditCourse(course);
    editCourseModalControl.setModalOpen(true);
  }
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    (async () => {
      getCourses(getValues());
      // todo: only do the below if it's not cached already.
      Promise.all([
        api.i18n.countries(),
        // api.i18n.languages(),
      ])
        .then(([countries]) => {
          setCountries(countries);
        })
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    })();
  }, []);

  useEffect(() => {
    onSubmit();
  }, [debouncedSearch]);

  return (
    <FocusedContent>
      <ScrollView>
        <Container>
          <I18nDataContext.Provider value={{countries}}>
            <Modal control={addCourseModalControl}>
              <CourseForm
                action="Create"
                theme={secondaryFormTheme}
                onReload={onSubmit}
                onClose={() => addCourseModalControl.setModalOpen(false)}
              />
            </Modal>
            <Modal control={editCourseModalControl}>
              <CourseForm
                action="Update"
                theme={secondaryFormTheme}
                onReload={onSubmit}
                onClose={() => editCourseModalControl.setModalOpen(false)}
                course={editCourse}
              />
            </Modal>
            <Modal control={confirmDeleteCourseModalControl}>
              <CourseForm
                action="Delete"
                theme={secondaryFormTheme}
                onReload={onSubmit}
                onClose={() => confirmDeleteCourseModalControl.setModalOpen(false)}
                course={editCourse}
              />
            </Modal>
          </I18nDataContext.Provider>
          <View style={tw('flex-row justify-between items-center')}>
            <Heading>Courses</Heading>
            <TouchableOpacity onPress={() => addCourseModalControl.setModalOpen(true)}>
              <PlusIcon width={25} height={25} stroke={profile.darkMode ? colors['dark-highlight'] : colors.highlight} />
            </TouchableOpacity>
          </View>
          <View style={breakMd && {maxWidth: 300}}>
            <Input {...search} label="Search" placeholder="Enter a search criteria" />
          </View>
          <LoadingOrResult
            loading={loading}
            results={courses.map((c, i) => (
              <CourseListing
                key={i}
                onPress={() => handleEditCourse(c)}
                course={c}
                actions={(
                  <TouchableOpacity
                    style={tw('mr-4')}
                    onPress={() => {
                      setEditCourse(c);
                      confirmDeleteCourseModalControl.setModalOpen(true)}
                    }
                  >
                    <TrashIcon width={15} fill={colors.danger} />
                  </TouchableOpacity>
                )}
              />
            ))}
          />
        </Container>
      </ScrollView>
    </FocusedContent>
  );
}
