import {StyleProp, ViewStyle} from 'react-native';
import {colors} from '~/theme';

export const encodedIcon = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cpath d='m5 12 5 5L20 7'/%3E%3C/svg%3E")`;

export const checkboxStyles = (checked: boolean): StyleProp<ViewStyle> => ({
  marginTop: 0,
  marginRight: 8,
  marginBottom: 0,
  marginLeft: 0,
  width: 18,
  height: 18,
  borderRadius: 4,
  // borderStyle: 'solid',
  // borderWidth: 1,
  ...(checked && {
    borderColor: colors.highlight,
    backgroundColor: colors.highlight,
    backgroundImage: encodedIcon,
  })
});
