import React from 'react';
import {TouchableOpacity, TouchableOpacityProps, View} from 'react-native';

// todo: add up and down icons
import {ChevronLeftIcon, ChevronRightIcon} from '~/assets/icons';
import {useProfile} from '~/hooks';
import {colors} from '~/theme';

type ChevronDirection = 'up' | 'right' | 'down' | 'left';

function getChevronIcon(direction: ChevronDirection) {
  switch (direction) {
    case 'left': return ChevronLeftIcon;
    case 'right': return ChevronRightIcon;
    case 'up':
    case 'down':
    default: return ChevronRightIcon;
  }
}

export function Chevron(props: TouchableOpacityProps & {direction: ChevronDirection}) {
  const {profile} = useProfile();

  const ChevronContainer = ({children}: {children: React.ReactNode}) =>
    !!props.onPress
      ? <TouchableOpacity {...props} children={children} />
      : <View children={children} />;
  const Chevron = getChevronIcon(props.direction);

  return (
    <ChevronContainer>
      <Chevron
        width={28}
        stroke={profile.darkMode ? colors.white : colors.black}
      />
    </ChevronContainer>
  )
}