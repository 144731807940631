import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {colors} from '~/theme';
import {PlusIcon, TrashIcon} from '~/assets/icons';
import {Heading, LoadingOrResult, Modal, StudySeriesListing} from '~/components/core';
import {useModalControl} from '~/components/core/Modal';
import {useBreakpoint, useForm, useProfile, useDebounce} from '~/hooks';
import {Input} from '~/components/form';
import {useCompanionApi} from '~/api/companion';
import {Country, StudySeries} from '~/api/companion/models/response';
import {StudySeriesSearchRequest} from '~/api/companion/models/request';
import {StudySeriesForm} from '~/forms';
import {secondaryFormTheme} from '~/types';
import {I18nDataContext} from '~/contexts';

export function StudySeriesList() {
  const {register, handleSubmit, getValues, watch} = useForm<StudySeriesSearchRequest>({
    defaultValues: {
      page: 0,
      size: 10,
      search: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const lastSearch = watch('search');

  const getSeriesList = (data: StudySeriesSearchRequest) => {
    setLoading(true);
    api.studySeries.paginate(data)
      .then((studySeriesList) => {
        setSeriesList(studySeriesList.items);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  const onSubmit = handleSubmit(
    (data) => {
      getSeriesList(data);
    },
    (errors, e) => {
      console.log({errors, e});
    },
  );

  const tw = useTailwind();
  const {breakMd} = useBreakpoint();

  const search = register('search');
  const debouncedSearch = useDebounce(lastSearch);

  const {profile} = useProfile();
  const navigate = useNavigate();
  const [seriesList, setSeriesList] = useState<StudySeries[]>([]);
  const addStudySeriesModalControl = useModalControl();
  const confirmDeleteStudySeriesModalControl = useModalControl();
  const {api} = useCompanionApi();
  const [deleteStudySeries, setDeleteStudySeries] = useState<StudySeries>();
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    (async () => {
      getSeriesList(getValues());
      // todo: only do the below if it's not cached already.
      Promise.all([
        api.i18n.countries(),
        // api.i18n.languages(),
      ])
        .then(([countries]) => {
          setCountries(countries);
        })
        .catch(err => console.error(err))
        .finally(() => {/*todo: add loading bar finish*/});
    })();
  }, []);

  useEffect(() => {
    onSubmit();
  }, [debouncedSearch]);

  return (
    <>
      <I18nDataContext.Provider value={{countries}}>
        <Modal control={addStudySeriesModalControl}>
          <StudySeriesForm
            action="Create"
            theme={secondaryFormTheme}
            onReload={onSubmit}
            onClose={() => addStudySeriesModalControl.setModalOpen(false)}
          />
        </Modal>
        <Modal control={confirmDeleteStudySeriesModalControl}>
          <StudySeriesForm
            action="Delete"
            theme={secondaryFormTheme}
            onReload={onSubmit}
            onClose={() => confirmDeleteStudySeriesModalControl.setModalOpen(false)}
            series={deleteStudySeries}
          />
        </Modal>
      </I18nDataContext.Provider>
      <View style={tw('flex-row justify-between items-center')}>
        <Heading size="lg">Bible Study Series List</Heading>
        <TouchableOpacity onPress={() => addStudySeriesModalControl.setModalOpen(true)}>
          <PlusIcon width={25} height={25} stroke={profile.darkMode ? colors['dark-highlight'] : colors.highlight} />
        </TouchableOpacity>
      </View>
      <View style={breakMd && {maxWidth: 300}}>
        <Input {...search} label="Search" placeholder="Enter a search criteria" />
      </View>
      <LoadingOrResult
        loading={loading}
        results={seriesList.map((s, i) => (
          <StudySeriesListing
            key={i}
            onPress={() => navigate(`/study-series/${s.slug}`)}
            series={s}
            actions={(
              <TouchableOpacity
                style={tw('mr-4')}
                onPress={() => {
                  setDeleteStudySeries(s);
                  confirmDeleteStudySeriesModalControl.setModalOpen(true)}
                }
              >
                <TrashIcon width={15} fill={colors.danger} />
              </TouchableOpacity>
            )}
          />
        ))}
      />
    </>
  );
}
