import React from 'react';
import {GestureResponderEvent, Pressable, View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

import {Panel, Heading, Text} from '~/components/core';
import {StudySeries} from '~/api/companion/models/response';

export function StudySeriesListing(
  props: ViewProps & {
    onPress: ((event: GestureResponderEvent) => void) | null | undefined;
    series: StudySeries;
    actions?: JSX.Element;
  }
) {
  const tw = useTailwind();

  return (
    <Pressable {...props}>
      <Panel>
        <View style={tw('flex-row justify-between items-center')}>
          <View>
            <Heading size="sm" style={tw('text-highlight')}>
              {props.series.title}
            </Heading>
            <Text style={{maxWidth: 250}} numberOfLines={2}>{props.series.description}</Text>
          </View>
          {props.actions}
        </View>
      </Panel>
    </Pressable>
  );
};
