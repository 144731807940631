import React from 'react';
import {View, ViewProps} from 'react-native';
import {useTailwind} from 'tailwind-rn';

export function Panel({style, ...props}: ViewProps) {
  const tw = useTailwind();

  return (
    <View {...props} style={[tw('p-4 mb-4 bg-white dark:bg-stone rounded-2xl'), style]} />
  );
}
