import React from 'react';
import {Pressable, View} from 'react-native';
import {useNavigate} from 'react-router-native';
import {useTailwind} from 'tailwind-rn';

import {FocusedContent, Heading, Panel, Text} from '~/components/core';

export function ClassroomContent() {
  const tw = useTailwind();
	const navigate = useNavigate();

  return (
    <FocusedContent>
			<View style={tw('flex-row justify-between items-center')}>
				<Heading size="lg">Classroom</Heading>
			</View>
			<Pressable onPress={() => navigate('/content/courses')}>
      	<Panel>
					<Heading size="lg">Courses</Heading>
					<Text>View and edit classroom courses</Text>
				</Panel>
			</Pressable>
			<Pressable onPress={() => navigate('/content/workbooks')}>
      	<Panel>
					<Heading size="lg">Workbooks</Heading>
					<Text>View and edit classroom workbooks</Text>
				</Panel>
			</Pressable>
		</FocusedContent>
  );
}

export * from './Courses';
export * from './WorkbooksListing';
