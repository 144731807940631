import {API_URL} from '@env';

export enum Api {
  Companion = 0,
}

const apiLocation: string[] = [
  `${API_URL}/api/`, // Companion
];

// low-level api consumption helper hook
export function useApi(api: Api) {
  const apiUrl = apiLocation[api];

  async function req<T>(url: RequestInfo, options: RequestInit): Promise<T> {
    const res = await fetch(`${apiUrl}${url}`, options);
    // not within 2xx response range
    if (!res.ok) {
      throw Error(`${res.status}`);
    } else {
      const json = res.headers.get('content-type')?.includes('application/json');
      if (json) {
        return await res.json() as T;
      } else {
        return Promise.resolve(res.body as any);
      }
    }
  }

  // convenience functions (assume json)
  function get<T>(url: RequestInfo, options?: RequestInit) {
    return req<T>(url, {...options, method: 'GET'});
  }

  function post<T>(url: RequestInfo, options?: RequestInit) {
    return req<T>(url, {...options, method: 'POST'});
  }

  function put<T>(url: RequestInfo, options?: RequestInit) {
    return req<T>(url, {...options, method: 'PUT'});
  }

  function delete_<T>(url: RequestInfo, options?: RequestInit) {
    return req<T>(url, {...options, method: 'DELETE'});
  }

  return {get, post, put, delete_};
}
