import React from 'react';
import {View, ViewProps} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useTailwind} from 'tailwind-rn';

import {useAdministrative} from '~/hooks';
import {
  BookIcon,
  ClassroomIcon,
  HomeIcon,
  LogoIcon,
  ContentIcon,
  PeopleIcon,
} from '~/assets/icons';
import {Tab} from '~/types';
import {NavMenuItem} from './NavMenuItem';

const ContentTab: Tab = {to: '/content', label: 'Content', Icon: ContentIcon, roles: ['Administrator', 'Author', 'Instructor']}; 

const getTabs = (administrative: boolean): Tab[] => [
  {to: '/', label: 'Home', Icon: HomeIcon},
  ...(administrative ? [ContentTab] : []),
  {to: '/study-series', label: 'Bible Studies', Icon: BookIcon},
  {to: '/classroom', label: 'Classroom', Icon: ClassroomIcon},
  {to: '/contacts', label: 'Contacts', Icon: PeopleIcon},
];

export function NavBar({style}: ViewProps) {
  const insets = useSafeAreaInsets();
  const tw = useTailwind();
  const administrative = useAdministrative();
  const tabs = getTabs(administrative);

  return (
    <View
      style={[
        tw(
          'w-full md:w-auto flex-row md:flex md:flex-col md:px-2 justify-around md:justify-start bg-white dark:bg-stone',
        ),
        {paddingBottom: insets.bottom},
        style,
      ]}>
      <View style={tw('hidden md:flex px-4 py-8')}>
        <LogoIcon height={15} />
      </View>
      {tabs.map((tabProps, index) => (
        <NavMenuItem key={index} index={index} {...tabProps} />
      ))}
    </View>
  );
}
