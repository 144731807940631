import React, {useEffect, useState} from 'react';
import {useTailwind} from 'tailwind-rn';

import {useCompanionApi} from '~/api/companion';
import {PersonalBibleStudySeries} from '~/api/companion/models/response';
import {FlatList, FocusedContent, Heading} from '~/components/core';
import {SkeletonPanel} from '~/components/skeleton';
import {SeriesLink} from './SeriesLink';

export function BibleStudiesList() {
  const {api} = useCompanionApi();
  const [series, setSeries] = useState<PersonalBibleStudySeries[]>(new Array(6).fill(undefined));
  const tw = useTailwind();

  useEffect(() => {
    (async () => {
      api.studySeries.all()
        .then((bibleStudySeriesList) => {
            setSeries(bibleStudySeriesList);
          })
        .catch((err) => console.error(err));
    })();
  }, []);

  return (
    <FocusedContent>
      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(_, index) => 'key' + index}
        stickyHeaderIndices={[0]}
        stickyHeaderHiddenOnScroll
        ListHeaderComponent={(
          <Heading style={tw('mr-4')}>Personal Bible Study Series</Heading>
        )}
        data={series}
        renderItem={({item}) => item ? <SeriesLink {...item} /> : <SkeletonPanel />}
      />
    </FocusedContent>
  );
}
