import React from 'react';
import {View} from 'react-native';

import EditorInput from './component.native';
import {EditorProps} from './EditorProps';

function _Editor(
	props: EditorProps,
	ref: React.Ref<any>,
) {
	return (
		<View ref={ref}>
			<EditorInput {...props} />
		</View>
	);
}

export const Editor = React.forwardRef(_Editor);
export default Editor;
